<template>
  <div>
    <el-tag
      v-for="(file, index) in uploadedFiles"
      :key="file.id"
      class="mr7 mb7"
      :closable="!disableBtn"
      :disable-transitions="false"
      @close="handleRemove(file.id, index, false)">
      <!-- <el-col :span="15"> -->
      <el-link
        type="primary"
        :href="$downloadLink(file.id)"
        target="_blank">
        {{ file.name }}
      </el-link>
      <span class="attachment-file-size">({{ file.size }})</span>
    </el-tag>

    <div v-if="showNewFiles">
      <el-tag
        v-for="(file, index) in newFiles"
        :key="file.id"
        class="mr7 mb7"
        closable
        :disable-transitions="false"
        @close="handleRemove(file.id, index, true)">
        <el-link
          type="primary"
          :href="$downloadLink(file.id)"
          target="_blank">
          {{ file.name }}
        </el-link>
        <span class="attachment-file-size">({{ file.size }})</span>
      </el-tag>
    </div>

    <div
      :class="{
        'disabled-upload':disabled,
      }">
      <el-upload
        v-if="dragFile"
        :disabled="disabled"
        class="upload-demo dragable-uploader"
        drag
        :data-test="testId"
        :action="$apiRoutes.upload"
        :headers="httpHeader"
        :on-success="handleSucces"
        :limit="limit"
        :multiple="multiple">
        <div class="el-upload__text">
          {{ uploadText || $tr("upload.text") }}
          <br />
          <span class="el-upload_text_em"><em>{{ uploadButton || $tr("upload.button") }}</em></span>
        </div>
      </el-upload>
      <el-upload
        v-else
        :disabled="disabled"
        class="upload-demo"
        :action="$apiRoutes.upload"
        :headers="httpHeader"
        :on-success="handleSucces"
        style="margin-bottom: 10px">
        <el-button
          size="small"
          :disabled="disableBtn || disabled"
          type="primary">
          {{ uploadButton }}
        </el-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    files: {
      type: Array,
      default () {
        return []
      },
    },
    showNewFiles: {
      type: Boolean,
      default: true,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    uploadButton: {
      type: String,
      default: '',
    },
    disableBtn: {
      type: Boolean,
      default: false,
    },
    dragFile: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    uploaderName: {
      type: String,
      default: '',
    },
    limit: {
      type: [Number, String],
      default: 20,
    },
    uploadText: {
      type: String,
      default: '',
    },
    showConfirmBeforeDelete: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    testId: {
      type: String,
      default: 'upload-input',
    },
  },
  data () {
    return {
      httpHeader: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
      },
      uploadedFiles: [...this.files],
      newFiles: [],
      removedFiles: [],
    }
  },
  watch: {
    files () {
      this.uploadedFiles = [...this.files]
    },
    reset () {
      this.uploadedFiles = []
      this.newFiles = []
      this.removedFiles = []
    },
  },
  methods: {
    removeFile (id, index) {
      this.uploadedFiles.splice(index, 1)
      this.$emit('removed-file', id, this.uploaderName)
    },
    destroyFile (id, index) {
      this.$axios.delete(this.$apiRoutes.upload + '/' + id)
        .then(() => {
          this.newFiles.splice(index, 1)
          this.$emit('removed-file', id, this.uploaderName)
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    handleSucces (response) {
      const deserializeResponse = this.$deserialize(response)
      this.newFiles.push(deserializeResponse)
      this.$emit('added-file', deserializeResponse, this.uploaderName)
    },
    handleRemove (id, index, isNewFiles) {
      if (!this.showConfirmBeforeDelete) {
        this.finalRemoveHandler(id, index, isNewFiles)
        return
      }

      this.$confirm(
        this.$tr('upload.delete_warning'),
        this.$tr('upload.delete_warning_title'),
        {
          confirmButtonText: this.$tr('upload.yes_button'),
          cancelButtonText: this.$tr('upload.no_button'),
          type: 'warning',
        },
      ).then(() => {
        this.finalRemoveHandler(id, index, isNewFiles)
      })
    },
    finalRemoveHandler (id, index, isNewFiles) {
      if (isNewFiles) {
        this.destroyFile(id, index)
      } else {
        this.removeFile(id, index)
      }
    },
  },
}
</script>

<style>
.el-upload-list__item.is-success {
  display: none;
}
.dragable-uploader .el-upload {
  width: 100%;
  height: 75px;
}
.dragable-uploader .el-upload .el-upload-dragger {
  width: 100%;
  height: 75px;
}
.dragable-uploader .el-upload-dragger .el-upload__text {
  line-height: 25px;
  margin-top: 10px;
  user-select: none;
}
.el-upload_text_em{
  margin-bottom: -10px;
  margin-top: -20px;
}
.disabled-upload .el-upload-dragger{
  opacity: 0.5;
  background-color: #f5f7fa;
  cursor: not-allowed;
}
.is-error .el-upload-dragger {
  border-color: #f56c6c !important;
}
.attachment-file-size {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
</style>
