<template>
  <div>
    <div class="flex justify-space-between items-center">
      <h4 class="my0">
        {{ $tr('document_section.contact_person_label') }}
      </h4>
      <el-button
        v-if="items.length === 0"
        type="text"
        class="add-button"
        @click="addContact">
        <i class="el-icon-circle-plus-outline" />
      </el-button>
    </div>
    <el-divider class="mt7" />
    <el-row
      v-for="(item, index) in items"
      :key="index"
      :gutter="20">
      <el-col :span="23">
        <Contact
          :contact.sync="items[index]"
          :available-employees="availableEmployees" />
      </el-col>
      <el-col :span="1">
        <el-button
          v-if="items.length !== 1"
          :type="item._destroy ? 'info' : 'danger'"
          :icon="item._destroy ? 'el-icon-refresh-left' : 'el-icon-delete'"
          class="mt5"
          circle
          @click="triggerRemove(index)" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Contact from './Contact'

export default {
  components: {
    Contact,
  },
  props: {
    contacts: {
      type: Array,
      required: true,
      default () { return [] },
    },
    availableEmployees: {
      type: Array,
      required: false,
      default () { return [] },
    },
  },
  data () {
    return {
      items: [],
    }
  },
  watch: {
    contacts: {
      handler (v) {
        if (this.$isEmpty(v)) {
          this.items = []
          this.addContact()
        } else {
          this.items = JSON.parse(JSON.stringify(v))
        }
      },
      immediate: true,
    },
    items: {
      handler () {
        this.emit()
      },
      deep: true,
    },
  },
  methods: {
    emit () {
      if (!this.$lodash.isEqual(this.items, this.contacts)) {
        this.$emit('update:contacts', JSON.parse(JSON.stringify(this.items)))
      }
    },
    triggerRemove (index) {
      if (this.$isBlank(this.items[index].id)) {
        this.items.splice(index, 1)
      } else {
        this.items[index]._destroy = !this.items[index]._destroy
        this.emit()
      }
    },
    addContact () {
      const item = {
        id: null,
        person_id: null,
        first_name: '',
        last_name: '',
        country_code: '',
        phone: '',
        gender: '',
        email: '',
        _destroy: false,
      }

      this.items.unshift(item)
      this.emit()
    },
  },
}
</script>
<style scoped lang="scss">
.add-button {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 22px;
  i {
    font-weight: bolder !important;
  }
}
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  color: currentColor;
  z-index: 2;
  i {
    font-weight: bolder !important;
  }
}
</style>
