import axios from 'axios'

export default class Resource {
  constructor (url) {
    this.url = url
  }

  call (options) {
    const { url } = this

    return axios({
      ...options,
      beforeRequest: {
        hidePreloader: true,
      },
      url: `${url}${options.url || ''}`,
    })
  }
}
