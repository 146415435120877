import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueRouter from 'vue-router'
import Gravatar from 'vue-gravatar'
import App from '@/App.vue'
import ActionCableVue from 'actioncable-vue'
import installFontAwesomePlugin from '@/utils/installFontAwesomePlugin'
import installPrototypeUtilitiesPlugin from '@/utils/installPrototypeUtilitiesPlugin'
import installAutoRegisterComponentsPlugin from '@/utils/installAutoRegisterComponentsPlugin'
import { installElement } from './installElement'
import { VueQueryPlugin } from '@tanstack/vue-query'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

export const pinia = createPinia()

export default function (router) {
  Vue.component('VGravatar', Gravatar)
  Vue.use(PiniaVuePlugin)
  Vue.use(VueRouter)
  Vue.use(VueQueryPlugin)
  Vue.use(installFontAwesomePlugin)
  Vue.use(installPrototypeUtilitiesPlugin)
  Vue.use(installAutoRegisterComponentsPlugin)

  const connectionUrl = `ws${['development', 'test'].includes(import.meta.env.MODE) ? '' : 's'}://${window.location.host}/cable`
  Vue.use(ActionCableVue, {
    debug: true,
    debugLevel: 'all',
    connectionUrl,
    connectImmediately: true,
  })

  installElement()

  if (import.meta.env.VITE_SENTRY_DSN?.length > 0) {
    Sentry.init({
      Vue,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.MODE,
      logErrors: true,
      trackComponents: true,
      initialScope: {
        tags: { origin: 'Frontend' },
      },
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      tracesSampleRate: 0.2,
    })
  }

  document.addEventListener('DOMContentLoaded', () => {
    new Vue({
      router,
      pinia,
      render: (createEl) => createEl(App),
    }).$mount('#app')
  })
}
