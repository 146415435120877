<template>
  <div>
    <el-link
      :href="ppm.details_link"
      type="primary"
      :underline="false"
      target="_blank"
      class="mr10">
      {{ $tr('ppm.details_btn') }}
    </el-link>
    <el-tag
      :class="['ppm__tag', `ppm__tag--${ppm.workflow_status}`]">
      {{ $tr(`ppm.${ppm.workflow_status}`) }}
    </el-tag>
  </div>
</template>

<script>
export default {
  props: {
    ppm: { type: Object, required: false, default () { return {} } },
  },
}
</script>
