<template>
  <div
    data-test="time-tracking-form"
    class="time-tracking-details">
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item prop="billable">
          <el-radio-group
            v-model="billableModelValue"
            class="flex justify-between"
            size="small"
            @change="(value)=> handleFormChange('billable_change', value)">
            <el-radio-button
              data-test="non-billable"
              label="non_billable"
              class="flex-grow">
              {{ $tr('time_tracking.non_billable') }}
            </el-radio-button>
            <el-radio-button
              data-test="billable-self"
              label="billable_self"
              class="flex-grow">
              {{ $tr('time_tracking.billable_self') }}
            </el-radio-button>
            <el-radio-button
              v-if="!$isEmpty(ticket.company.parent_company)"
              label="billable_parent"
              class="flex-grow">
              {{ $tr('time_tracking.billable_parent') }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-row
          type="flex"
          class="items-center">
          <el-col
            :span="10"
            class="flex">
            <span class="pr5">{{ $tr('contract.service_package') }}:</span>
          </el-col>
          <el-col :span="14">
            <el-select
              v-model="timeTrackingModel.contract_id"
              placeholder="Contract"
              @change="(value)=> handleFormChange('contract_id', value)">
              <el-option
                v-for="item in contracts"
                :key="item.id"
                :label="item.service_package.value"
                :value="parseInt(item.id)" />
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="24">
        <el-row
          type="flex"
          class="items-center">
          <el-col
            :span="10"
            class="flex">
            <img
              :src="personBlackIcon"
              class="icon-svg mr10" />
            <span class="pr5">{{ $tr('time_tracking.contact_person') }}:</span>
          </el-col>
          <el-col :span="14">
            <SelectWrapper
              v-model="timeTrackingModel.contact_person_id"
              :resource="$apiRoutes.employee"
              :custom-params="{by_company: ticket.company_id}"
              :place-holder="$tr('time_tracking.messages.enter_keyword')"
              data-test="contact-person"
              label-key="full_name"
              value-key="id"
              @change="(value)=> handleFormChange('contact_person_id', value)" />
          </el-col>
        </el-row>
      </el-col>
      <el-col
        data-test="tracked-by"
        :span="24">
        <el-row
          type="flex"
          class="items-center">
          <el-col
            :span="10"
            class="flex">
            <img
              :src="personRedIcon"
              class="icon-svg mr10" />
            <span class="pr5">{{ $tr('time_tracking.tracked_by') }}:</span>
          </el-col>
          <el-col :span="14">
            <strong>{{ $lodash.get(timeTrackingModel, 'tracking_assignee.full_name', '-') }}</strong>
          </el-col>
        </el-row>
      </el-col>
      <el-col
        data-test="phone-call"
        :span="24">
        <el-row
          type="flex"
          class="items-center">
          <el-col
            :span="10"
            class="flex">
            <img
              :src="phoneIcon"
              class="icon-svg mr10" />
            <span class="pr5">{{ $tr('time_tracking.phone_call') }}:</span>
          </el-col>
          <el-col :span="14">
            <el-select
              v-model="timeTrackingModel.phone_call"
              :placeholder="$tr('time_tracking.messages.enter_keyword')"
              @change="(value)=> handleFormChange('phone_call', value)">
              <el-option
                v-for="option in phoneOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value" />
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col
        :span="24">
        <div class="mb10 flex">
          <img
            :src="noteIcon"
            class="flex-shrink-0 icon-svg mr10" />
          <span>{{ $tr('time_tracking.description') }}</span>
        </div>
        <el-form-item
          prop="description">
          <el-input
            v-model="timeTrackingModel.description"
            data-test="description-input"
            type="textarea"
            :rows="2"
            @change="(value)=> handleFormChange('description', value)" />
        </el-form-item>
      </el-col>
      <el-col
        v-for="(timePeriod, index) in timePeriods"
        :key="index"
        data-test="time-interval"
        :span="24">
        <div class="flex justify-between items-center mb10">
          <div
            class="flex"
            :style="timePeriod._destroy ? 'opacity: .4' : ''">
            <img
              :src="clockIcon"
              class="icon-svg mr10" />
            <span>{{ $tr('time_tracking.time_interval') }} {{ timePeriods.length - index }}</span>
          </div>
          <div>
            <span v-if="timePeriod.end_date !== null">
              <el-tooltip
                v-show="!timePeriod._destroy && timePeriodDeletable"
                effect="dark"
                :content="$tr('time_tracking.button.delete_time_period')"
                placement="top">
                <el-button
                  class="p0"
                  :disabled="!updateTimeTrackingPermission"
                  type="text"
                  @click="timePeriod._destroy = true">
                  <img
                    :src="deleteOutlineIcon"
                    class="icon-svg" />
                </el-button>
              </el-tooltip>
              <el-tooltip
                v-show="timePeriod._destroy"
                effect="dark"
                :content="$tr('time_tracking.button.undo_delete_time_period')"
                placement="top">
                <el-button
                  class="p0"
                  :disabled="!updateTimeTrackingPermission"
                  type="text"
                  style="color: 00bf6e; font-size: 20px; height: 19.5px; width: 18px;"
                  icon="el-icon-refresh-left"
                  @click="timePeriod._destroy = false" />
              </el-tooltip>
            </span>
          </div>
        </div>
        <TodoTimeTrackingsItemFormPeriod
          :style="timePeriod._destroy ? 'opacity: .4' : ''"
          class="pl25"
          :time-period="timePeriod"
          :time-period-index="index"
          :handle-form-change="handleFormChange" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TodoTimeTrackingsItemFormPeriod from './TodoTimeTrackingsItemFormPeriod'

export default {
  components: {
    TodoTimeTrackingsItemFormPeriod,
  },
  props: {
    handleFormChange: {
      type: Function,
      required: true,
      default: function () {},
    },
    ticket: {
      type: Object,
      default: function () {
        return {}
      },
    },
    currentUser: {
      type: Object,
      default: function () {
        return {}
      },
    },
    timeTracking: {
      type: Object,
      default: function () {
        return {
          description: 'Tacking for ' + this.ticket.send_email.subject,
          billable: 'Non-billable',
          last_tracker_id: 0,
          tracking_assignee: this.currentUser,
          tracking_assignee_id: this.currentUser.id,
          contact_person_id: this.ticket.company.main_contact_dpo_id,
          phone_call: 'no',
          time_periods: [],
          dpo: {},
          deducted_from_id: '',
          contract_id: null,
          company: this.ticket.company,
        }
      },
    },
    billableModel: {
      type: String,
      required: true,
    },
    personBlackIcon: {
      type: String,
      required: true,
    },
    personRedIcon: {
      type: String,
      required: true,
    },
    phoneIcon: {
      type: String,
      required: true,
    },
    noteIcon: {
      type: String,
      required: true,
    },
    clockIcon: {
      type: String,
      required: true,
    },
    deleteOutlineIcon: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      timeTrackingModel: Object.assign({}, this.timeTracking),
      query: '',
      searchTimer: null,
      dpos: [this.timeTracking.tracking_assignee],
      phoneOptions: [{
        value: 'no',
        label: 'no',
      }, {
        value: 'inbound',
        label: 'Inbound',
      }, {
        value: 'outbound',
        label: 'Outbound',
      }],
      billableModelValue: this.billableModel,
      employees: [],
      contracts: [],
    }
  },
  computed: {
    company () {
      return this.ticket.company
    },
    timePeriods () {
      return this.timeTrackingModel.time_periods
    },
    updateTimeTrackingPermission () {
      if (this.$isBlank(this.timeTrackingModel.last_tracker)) return true
      // admin user is able to edit the tracking just when last_tracker is inactive
      if (!this.timeTrackingModel.last_tracker.account_active && this.$hasRole(['admin'])) return true
      // if it's not active, all users are able to edit
      if (!this.timeTrackingModel.is_active) return true
      // if it's active, just last_tracker is able to edit
      if (this.timeTrackingModel.last_tracker_id === this.currentUser.id) return true

      return false
    },
    timePeriodDeletable () {
      return this.timePeriods.filter(period => period._destroy === false).length > 1
    },
  },
  created () {
    this.timeTrackingModel.company = this.company
    if (this.$isEmpty(this.company.parent_company)) this.timeTracking.deducted_from_id = this.company.id
    this.fetchContracts()
  },
  methods: {
    remoteMethodDpos (query) {
      this.loadDpos(query)
    },
    loadDpos (query) {
      if (this.searchTimer) clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.dpos = []

        let generatedRoute = this.$apiRoutes.dpo
        generatedRoute += '?search=' + encodeURIComponent(query)
        generatedRoute += '&account_active=true'
        generatedRoute += '&sorted[column]=first_name'
        generatedRoute += '&sorted[direction]=asc'
        generatedRoute += '&page=1&per_page=10'

        this.$axios.get(generatedRoute)
          .then(response => {
            this.dpos = this.$deserialize(response.data)
          })
          .catch(e => {
            this.$errorMessage(e.request.status, e)
          })
      }, 500)
    },
    fetchContracts () {
      this.loading = true
      this.$axios.get(
        this.$apiRoutes.contract,
        {
          beforeRequest: {
            hidePreloader: true,
          },
          params: {
            by_company: this.company.id,
          },
        })
        .then(response => {
          this.contracts = this.$deserialize(response.data)
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .el-col-24 {
    + .el-col-24 {
      margin-top: 10px;
    }
  }
  .el-radio-button {
    ::v-deep .el-radio-button__inner {
      width: 100%;
    }
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #007575;
      background: #F4F4F5;
      border-color: #007575;
    }
  }
</style>
