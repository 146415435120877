import { isBlank } from '@/utils/utilityMethods'
import { defineStore } from 'pinia'

// to prevent error in Firefox
if (!('indexedDB' in window)) {
  window.indexedDB =
    window.indexedDB ||
    window.webkitIndexedDB ||
    window.mozIndexedDB ||
    window.oIndexedDB ||
    window.msIndexedDB
}

window.IDBTransaction = window.IDBTransaction ||
  window.webkitIDBTransaction ||
  window.msIDBTransaction || { READ_WRITE: 'readwrite' }
window.IDBKeyRange =
  window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange

if (!window.indexedDB) {
  window.alert(
    "Your browser doesn't support IndexedDB. You can't save emails as draft",
  )
}

export const useIndexedDBStore = defineStore('indexedDBStore', {
  state: () => ({ localDataguardDB: null }),
  actions: {
    openDB() {
      const open = indexedDB.open('dataguardDB', 7)
      open.onupgradeneeded = (e) => {
        this.localDataguardDB = open.result
        if (e.oldVersion < 1) {
          this.localDataguardDB.createObjectStore('drafts', {
            keyPath: 'draftIdentifier',
          })
          this.localDataguardDB.createObjectStore('notes', {
            keyPath: 'company_id',
          })
        }
        if (e.oldVersion < 2) {
          this.localDataguardDB.createObjectStore('companies_filter', {
            autoIncrement: true,
          })
          this.localDataguardDB.createObjectStore('todos_filter', {
            autoIncrement: true,
          })
        }
        if (e.oldVersion < 3) {
          this.localDataguardDB.deleteObjectStore('companies_filter')
          this.localDataguardDB.createObjectStore('companies_filter', {
            autoIncrement: true,
          })
        }
        if (e.oldVersion < 4) {
          this.localDataguardDB.createObjectStore('companies_listed_filters', {
            autoIncrement: true,
          })
        }
        if (e.oldVersion < 5) {
          this.localDataguardDB.deleteObjectStore('companies_filter')
          this.localDataguardDB.deleteObjectStore('companies_listed_filters')
          this.localDataguardDB.deleteObjectStore('todos_filter')

          this.localDataguardDB.createObjectStore('companies_filter', {
            autoIncrement: true,
          })
          this.localDataguardDB.createObjectStore('todos_filter', {
            autoIncrement: true,
          })
          this.localDataguardDB.createObjectStore('companies_listed_filters', {
            autoIncrement: true,
          })
          this.localDataguardDB.createObjectStore('todos_listed_filters', {
            autoIncrement: true,
          })
        }
        if (e.oldVersion < 6) {
          this.localDataguardDB.deleteObjectStore('companies_filter')
          this.localDataguardDB.deleteObjectStore('companies_listed_filters')
          this.localDataguardDB.deleteObjectStore('todos_filter')
          this.localDataguardDB.deleteObjectStore('todos_listed_filters')

          this.localDataguardDB.createObjectStore('companies_filter', {
            autoIncrement: true,
          })
          this.localDataguardDB.createObjectStore('todos_filter', {
            autoIncrement: true,
          })
          this.localDataguardDB.createObjectStore('companies_listed_filters', {
            autoIncrement: true,
          })
          this.localDataguardDB.createObjectStore('todos_listed_filters', {
            autoIncrement: true,
          })
        }
        if (e.oldVersion < 7) {
          this.localDataguardDB.deleteObjectStore('drafts')
          this.localDataguardDB.createObjectStore('drafts', {
            keyPath: 'draftIdentifier',
          })
        }
      }
      open.onsuccess = () => {
        this.localDataguardDB = open.result
      }
    },
    performOpenDB() {
      if (isBlank(this.localDataguardDB)) {
        const store = useIndexedDBStore()
        store.openDB()
      }
    },
    indexedDbOS(tableName) {
      const tx = this.localDataguardDB.transaction(
        [
          'drafts',
          'notes',
          'companies_filter',
          'companies_listed_filters',
          'todos_filter',
          'todos_listed_filters',
        ],
        'readwrite',
      )
      return tx.objectStore(tableName)
    },
  },
})
