<template>
  <div>
    <el-form-item
      v-for="(_, key) in model"
      :key="key"
      :label="`${$tr(`${label}`)}-${key.toUpperCase()}`"
      :rules="rules"
      :prop="`${prop}.${key}`">
      <el-input
        v-model="model[key]"
        v-bind="$attrs" />
    </el-form-item>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default: () => ({ en: '', de: '' }),
    },
    label: {
      type: String,
      default: 'generic_words.title',
    },
    rules: {
      type: Array,
      default: () => null,
    },
    prop: {
      type: String,
      default: 'title',
    },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
