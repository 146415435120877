import apiRoutes from '@/api/apiRoutes'
import { useUserStore } from '@/stores/userStore'
import { useLanguageStore } from '@/stores/languageStore'
import { Message } from 'element-ui'
import { get } from '@/utils/lodashSelectedMethods'
import { dateTimeFormat } from './timeDateFormat.js'

function auditAreaActivityFormat(auditAreaActivity) {
  if (isBlank(auditAreaActivity)) return null
  if (isBlank(auditAreaActivity.value)) return null

  if (auditAreaActivity.audit_area_type === 'datetime') {
    return (
      dateTimeFormat(auditAreaActivity.value) +
      '&nbsp;&nbsp;&nbsp;' +
      dateTimeFormat(auditAreaActivity.value, 'HH:mm')
    )
  }
  return dateTimeFormat(auditAreaActivity.value)
}

function booleanIcon(value) {
  return value ? 'check' : 'times'
}

async function copyText({
  string,
  successMessage = 'Text copied to clipboard successfully',
}) {
  await navigator.clipboard.writeText(string)
  Message({
    showClose: true,
    message: successMessage,
    type: 'success',
    duration: 3000,
    dangerouslyUseHTMLString: true,
  })
}

function doScrolling(query, duration) {
  const startingY = window.pageYOffset
  const elementY =
    window.pageYOffset +
    document.querySelector(query).getBoundingClientRect().top
  // If element is close to page's bottom then window will scroll only to some position above the element.
  const targetY =
    document.body.scrollHeight - elementY < window.innerHeight
      ? document.body.scrollHeight - window.innerHeight
      : elementY
  const diff = targetY - startingY
  // Easing function: easeInOutCubic
  // From: https://gist.github.com/gre/1650294
  const easing = function (t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
  }
  let start

  if (!diff) return

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp
    // Elapsed miliseconds since start of scrolling.
    const time = timestamp - start
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1)
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    percent = easing(percent)

    window.scrollTo(0, startingY + diff * percent)

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step)
    }
  })
}

function downloadLink(id, type = 'attachment') {
  return apiRoutes.download + '/' + id + '?type=' + type
}

function emailStatus(key) {
  let status = ''
  switch (key) {
    case 'sending':
      break
    case 'delivered':
      status = 'success'
      break
    case 'failed':
      status = 'danger'
      break
  }
  return status
}

function errorMessage(statusCode, error, messageType = null, testID) {
  const msgType = messageType || 'error'
  const attrs = {}
  switch (msgType) {
    case 'error':
      attrs['data-test'] = 'error-message'
      break
    case 'warning':
      attrs['data-test'] = 'warning-message'
      break
    default:
      attrs['data-test'] = 'success-message'
  }

  if (testID) {
    attrs['data-test'] = testID
  }

  if (statusCode === -1) {
    showAlertMessage(error, msgType, attrs)
    return
  }

  try {
    let errors = []
    let errorMsg = ''
    const errorMessageResponse = JSON.parse(error.request.response)

    if (errorMessageResponse.jsonapi) {
      errors = errorMessageResponse.errors
    } else {
      errors = errorMessageResponse.error
    }

    if (Array.isArray(errors)) {
      for (let i = 0; i < errors.length; i++) {
        const item = errors[i]
        errorMsg += '<p>'
        if (!hasRole(['customer'])) {
          errorMsg += item.title + ': '
        }
        errorMsg += item.detail + '</p>'
      }
    } else if (typeof errors === 'object') {
      Object.entries(errors).forEach((item) => {
        errorMsg += '<p>'
        if (!hasRole(['customer'])) {
          errorMsg += item[0] + ': '
        }
        errorMsg += item[1].join() + '</p>'
      })
    } else {
      errorMsg = errors
    }

    showAlertMessage(errorMsg, msgType, attrs)
  } catch (e) {
    if (error.request.statusText === '') {
      showAlertMessage('Network Error', msgType, attrs)
    } else if (error.request.response === '') {
      showAlertMessage('Response is empty: ', error.request.statusText, attrs)
    } else {
      if (error.response) {
        const msg = get(error.response, 'statusText', error.request.response)
        showAlertMessage(msg, msgType, attrs)
      } else {
        showAlertMessage(error.request.response, msgType, attrs)
      }
    }
  }
}

function extractEmailBody(body) {
  if (isBlank(body)) return ''

  const match = body.match(/<body>([^]*)<\/body>/)
  if (match) return match[1]
  return body
}

function featureFlagIsActive(flagValue) {
  const userStore = useUserStore()
  return userStore.currentUser.active_feature_flags.includes(flagValue)
}

function generateTestID(string) {
  return string?.toLowerCase().replaceAll(' ', '-')
}

function getUrl(url) {
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = '//' + url
  }
  return url
}

function hasRole(arrayOfSpecificRoles) {
  const userStore = useUserStore()
  return userStore.getCurrentUserRoles.some(
    (role) => arrayOfSpecificRoles.indexOf(role) !== -1,
  )
}

function isBlank(value) {
  if (value === '' || value === null || value === undefined) {
    return true
  }
  return false
}

function isEmpty(item) {
  if (isBlank(item)) return true
  if (Object.keys(item).length === 0) {
    return true
  }
  return false
}

function numMetricFormatter(num, digits) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

function pluck(array, property) {
  const plucked = []
  for (let i = 0; i < array.length; ++i) {
    plucked.push(array[i][property])
  }
  return plucked
}

function showAlertMessage(errorMessageResponse, msgType, attrs) {
  let response = errorMessageResponse
  if (attrs) {
    response = wrapMessage(attrs, response)
  }
  Message({
    showClose: true,
    message: response,
    type: msgType,
    duration: 3000,
    dangerouslyUseHTMLString: true,
  })
}

function splitWords(string, separator = ' ', merger = ' ') {
  return string !== null ? string.split(separator).join(merger) : ''
}

function swapItemInObj(parentObj, index1, index2) {
  const swapTemp = parentObj[index1]
  parentObj[index1] = parentObj[index2]
  parentObj[index2] = swapTemp

  return parentObj
}

function tr(string, replacement) {
  const languageStore = useLanguageStore()
  const lang = languageStore.lang || document.documentElement.lang

  if (isBlank(lang)) return

  let translate = window.DATAGUARD.locale()
  translate = translate[lang][string]

  if (translate === undefined) {
    return string // translate of string not exist
  }

  // No replacement trigger is found in translation string
  if (translate.split(/%{\w+}/).length <= 1) {
    return translate
  }

  // Second argument is empty
  if (isEmpty(replacement)) {
    return translate
  }

  // Replace
  translate = translate.split(/%{\w+}/).join('%{}')
  replacement.forEach((item) => {
    translate = translate.replace('%{}', item)
  })
  return translate
}

function truncate(string, length = 20) {
  if (isBlank(string)) return string
  if (string.length > length) {
    return string.substring(0, length) + '...'
  }
  return string
}

function wrapMessage(attrs, content) {
  const attrsString = Object.entries(attrs)
    .map(([prop, value]) => `${prop}="${value}"`)
    .reduce((acc, curr) => `${acc} ${curr}`)

  return `<div ${attrsString} >${content}</div>`
}

function validSpecificPassword(value, callback) {
  const LowerCaseRegex = '(?=.*[a-z])'
  const UpperCaseRegex = '(?=.*[A-Z])'
  const numricRegex = '(?=.*[0-9])'
  const specialRegex = '(?=.[!@#$%^&])'
  if (isBlank(value)) {
    let message = tr('messages.password')
    message =
      message === 'messages.password'
        ? tr('auth.messages.password')
        : tr('messages.password')
    callback(new Error(message))
  } else if (value.length < 8) {
    let leastCharsMessage = tr('messages.least_8_chars')
    leastCharsMessage =
      leastCharsMessage === 'messages.least_8_chars'
        ? tr('auth.messages.least_8_chars')
        : tr('messages.least_8_chars')
    callback(new Error(leastCharsMessage))
  } else if (!value.match(LowerCaseRegex)) {
    let lowerCaseMessage = tr('messages.lower_case')
    lowerCaseMessage =
      lowerCaseMessage === 'messages.lower_case'
        ? tr('auth.messages.lower_case')
        : tr('messages.lower_case')
    callback(new Error(lowerCaseMessage))
  } else if (!value.match(UpperCaseRegex)) {
    let upperCaseMessage = tr('messages.upper_case')
    upperCaseMessage =
      upperCaseMessage === 'messages.upper_case'
        ? tr('auth.messages.upper_case')
        : tr('messages.upper_case')
    callback(new Error(upperCaseMessage))
  } else if (!value.match(numricRegex)) {
    let numericCharacterMessage = tr('messages.numeric_character')
    numericCharacterMessage =
      numericCharacterMessage === 'messages.numeric_character'
        ? tr('auth.messages.numeric_character')
        : tr('messages.numeric_character')
    callback(new Error(numericCharacterMessage))
  } else if (!value.match(specialRegex)) {
    let specialCharacterMessage = tr('messages.special_character')
    specialCharacterMessage =
      specialCharacterMessage === 'messages.special_character'
        ? tr('auth.messages.special_character')
        : tr('messages.special_character')
    callback(new Error(specialCharacterMessage))
  }
}

function trimMultiLineString(string) {
  return string?.trim().replace(/\s+/g, ' ')
}

export {
  auditAreaActivityFormat,
  booleanIcon,
  copyText,
  doScrolling,
  downloadLink,
  emailStatus,
  errorMessage,
  extractEmailBody,
  featureFlagIsActive,
  generateTestID,
  getUrl,
  hasRole,
  isBlank,
  isEmpty,
  numMetricFormatter,
  pluck,
  showAlertMessage,
  splitWords,
  swapItemInObj,
  tr,
  truncate,
  validSpecificPassword,
  trimMultiLineString,
}
