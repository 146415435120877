<template>
  <div>
    <el-dialog
      ref="todo-dialog"
      append-to-body
      data-test="dialog--edit-todo-item"
      :visible.sync="modal.view"
      width="75vw"
      class="new-design tracking_details"
      :lock-scroll="false"
      destroy-on-close
      @opened="openTrigger"
      @close="timeTrackingFadeClass = ''">
      <div slot="title">
        <TodoHeader
          :key="headerId"
          v-loading="loading"
          :ticket="ticket"
          @update-ticket="loadTicketData"
          @scroll-to-bottom-of-dialog="scrollToBottomOfDialog" />
      </div>
      <el-row :gutter="20">
        <el-col
          v-if="$userCan('view:time_trackings')"
          :span="10">
          <TodoTimeTrackings
            :key="ticket.id"
            v-loading="loading"
            :class="timeTrackingFadeClass"
            :ticket="ticket"
            @update-ticket="loadTicketData" />
        </el-col>
        <el-col :span="$userCan('view:time_trackings') ? 14 : 24">
          <TodoProperties
            v-loading="loading"
            :ticket="ticket"
            :support-services="supportServices"
            @update-ticket="loadTicketData"
            @edit-task-toggle="updateTrackingVisibility" />
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
      v-if="currentUser.role === 'dpo'"
      width="initial"
      class="floating-actions"
      top="0"
      :visible.sync="floatingActionsVisibility"
      :append-to-body="true"
      :modal="false"
      :lock-scroll="false"
      :show-close="false"
      :close-on-click-modal="false">
      <FloatingTodoTimeTracking />
    </el-dialog>
  </div>
</template>

<script>
import TodoProperties from './TodoProperties'
import TodoHeader from './TodoHeader'
import TodoTimeTrackings from './TodoTimeTrackings'
import FloatingTodoTimeTracking from './FloatingTodoTimeTracking'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  components: {
    TodoProperties,
    TodoTimeTrackings,
    TodoHeader,
    FloatingTodoTimeTracking,
  },
  data () {
    return {
      loading: false,
      ticket: {},
      timeTrackingFadeClass: '',
      headerId: 0,
      supportServices: [],
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'runningTask',
      'ticketModalState',
      'currentUser',
    ]),
    modal: {
      get () {
        return this.ticketModalState
      },
      set (value) {
        this.setTicketModalState(value)
      },
    },
    floatingActionsVisibility () {
      return !(this.modal.view && parseInt(this.runningTask.ticketId) === parseInt(this.modal.id)) && (this.runningTask.active || this.runningTask.pause)
    },
  },
  created () {
    this.resetData()
  },
  methods: {
    ...mapActions(useUserStore, [
      'setTicketModalState',
      'fetchTickets',
      'updateTicketItem',
      'runTrackingAction',
      'setRunningTask',
    ]),
    updateTrackingVisibility (eventData) {
      this.timeTrackingFadeClass = eventData ? 'faded-out' : ''
    },
    openTrigger () {
      this.resetData()

      if (this.modal.afterOpen === 'stopTimeTracking') {
        this.handleActionOnOpen('stop')
      } else if ((this.modal.afterOpen === 'continueTimeTracking')) {
        this.handleActionOnOpen('continue')
      } else {
        this.loadTicketData()
      }
    },
    resetData () {
      this.ticket = {
        id: null,
        send_email: {
          subject: '',
          body: '',
        },
        audit_call: false,
        deadline: '',
        created_at: '',
        status: '-',
        active_tracking: false,
        last_tracker_id: null,
      }
    },
    handleActionOnOpen (mode) {
      const data = {
        ticket_id: this.modal.id,
        action: mode,
      }
      this.runTrackingAction(data).then(() => {
          return this.loadTicketData()
        })
        .then(() => {
          if (mode === 'stop') {
            this.$root.$emit('tracking-stopped')
          } else if (mode === 'continue') {
            this.$root.$emit('tracking-started')
          }
        })
    },
    loadTicketData () {
      this.loading = true

      const generatedRoute = this.$apiRoutes.ticket + '/' + this.modal.id
      return this.$axios
        .get(generatedRoute, {
          beforeRequest: {
            hidePreloader: true,
          },
        })
        .then(response => {
          this.ticket = this.$deserialize(response.data)
          this.updateTicketItem(this.ticket)
          this.fetchTickets()
          this.fetchSupportServices()
          this.loading = false
          this.headerId++

          if (this.ticket.active_tracking) {
            this.setRunningTask({
              companyName: this.$lodash.get(this.ticket, 'company.name', 'Unknown Company'),
              ticketSubject: this.$lodash.get(this.ticket, 'send_email.subject', 'Anonymous Subject'),
              ticketSpentTime: this.ticket.spent_time_tracking,
              ticketId: this.ticket.id,
              trackingSpentTime: this.ticket.active_time_tracking.spent_time_tracking,
              timeTrackingId: parseInt(this.ticket.active_time_tracking.id),
              lastTrackerId: this.ticket.last_tracker_id,
              pause: this.ticket.active_time_tracking.pause,
            })
          }
        })
        .catch(e => {
          this.loading = false
          this.modal = { view: false }
          this.$errorMessage(e.request.status, e)
        })
      },
      scrollToBottomOfDialog () {
        this.$nextTick(() => {
          const dialog = this.$refs['todo-dialog'].$el
          if (dialog) {
            dialog.scrollTo({
              top: dialog.scrollHeight,
              behavior: 'smooth'
            });
          }
        })
      },
      fetchSupportServices () {
        this.$axios
          .get(this.$apiRoutes.support_service, {
            params: {
              company_id: this.ticket.company_id,
            },
            beforeRequest: {
              hidePreloader: true,
            },
          })
          .then(response => {
            this.supportServices = this.$deserialize(response.data)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
  .tracking_details {
     ::v-deep.el-card,
     ::v-deep.el-tag {
      font-size: 12px
    }
     ::v-deep.el-col {
      padding-bottom: 0;
    }
  }
  .status-select {
    ::v-deep {
      .el-input__inner {
        height: 35px;
        line-height: 35px;
      }
      .el-input__icon {
        line-height: 35px;
      }
    }
  }
</style>
