const installAutoRegisterComponentsPlugin = {
  install(Vue) {
    const autoRegister = (requireComponents) => {
      Object.entries(requireComponents).forEach(([path, definition]) => {
        const componentName = path
          .split('/')
          .pop()
          .replace(/\.\w+$/, '')
        Vue.component(componentName, definition.default)
      })
    }
    const globalComponents = import.meta.glob('@/components/base/**/*.vue', { eager: true })
    autoRegister(globalComponents)
  },
}

export default installAutoRegisterComponentsPlugin
