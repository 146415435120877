<template>
  <div class="document-answer">
    <el-form
      ref="documentAnswerForm"
      v-loading="loading"
      :model="documentAnswer"
      :rules="rules"
      data-test="document-answer-form"
      label-position="top">
      <Customer
        :customer.sync="documentAnswer"
        :ppm="ppm"
        :company="dpaModal.ticket.company" />

      <Partners :partners.sync="documentAnswer.partners" />

      <el-row :gutter="20">
        <el-col :span="24">
          <FileCategories
            :files.sync="documentAnswer.files"
            :required-files="requiredFiles"
            :absent-files="absentFiles" />
        </el-col>
      </el-row>
      <br />
      <el-row
        v-if="!documentAnswer.id"
        :gutter="20">
        <el-col :span="24">
          <PreloadList
            :partner-title="$lodash.get(documentAnswer.partners[0], 'name')"
            :document-type="dpaModal.document_type"
            @selected-dpa="
              id => (documentAnswer.documentAnswerForPreloadId = id)
            " />
        </el-col>
      </el-row>

      <el-form-item size="large">
        <el-button
          type="primary"
          style="float: right;"
          data-test="next-button"
          round
          @click="saveDocumentAnswer">
          Next
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { countryList } from '@/utils/countryList'
import Partners from './document-answer-from-elements/Partners'
import PreloadList from './document-answer-from-elements/PreloadList'
import FileCategories from './document-answer-from-elements/FileCategories'
import Customer from './document-answer-from-elements/Customer'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  components: {
    Partners,
    Customer,
    FileCategories,
    PreloadList,
  },
  props: {
    documentRoutes: {
      type: Object,
      required: true,
      default () {
        return {
          answer: this.$apiRoutes.dpa_answer,
        }
      },
    },
  },
  data () {
    return {
      requiredFiles: ['privacy_contract'],
      absentFiles: [],
      loading: false,
      ppm: null,
      documentAnswer: {
        id: null,
        red_privacy_contract_id: null,
        date: '',
        version: '',
        location_type: '',
        location_id: '',
        company_id: '',
        customer_role: '',
        main_contract_file_id: '',
        review_report_file_id: '',
        privacy_contract_file_id: '',
        additional_attachment_file_ids: [],
        it_reviewer: '',
        legal_reviewer: '',
        partners: [],
        files: [],
        documentAnswerForPreloadId: null,
      },
      mainContractFiles: [],
      reviewReportFiles: [],
      privacyContractFiles: [],
      additionalAttachmentFiles: [],
      selectedMainContractFromEmail: '',
      selectedReviewReportFromEmail: '',
      selectedPrivacyContractFromEmail: '',
      selectedAdditionalAttachmentFromEmail: '',
      contactPersonItems: null,
      selectedContactPerson: null,
      dpos: [],
      documentAnswerEdit: false,
      countryList,
      rules: {
        company_id: [
          {
            required: true,
            message: this.$tr('document_section.messages.input_company'),
            trigger: ['blur', 'change'],
          },
        ],
        version: [
          {
            required: true,
            message: this.$tr('document_section.messages.version'),
            trigger: ['blur', 'change'],
          },
        ],
        customer_role: [
          {
            required: true,
            message: this.$tr('document_section.messages.customer_role'),
            trigger: ['blur', 'change'],
          },
        ],
        partners: [{
          name: [
            {
              required: true,
              message: this.$tr('document_section.messages.title'),
              trigger: ['blur', 'change'],
            },
          ],
          address: {
            country: [
              {
                required: true,
                message: this.$tr('document_section.messages.country'),
                trigger: ['blur', 'change'],
              },
            ],
            city: [
              {
                required: true,
                message: this.$tr('document_section.messages.city'),
                trigger: ['blur', 'change'],
              },
            ],
            street: [
              {
                required: true,
                message: this.$tr('document_section.messages.street'),
                trigger: ['blur', 'change'],
              },
            ],
            street_no: [
              {
                required: true,
                message: this.$tr('document_section.messages.street_number'),
                trigger: ['blur', 'change'],
              },
            ],
            postal_code: [
              {
                required: true,
                message: this.$tr('document_section.messages.postal_code'),
                trigger: ['blur', 'change'],
              },
            ],
          },
        }],
      },
    }
  },
  computed: {
    ...mapState(useUserStore, ['dpaModalState']),
    dpaModal: {
      get () {
        return this.dpaModalState
      },
      set (value) {
        this.setDpaModalState(value)
      },
    },
    documentAnswerForm () {
      return this.$refs.documentAnswerForm
    },
    customerRoleMapper () {
      return {
        controller: 'client',
        processor: 'contractor',
      }
    },
  },
  created () {
    this.fetchDpaAnswer()

    const { ticket } = this.dpaModal
    this.documentAnswer.location_type = 'Ticket'
    this.documentAnswer.location_id = ticket.id
    this.documentAnswer.company_id = parseInt(ticket.company.id)
  },
  methods: {
    ...mapActions(useUserStore, ['setDpaModalState']),
    fetchDpaAnswer () {
      if (
        this.$lodash.every(
          ['dpa', 'thirteen_art', 'fourteen_art', 'mix_art'],
          item => this.$isBlank(this.dpaModal.ticket[item]),
        )
      ) {
        this.fetchDpaAnswerFromRedWorld()
      } else {
        this.fetchDpaAnswerFromBlueWorld()
      }
    },
    fetchDpaAnswerFromBlueWorld () {
      this.loading = true

      let generatedRoute = this.$apiRoutes.dpa_answer + '/'
      generatedRoute += this.dpaModal.ticket[this.dpaModal.document_type].id

      this.$axios
        .get(generatedRoute, {
          beforeRequest: {
            hidePreloader: true,
          },
        })
        .then(response => {
          const documentAnswer = this.$deserialize(response.data)
          if (this.$isBlank(documentAnswer)) {
            return
          }

          const {
            main_contract_file: mainContractFile,
            review_report_file: reviewReportFile,
            privacy_contract_file: privacyContractFile,
            additional_attachment_files: additionalAttachmentFiles,
          } = documentAnswer
          this.documentAnswerEdit = true
          this.dpos.push(documentAnswer.dpo)
          this.documentAnswer = {
            id: documentAnswer.id,
            date: documentAnswer.date,
            red_privacy_contract_id: documentAnswer.red_privacy_contract_id,
            version: documentAnswer.version,
            document_type: this.dpaModal.document_type,
            location_type: 'Ticket',
            location_id: documentAnswer.location_id,
            company_id: documentAnswer.company_id,
            customer_role: documentAnswer.customer_role,

            partners: documentAnswer.partners ? documentAnswer.partners : [],
            files: [],

            main_contract_file_id: mainContractFile ? mainContractFile.id : '',
            review_report_file_id: reviewReportFile ? reviewReportFile.id : '',
            privacy_contract_file_id: privacyContractFile
              ? privacyContractFile.id
              : '',
            additional_attachment_file_ids: additionalAttachmentFiles
              ? this.$pluck(additionalAttachmentFiles, 'id')
              : [],
            it_reviewer: documentAnswer.it_reviewer,
            legal_reviewer: documentAnswer.legal_reviewer,
          }
          this.mainContractFiles = mainContractFile ? [mainContractFile] : []
          this.reviewReportFiles = reviewReportFile ? [reviewReportFile] : []
          this.privacyContractFiles = privacyContractFile
            ? [privacyContractFile]
            : []
          this.additionalAttachmentFiles = additionalAttachmentFiles || []

          if (response.data.meta.include_ppm) {
            const { ppm } = response.data.meta
            this.ppm = ppm
            this.documentAnswer.red_privacy_contract_id = ppm.id
            this.documentAnswer.customer_role = this.customerRoleMapper[ppm.customer_role]
            this.documentAnswer.partners = ppm.partners
            this.documentAnswer.files = ppm.files || []
          }
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchDpaAnswerFromRedWorld () {
      let url = this.$apiRoutes.privacy_contract
      url += '?company_id=' + this.dpaModal.ticket.company.id
      url += '&by_ticket_app_id=' + this.dpaModal.ticket.id
      this.$axios.get(url)
        .then(response => {
          const result = this.$deserialize(response.data)
          if (result.length === 0) return

          this.ppm = result[0]
          this.documentAnswer.red_privacy_contract_id = result[0].id
          this.documentAnswer.partners = result[0].partners
          this.documentAnswer.customer_role = this.customerRoleMapper[
            result[0].customer_role
          ]
          this.documentAnswer.files = result[0].files
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    saveDocumentAnswer () {
      this.documentAnswerForm.validate(valid => {
        this.requiredFiles.forEach(fileType => {
          this.checkFile(fileType)
        })

        if (this.absentFiles.length !== 0 || !valid) return false

        if (this.documentAnswer.id) {
          this.updateDocumentAnswer()
        } else {
          this.newDocumentAnswer()
        }
      })
    },
    checkFile (fileType) {
      const file = this.documentAnswer.files.find(
        el => el.file_type === fileType,
      )

      if (!file) {
        if (!this.absentFiles.includes(fileType)) {
          this.absentFiles.push(fileType)
        }
      } else {
        this.absentFiles = this.absentFiles.filter(e => e !== fileType)
      }
    },
    updateDocumentAnswer () {
      this.$axios
        .put(
          this.documentRoutes.answer + '/' + this.documentAnswer.id,
          this.documentAnswer,
        )
        .then(() => {
          this.nextPage()
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    newDocumentAnswer () {
      this.$axios
        .post(this.documentRoutes.answer, this.documentAnswer)
        .then(response => {
          this.documentAnswer.id = response.data.data.id
          this.nextPage()
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    nextPage () {
      this.$emit('show-section', this.documentAnswer)
    },
  },
}
</script>
<style lang="scss">
.document-answer {
  .el-form-item {
    position: relative;
  }

  .el-form-item__label {
    position: absolute;
    top: 0;
    margin-top: -6px;
    font-weight: 700;
    background-color: #fff;
    white-space: nowrap;
    z-index: 1;
    left: 5px;
    line-height: 1;
    padding: 0 5px !important;
    font-size: 12px;
  }

  .el-input__inner {
    height: 45px;
    line-height: 45px;
    padding-top: 10px;
  }

  .row-separator {
    position: relative;
    margin: 15px 0 30px;
    padding-left: 15px;
    text-align: center;

    &__title {
      background-color: #fff;
      position: relative;
      z-index: 2;
      display: inline-block;
      padding: 0 15px;
      font-weight: bold;
      color: #606266;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: #dcdfe6;
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      margin-top: -0.5px;
      z-index: 1;
    }
  }
}
</style>
