<template>
  <div>
    <textarea
      :id="id"
      v-model="content"
      :name="name" />
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import 'tinymce/models/dom'
import 'tinymce/themes/silver/theme.min'
import 'tinymce/icons/default/icons.min'
import 'tinymce/skins/ui/oxide/skin.min.css'

import 'tinymce/plugins/advlist'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link'
import 'tinymce/plugins/media'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/template'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/code'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/help'
import 'tinymce/plugins/help/js/i18n/keynav/en.js'
import 'tinymce/plugins/image'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/save'
import 'tinymce/plugins/table'
import 'tinymce/plugins/visualchars'
import './plugins/dg_menu'

const inc = new Date().getTime()
export default {
  name: 'Tinymce',
  props: {
    id: {
      type: String,
      default: () => `editor-${inc}`,
    },
    name: {
      type: String,
      default: () => `editor-${inc}`,
    },
    height: {
      type: Number,
      default: 400,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: '',
      type: String,
    },
    quandora: {
      default: false,
      type: Boolean,
    },
  },
  data () {
    return {
      content: this.value,
      editor: null,
      isTyping: false,
      font_size_formats:
        '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 39px 34px 38px 42px 48px',
      plugins: [
        'advlist',
        'autolink',
        'lists',
        'link',
        'image',
        'charmap',
        'preview',
        'anchor',
        'pagebreak',
        'searchreplace',
        'wordcount',
        'visualblocks',
        'visualchars',
        'code',
        'fullscreen',
        'insertdatetime',
        'nonbreaking',
        'save',
        'table',
        'directionality',
        'template',
        'help',
        'dg-custom-buttons',
      ],
      toolbar1:
        'blocks fontsize | bold italic underline strikethrough | forecolor backcolor | link image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat  | fullscreen | help',
    }
  },
  computed: {
    uploadRoute () {
      return this.$apiRoutes.public_content
    },
  },
  watch: {
    value: function (newValue) {
      if (!this.isTyping) {
        if (this.editor !== null) {
          this.editor.setContent(newValue)
        } else {
          this.content = newValue
        }
      }
    },
    disabled (value) {
      this.editor.mode.set(value ? 'readonly' : 'design')
    },
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  methods: {
    init () {
      const { quandora } = this
      const options = {
        selector: '#' + this.id,
        skin: false,
        height: this.height,
        font_size_formats: this.font_size_formats,
        toolbar1: this.toolbar1,
        plugins: this.plugins,
        images_upload_url: this.uploadRoute,
        init_instance_callback: this.initEditor,
        relative_urls: false,
        convert_urls: false,
        remove_script_host: false,
        browser_spellcheck: true,
        allow_conditional_comments: true,
        convert_unsafe_embeds: true,
        valid_children: '+body[style]',
        content_css: 'https://elasticbeanstalk-eu-central-1-537055275646.s3.eu-central-1.amazonaws.com/dg-document.css',
        readonly: this.disabled,
        contextmenu: `dgButtons vvtButtons tomButtons coreProcessButtons ${quandora ? '| quandora' : ''}`,
        promotion: false,
        setup: (editor) => {
          if (quandora) {
            editor.ui.registry.addMenuItem('quandora', {
              icon: 'help',
              text: this.$tr('quandora.new_question'),
              onAction: () => {
                const content = editor.selection.getContent()
                this.$emit('quandoraOnClick', content)
              },
            })
          }
        },
      }
      tinymce.init(options)
    },
    initEditor (editor) {
      this.editor = editor
      editor.on('KeyUp', () => {
        this.submitNewContent()
      })
      editor.on('Change', e => {
        if (this.editor.getContent() !== this.value) {
          this.submitNewContent()
        }
        this.$emit('editorChange', e)
      })
      const mountContent = () => {
        editor.setContent(this.content)
        this.$emit('input', this.content)
      };
      (editor.initialized && mountContent()) || editor.on('init', mountContent)
    },
    submitNewContent () {
      this.isTyping = true
      if (this.checkerTimeout !== null) {
        clearTimeout(this.checkerTimeout)
      }
      this.checkerTimeout = setTimeout(() => {
        this.isTyping = false
      }, 300)
      this.$emit('input', this.editor.getContent())
    },
  },
}
</script>
