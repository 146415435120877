<template>
  <div>
    <el-row
      :gutter="10"
      type="flex"
      class="items-center">
      <el-col :span="3">Start</el-col>
      <el-col :span="13">
        <el-date-picker
          v-model="localTimePeriod.start_date"
          format="dd.MM.yyyy HH:mm:ss"
          type="datetime"
          prefix-icon="el-icon-date" />
      </el-col>
      <el-col :span="8">
        <el-select
          v-model="defaultDuration"
          :placeholder="$tr('time_tracking.messges.select_duration')"
          :disabled="localTimePeriod.end_date === null">
          <el-option
            v-for="time in timeDurations"
            :key="time.value"
            :label="time.label"
            :value="time.value" />
        </el-select>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="items-center mt5">
      <el-col :span="3">End</el-col>
      <el-col :span="13">
        <el-date-picker
          v-model="localTimePeriod.end_date"
          :disabled="
            localTimePeriod.end_date === null || defaultDuration !== -1
          "
          format="dd.MM.yyyy HH:mm:ss"
          type="datetime"
          prefix-icon="el-icon-date" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { dayjs } from '@/utils/timeDateFormat'

export default {
  props: {
    timePeriod: {
      type: Object,
      default: function () {
        return {
          id: null,
          start_date: '',
          end_date: '',
          _destroy: false,
        }
      },
    },
    timePeriodIndex: {
      type: Number,
      required: true,
    },
    handleFormChange: {
      type: Function,
      required: true,
      default: function () {},
    },
  },
  data () {
    return {
      timeDurations: [
        { value: 5, label: '5 mins' },
        { value: 10, label: '10 mins' },
        { value: 15, label: '15 mins' },
        { value: -1, label: 'custom' },
      ],
      defaultDuration: -1,
      localTimePeriod: Object.assign({}, this.timePeriod),
      isStartDateReset: false,
      isEndDateReset: false,
      isStartDateErrorMessageShown: false,
      isEndDateErrorMessageShown: false,
    }
  },
  watch: {
    defaultDuration (newValue) {
      if (newValue === -1) return
      this.localTimePeriod.end_date = dayjs(this.localTimePeriod.start_date).add(newValue, 'm').toDate()
    },
    'localTimePeriod.start_date' (newStartDate, oldStartDate) {
      this.handleDateChange('start_date', newStartDate, oldStartDate)
    },
    'localTimePeriod.end_date' (newEndDate, oldEndDate) {
      this.handleDateChange('end_date', newEndDate, oldEndDate)
    },
  },
  methods: {
    getFlags(dateType) {
      return {
        errorMessageFlag: dateType === 'start_date' ? 'isStartErrorShown' : 'isEndErrorShown',
        resetDateFlag: dateType === 'start_date' ? 'isStartReset' : 'isEndReset',
      }
    },

    getDates(dateType, newDate) {
      const startDate = dateType === 'start_date'
        ? new Date(newDate)
        : new Date(this.localTimePeriod.start_date)

      const endDate = dateType === 'start_date'
        ? new Date(this.localTimePeriod.end_date || new Date())
        : new Date(newDate)

      return { startDate, endDate }
    },

    showError(dateType, errorMessageFlag) {
      this.$errorMessage(-1, this.$tr(`time_tracking.messages.${dateType}`), 'error')
      this[errorMessageFlag] = true
    },

    resetDate(dateType, oldDate, resetDateFlag) {
      this[resetDateFlag] = true
      this.localTimePeriod[dateType] = oldDate
      this.$nextTick(() => {
        this[resetDateFlag] = false
      })
    },

    handleDateChange(dateType, newDate, oldDate) {
      const { errorMessageFlag, resetDateFlag } = this.getFlags(dateType)

      if (this[resetDateFlag]) return

      const { startDate, endDate } = this.getDates(dateType, newDate)

      if (startDate > endDate) {
        if (!this[errorMessageFlag]) {
          this.showError(dateType, errorMessageFlag)
        }

        this.resetDate(dateType, oldDate, resetDateFlag)
        return
      }

      this[errorMessageFlag] = false
      this.handleFormChange('time_periods', this.localTimePeriod, this.timePeriodIndex)
    },
  },
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input {
  width: 180px;
}
</style>
