import NotesResource from '@/api/notes'
import { isEmpty } from 'lodash'
import { deserialize } from 'jsonapi-deserializer'
import { errorMessage } from '@/utils/utilityMethods'
import { defineStore } from 'pinia'

let fetchAllParams = {}
const maxPinsAllowed = 7

export const useNotesStore = defineStore('notesStore', {
  state: () => ({
    allNotes: [],
    allNotesSize: 0,
    pinnedNotes: [],
    pinnedNotesSize: 0,
  }),
  getters: {
    maxPinsReached: (state) => state.pinnedNotesSize >= maxPinsAllowed,
  },
  actions: {
    setInactiveIncluded(data) {
      this.isInactiveIncluded = data
    },
    fetchAllNotes(params) {
      fetchAllParams = isEmpty(params) ? fetchAllParams : params // use the previous params if params is empty
      return NotesResource.list(fetchAllParams)
        .then((response) => {
          this.allNotes = deserialize(response.data)
          this.allNotesSize = Number(response.data.meta.total_count)
        })
        .catch((e) => {
          errorMessage(e.request.status, e)
        })
    },
    createNote(data) {
      return NotesResource.create(data).then(() => {
        this.fetchAllNotes()
      })
    },
    updateNote({ id, data }) {
      return NotesResource.update({ id, data }).then(() => {
        this.fetchAllNotes()
      })
    },
    deleteNote(id) {
      return NotesResource.destroy(id).then(() => {
        this.allNotes = this.allNotes.filter((note) => note.id !== id)
        this.allNotesSize = Number(this.allNotesSize - 1)
        this.unPinNoteFromStore(id)
      })
    },
    fetchPinnedNotes(id) {
      return NotesResource.list({ pinned_notes: true, by_company: id })
        .then((response) => {
          this.pinnedNotes = deserialize(response.data)
          this.pinnedNotesSize = deserialize(response.data).length
        })
        .catch((e) => {
          errorMessage(e.request.status, e)
        })
    },
    pinNote(id) {
      if (!this.maxPinsReached) {
        return NotesResource.update({ id, data: { pinned: true } }).then(() => {
          const pinnedNote = this.allNotes.find((pin) => pin.id === id)
          pinnedNote.pinned = true
          this.pinnedNotes.unshift(pinnedNote)
          this.pinnedNotesSize = this.pinnedNotesSize + 1
        })
      }
    },
    unPinNote(id) {
      return NotesResource.update({ id, data: { pinned: false } }).then(() => {
        this.unPinNoteFromStore(id)
      })
    },
    unPinNoteFromStore(id) {
      const pinnedNote = this.allNotes.find((pin) => pin.id === id)
      const pinnedNoteIndex = this.pinnedNotes.findIndex((pin) => pin.id === id)

      if (pinnedNote) {
        pinnedNote.pinned = false
      }
      this.pinnedNotes.splice(pinnedNoteIndex, 1)
      this.pinnedNotesSize = this.pinnedNotesSize - 1
    },
  },
})
