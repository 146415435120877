<template>
  <el-form
    v-loading="loading"
    class="task-form">
    <el-row
      type="flex"
      class="items-center">
      <el-col :span="10">
        {{ $tr('time_tracking.type') }}
      </el-col>
      <el-col
        :span="14"
        class="text-right">
        <el-form-item
          :rules="{ required: true, message: $tr('task.message.pick_ticket_type'), trigger: 'blur' }">
          <el-select
            id="ticketType"
            v-model="taskUpdate.ticket_type_id"
            data-test="ticket-type"
            clearable
            class="w-full">
            <el-option
              v-for="(item, index) in ticketTypes"
              :key="index"
              :value="parseInt(item.id)"
              :label="item.verbose_title[lang]"
              class="option-with-image">
              <img :src="$downloadLink(item.id, 'ticket_type')" />
              <span>{{ item.verbose_title[lang] }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="items-center">
      <el-col :span="10">
        {{ $tr('time_tracking.subject') }}
      </el-col>
      <el-col
        :span="14"
        class="text-right">
        <el-form-item
          :rules="{
            required: true, message: $tr('task.message.input_subject'), trigger: 'blur'}">
          <el-input
            id="subject"
            v-model="taskUpdate.send_email_attributes.subject"
            data-test="ticket-subject"
            name="subject" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="items-center">
      <el-col :span="10">
        {{ $tr('time_tracking.assigned_to') }}
      </el-col>
      <el-col
        :span="14"
        class="text-right">
        <el-form-item
          :rules="{ required: true, message: $tr('task.message.select_dpo'), trigger: 'blur' }">
          <SelectWrapper
            v-model="taskUpdate.dpo_id"
            class="full-width"
            :resource="$apiRoutes.dpo"
            :init-resource-data="dpoOptions"
            label-key="full_name"
            value-key="id"
            remote
            data-test="assigned-to"
            data-test-prefix-for-options="assigned-to-options"
            :custom-params="{
              account_active: true,
              sorted: {
                column: 'first_name',
                direction: 'asc',
              },
            }" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="items-center">
      <el-col :span="10">
        {{ $tr('time_tracking.deadline') }}
      </el-col>
      <el-col
        :span="14"
        class="text-right">
        <el-form-item>
          <el-date-picker
            v-model="taskUpdate.deadline"
            data-test="deadline"
            style="width: 100%"
            format="dd.MM.yyyy HH:mm:ss"
            type="datetime" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="11">
        <div class="flex items-center justify-between">
          <div>{{ $tr('task.audit_call') }}</div>
          <div>
            <el-form-item
              style="margin-top: -2px; padding-right: 10px">
              <el-switch
                v-model="taskUpdate.audit_call"
                data-test="audit_call"
                name="audit_call" />
            </el-form-item>
          </div>
        </div>
        <div>
          <el-form-item
            v-if="taskUpdate.audit_call"
            class="mb10">
            <SelectWrapper
              v-model="taskUpdate.audit_area_id"
              :resource="$apiRoutes.audit_area"
              :label-key="lang == 'en' ? 'title' : 'title_de'"
              value-key="id"
              :custom-params="{ sorted: { column: 'title', direction: 'asc' } }" />
          </el-form-item>
        </div>
        <div v-if="taskUpdate.audit_call && !$isBlank(taskUpdate.audit_area_id)">
          <div>{{ $tr('task.relevant_to') }}</div>
          <el-form-item class="line-height-0 mt5">
            <el-radio
              v-model="taskUpdate.responsible"
              data-test="responsible_dataGuard"
              label="DataGuard" />
            <el-radio
              v-model="taskUpdate.responsible"
              data-test="responsible_customer"
              label="Customer" />
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="13">
        <div class="flex items-center justify-between">
          <div>{{ $tr('task.create_note') }}</div>
          <el-form-item style="margin-top: -2px; padding-right: 10px">
            <el-switch
              v-model="taskUpdate.note.enabled"
              name="create_notes"
              data-test="create_notes"
              @change="confirmDeleteNote" />
          </el-form-item>
          <el-dialog
            :title="$tr('time_tracking.messages.note_will_be_deleted')"
            :append-to-body="true"
            :visible.sync="noteDialogVisible"
            width="422px">
            <span>{{ $tr('time_tracking.messages.note_deletion_confirm') }}</span>
            <span
              slot="footer"
              class="dialog-footer">
              <el-button @click="handleConfirmDeleteNote(false)">{{ $tr('time_tracking.button.cancel') }}</el-button>
              <el-button
                type="primary"
                @click="handleConfirmDeleteNote(true)">{{ $tr('time_tracking.button.confirm') }}</el-button>
            </span>
          </el-dialog>
        </div>
        <div
          v-if="taskUpdate.note.enabled"
          class="flex items-center justify-between">
          <div>{{ $tr('task.audit_relevant') }}</div>
          <el-form-item style="margin-top: -2px; padding-right: 10px">
            <el-switch
              v-model="taskUpdate.note.audit_relevant"
              name="audit_relevant"
              data-test="audit_relevant" />
          </el-form-item>
        </div>
        <div
          v-if="taskUpdate.note.enabled"
          class="flex items-center justify-between">
          <div>{{ $tr('task.annual_report_relevant') }}</div>
          <el-form-item style="margin-top: -2px; padding-right: 10px">
            <el-switch
              v-model="taskUpdate.note.annual_report_relevant"
              data-test="annual_report_relevant" />
          </el-form-item>
        </div>
      </el-col>
    </el-row>
    <el-row v-if="ticket.send_email.email_type === 'task'">
      <div>{{ $tr('time_tracking.task_description') }}</div>
      <el-form-item
        class="mt10"
        :rules="{
          required: true, message: $tr('time_tracking.messages.input_description'), trigger: 'blur'}">
        <el-input
          v-model="taskUpdate.send_email_attributes.body"
          type="textarea"
          :rows="3" />
      </el-form-item>
    </el-row>
    <el-row v-if="ticket.todo_type === 'todo_ticket'">
      <div>Attachments</div>
      <el-form-item class="mt10">
        <FileUploader
          :files="taskUpdate.attachments"
          :upload-button="$tr('time_tracking.messages.add_attachment')"
          :disable-btn="ticket.active_tracking && ticket.last_tracker_id !== currentUser.id"
          :drag-file="true"
          @removed-file="addFileForRemove"
          @added-file="addFileForAssign" />
      </el-form-item>
      <el-tag
        v-for="file in ticket.attachments"
        :key="file.id">
        <a
          :href="$downloadLink(file.id)"
          target="_blank">
          {{ file.name }}
        </a>
      </el-tag>
    </el-row>
  </el-form>
</template>

<script>
import FileUploader from '@/components/FileUploader'
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'
import { useLanguageStore } from '@/stores/languageStore'

export default {
  components: {
    FileUploader,
  },
  props: {
    taskUpdate: {
      type: Object,
      required: true,
      default: function () {
        return {}
      },
    },
    ticket: {
      type: Object,
      default: function () {
        return {}
      },
    },
    ticketTypes: {
      type: Array,
      default () { return [] },
    },
  },
  data () {
    return {
      dpoOptions: [],
      auditAreas: [],
      noteDialogVisible: false,
      loading: false,
    }
  },
  computed: {
    ...mapState(useUserStore, ['currentUser']),
    ...mapState(useLanguageStore, ['lang']),
  },
  created () {
    this.setCurrentDpo()
  },
  methods: {
    confirmDeleteNote (val) {
      if (!val) {
        this.noteDialogVisible = true
      }
    },
    handleConfirmDeleteNote (val) {
      this.taskUpdate.note.enabled = !val
      this.noteDialogVisible = false
    },
    addFileForRemove (id) {
      this.taskUpdate.removed_files.push(id)
    },
    addFileForAssign (file) {
      this.taskUpdate.added_files.push(file.id)
    },
    setCurrentDpo () {
      this.dpoOptions.push(this.taskUpdate.dpo)
    },
  },
}
</script>

<style lang="scss" scoped>
.task-form {
  > .el-row {
    padding: 4px 0;
  }
}
.line-height-0 {
  .el-form-item__content {
    line-height: 0;
  }
  .el-radio {
    color: currentColor;
  }
  .el-radio__label {
    font-size: 12px;
  }
}
</style>
