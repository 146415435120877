<template>
  <el-row>
    <el-col
      class="relative"
      :span="parseInt(formSpan)"
      :offset="offset">
      <div>
        <el-button
          class="button--back pb0"
          type="text"
          icon="el-icon-back"
          @click="backToList">
          {{ $tr("button.back") }}
        </el-button>
        <h2
          class="mt0"
          data-test="page-title">
          {{ $tr(computedTitle) }}
        </h2>
      </div>
      <FormWrapper
        v-slot="{ $validate }"
        :user-can-edit="userCanEdit"
        :submit-function="onSubmit"
        :form-data="record"
        :label-position="labelPosition">
        <slot v-bind="{ $validate, record, initRecord }" />
      </FormWrapper>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      required: false,
      default: '',
    },
    titles: {
      type: Array,
      required: false,
      default: () => [],
    },
    formSpan: {
      type: [String, Number],
      required: false,
      default: '14',
    },
    labelPosition: {
      type: String,
      default: 'left',
    },
    userCanEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data () {
    return {
      loading: false,
      record: {},
    }
  },
  computed: {
    resourcePath () {
      return this.resource || this.$route.params.resourcePath
    },
    parentId () {
      return this.$route.params.parentId
    },
    offset () {
      const length = (24 - parseInt(this.formSpan)) / 2
      return Math.floor(length)
    },
    id () {
      return this.$route.params.id
    },
    computedTitle () {
      const [addTitle, editTitle] = this.titles

      if (this.id) {
        return editTitle ?? 'table.edit'
      }
      return addTitle ?? 'table.add'
    },
  },
  created () {
    if (this.id) this.getResource()
  },
  methods: {
    initRecord (record) {
      this.record = record
    },
    backToList () {
      const routerObject = {
        name: this.$route.name.replace(this.id ? '-edit' : '-create', '-list'),
      }
      if (this.parentId) routerObject.params = { parentId: this.parentId }
      this.$router.push(routerObject)
    },
    onSubmit () {
      if (this.loading) {
        return
      }
      this.loading = true

      const { id, record, $createOne, $updateOne, resourcePath } = this
      const requestData = { data: record }
      let requestMethod = $createOne

      if (id) {
        requestData.id = id
        requestMethod = $updateOne
      }

      return requestMethod(resourcePath, requestData)
        .then(() => {
          this.loading = false
          this.$errorMessage(
            -1,
            this.$tr(`messages.successfully_${id ? 'updated' : 'created'}`),
            'success',
          )
          this.backToList()
        })
        .catch(err => {
          this.loading = false
          this.$errorMessage(err.request.status, err)
        })
    },
    getResource () {
      this.$getOne(this.resourcePath, { id: this.id })
        .then(res => {
          this.record = this.$deserialize(res.data)
        })
        .catch(err => {
          this.$errorMessage(err.request.status, err)
        })
    },
  },
}
</script>
