<template>
  <el-tag
    :color="value">
    {{ value }}
  </el-tag>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '#ffffff',
    },
  },

}
</script>
