<template>
  <el-dialog
    :title="$tr(title)"
    :visible.sync="isActive"
    :show-close="false"
    width="30%"
    center>
    <strong>{{ $tr(text) }}</strong>
    <span
      slot="footer"
      class="dialog-footer button">
      <el-button @click="handleClose">{{ $tr(cancelText) }}</el-button>
      <el-button
        :type="type"
        class="submit"
        data-test="confirm-button"
        @click.native.prevent="handleConfirm">{{ $tr(confirmText) }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'danger',
    },
    title: {
      type: String,
      required: false,
      default: () => 'messages.warning',
    },
    text: {
      type: String,
      required: false,
      default: () => 'messages.sure_to_delete',
    },
    cancelText: {
      type: String,
      required: false,
      default: () => 'button.cancel',
    },
    confirmText: {
      type: String,
      required: false,
      default: () => 'button.delete',
    },
  },
  data () {
    return {
      isActive: !!this.value,
    }
  },
  watch: {
    value (val) {
      this.isActive = val
    },
    isActive (val) {
      if (!val) {
        this.handleClose()
      }
    },
  },
  methods: {
    handleConfirm () {
      this.$emit('confirm-delete')
    },
    handleClose () {
      this.$emit('close')
    },
  },
}
</script>
