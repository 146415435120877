<template>
  <el-row>
    <el-col
      v-if="!$isBlank(question.answer)"
      :span="24">
      <el-form-item
        label-position="top"
        :label="deVersion ? question.title_de : question.title_en">
        <br />
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input
              v-if="question.question_type === 'text_type'"
              v-model="question.answer.value" />
            <el-input
              v-if="question.question_type === 'textarea_type'"
              v-model="question.answer.value"
              type="textarea"
              :rows="3" />
            <el-input-number
              v-if="question.question_type === 'number_type'"
              v-model="question.answer.value" />
            <el-date-picker
              v-if="question.question_type === 'date_type'"
              v-model="question.answer.value"
              type="date" />
            <el-time-picker
              v-if="question.question_type === 'time_type'"
              v-model="question.answer.value" />
            <el-date-picker
              v-if="question.question_type === 'datetime_type'"
              v-model="question.answer.value"
              type="datetime" />
            <el-switch
              v-if="question.question_type === 'switch_type'"
              v-model="question.answer.value" />
            <el-select
              v-if="question.question_type === 'select_type'"
              v-model="question.answer.value">
              <el-option
                v-for="item in question.question_options"
                :key="item.id"
                :label="deVersion ? item.title_de : item.title_en"
                :value="item.id" />
            </el-select>
            <el-select
              v-if="question.question_type === 'multiselect_type'"
              v-model="question.answer.value"
              multiple>
              <el-option
                v-for="item in question.question_options"
                :key="item.id"
                :label="deVersion ? item.title_de : item.title_en"
                :value="parseInt(item.id)" />
            </el-select>
            <el-slider
              v-if="question.question_type === 'slider_type'"
              v-model="question.answer.value"
              :step="10"
              show-stops />
            <el-radio-group
              v-if="question.question_type === 'radio_type'"
              v-model="question.answer.value">
              <el-radio
                v-for="item in question.question_options"
                :key="item.id"
                :label="item.id">
                {{ deVersion ? item.title_de : item.title_en }}
              </el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="12">
            <el-input
              v-if="question.show_optional_box == true"
              v-model="question.answer.optional_value"
              :placeholder="$tr('document_section.answer_optional')"
              type="textarea"
              :rows="3" />
          </el-col>
        </el-row>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default () {
        return {}
      },
    },
    deVersion: {
      type: Boolean,
      default: false,
    },
    answerSheetId: {
      type: [Number, String],
      default: '',
    },
  },
  watch: {
    'question.answer.value' (newValue, oldValue) {
      if (this.$isBlank(newValue) && this.$isBlank(oldValue)) return
      if (newValue === oldValue) return
      this.$emit('change-answer', this.question.answer)
    },
    'question.answer.optional_value' (newValue, oldValue) {
      if (this.$isBlank(newValue) && this.$isBlank(oldValue)) return
      if (newValue === oldValue) return
      this.$emit('change-answer', this.question.answer)
    },
  },
  created () {
    this.checkAnswerOfQuestion()
  },
  methods: {
    checkAnswerOfQuestion () {
      if (!this.$isBlank(this.question.answer)) return
      this.question.answer = {
        id: null,
        answer_sheet_id: this.answerSheetId,
        question_id: this.question.id,
        value: '',
        optional_value: '',
      }
    },
  },
}
</script>
<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.el-radio + .el-radio {
  display: block;
  margin-top: 10px;
}
.el-form-item__content {
  margin-left: 0px !important;
}
.el-radio + .el-radio {
  margin-left: 0;
  margin-top: 10px;
  display: block;
}
</style>
