import Keycloak from 'keycloak-js'
import * as Sentry from '@sentry/vue'
import { useLanguageStore } from '@/stores/languageStore'
import { getUserLocale } from '@/utils/getUserLocale'

const keycloakConfig = JSON.parse(document.getElementsByName('keycloak_config')[0].getAttribute('content'))
const loginWithKeycloak = document.getElementsByName('login_with_keycloak')[0]?.getAttribute('content') === 'true'

const initOptions = {
  url: keycloakConfig.url,
  realm: keycloakConfig.realm,
  clientId: keycloakConfig.clientId,
  pkceMethod: 'S256',
  onLoad: 'login-required',
}

const keycloak = new Keycloak(initOptions)

const login = async () => {
  const auth = await keycloak.init({
    onLoad: initOptions.onLoad,
    pkceMethod: initOptions.pkceMethod,
    enableLogging: true,
    checkLoginIframe: false,
  })
  if (!auth) {
    window.location.reload()
  }

  localStorage.setItem('access-token', keycloak.token)
  localStorage.setItem('refresh-token', keycloak.refreshToken)

  const setLanguage = (locale) => {
    const languageStore = useLanguageStore()
    localStorage.setItem('lang', locale)
    languageStore.setLang(locale)
  }

  const userLocale = getUserLocale(keycloak?.tokenParsed?.locale)
  setLanguage(userLocale)

  return keycloak.token
}

const checkSSO = async () => {
  if (!localStorage.getItem('access-token')) return

  keycloak.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    token: localStorage.getItem('access-token'),
    refreshToken: localStorage.getItem('refresh-token'),
  })
}

const refreshToken = async () => {
  try {
    const refreshed = await keycloak.updateToken(30)

    if (refreshed) {
      localStorage.setItem('access-token', keycloak.token)
    }
    return keycloak.token
  } catch (error) {
    return null
  }
}

const logout = async () => {
  localStorage.clear()

  if (loginWithKeycloak) {
    const logoutOptions = { redirectUri: window.location.origin }
    await keycloak.logout(logoutOptions)
  }

  Sentry.setUser(null);
}

export { login, logout, refreshToken, checkSSO }
