<template>
  <div>
    <div class="flex justify-space-between items-center">
      <h4 class="my0">
        {{ $tr('document_section.preload_answers.ask_title') }}
      </h4>
    </div>
    <p>
      {{ $tr('document_section.preload_answers.ask') }}
    </p>
    <el-divider class="mt7 mb15" />
    <el-row class="ml10">
      <el-col :span="24">
        <el-table
          ref="preloadedDPAsList"
          v-loading="loading"
          :data="documentAnswers"
          style="width: 100%"
          max-height="250"
          class="preload_answers"
          @select-all="deselectRow"
          @select="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55" />
          <el-table-column
            type="index"
            width="50" />
          <el-table-column
            prop="company_name"
            :label="$tr('document_section.company')"
            width="200" />
          <el-table-column
            prop="company.jurisdiction.title"
            :label="$tr('document_section.jurisdiction')"
            width="120" />
          <el-table-column
            prop="customer_role"
            :label="$tr('document_section.customer_role')"
            width="160" />
          <el-table-column
            prop="partner_full_name"
            :label="$tr('document_section.partner_full_name')"
            width="200" />
          <el-table-column
            prop="contracting_partner.title"
            :label="$tr('document_section.contracting_partner')"
            width="200" />
          <el-table-column
            prop="created_at"
            :label="$tr('document_section.date_of_review')"
            width="120">
            <template slot-scope="scope">
              {{ $isEmpty(scope.row.created_at) ? '' : $dateTimeFormat(scope.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="version"
            :label="$tr('document_section.version')"
            width="80" />
          <el-table-column
            prop="legal_reviewer.full_name"
            :label="$tr('document_section.legal_reviewer')"
            width="150" />
          <el-table-column
            prop="it_reviewer.full_name"
            :label="$tr('document_section.it_reviewer')"
            width="150" />
          <el-table-column
            fixed="right"
            prop="operations"
            :label="$tr('table.operations')"
            width="150">
            <template slot-scope="scope">
              <el-tooltip
                class="ppm"
                effect="dark"
                :content="$tr('document_section.load_ticket')"
                placement="right">
                <el-button
                  class="ppm__open"
                  @click="showRelatedDpaTicket(scope.row.location_id)">
                  {{ $tr('document_section.button.ticket') }}
                  <img
                    class="icon"
                    :src="openIcon" />
                </el-button>
              </el-tooltip>
              <br />
              <el-tooltip
                class="ppm"
                effect="dark"
                :content="$tr('document_section.load_details')"
                placement="right">
                <el-button
                  class="ppm__open"
                  @click="getPpmByTicketId(scope.row.location_id, scope.row.company_id)">
                  {{ $tr('document_section.button.details') }}
                  <img
                    class="icon"
                    :src="openIcon" />
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import openIcon from '@/assets/images/svg-icons/open.svg'
import { mapActions } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  props: {
    partnerTitle: {
      type: String,
      default: '',
    },
    documentType: {
      type: String,
      default: 'dpa',
    },
  },
  data () {
    return {
      openIcon,
      loading: false,
      documentAnswers: [],
    }
  },
  watch: {
    partnerTitle () {
      this.findDocumentAnswers()
    },
  },
  mounted () {
    this.findDocumentAnswers()
  },
  methods: {
    ...mapActions(useUserStore, ['setTicketModalState']),
    findDocumentAnswers () {
      const { partnerTitle, documentType } = this

      if (partnerTitle.length < 3) return

      this.loading = true
      let generatedRoute = this.$apiRoutes.dpa_answer
      generatedRoute += `?by_contracting_partner=${partnerTitle}`
      generatedRoute += `&by_document_type=${documentType}`

      this.$axios
        .get(generatedRoute, {
          beforeRequest: {
            hidePreloader: true,
          },
        })
        .then(response => {
          this.documentAnswers = this.$deserialize(response.data)
          this.loading = false
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
          this.loading = false
        })
    },
    showRelatedDpaTicket (TicketAppId) {
      if (!TicketAppId) return
      this.setTicketModalState({ view: true, id: TicketAppId })
    },
    getPpmByTicketId (ticketAppId, companyId) {
      let url = this.$apiRoutes.privacy_contract
      url += '?company_id=' + companyId
      url += '&by_ticket_app_id=' + ticketAppId
      this.$axios.get(url,
        {
          beforeRequest: {
            hidePreloader: true,
          },
        })
        .then(response => {
          const result = this.$deserialize(response.data)
          this.showDetailsPpm(result[0].details_link)
        })
    },
    showDetailsPpm (url) {
      window.open(url, '_blank')
    },
    deselectRow () {
      this.$refs.preloadedDPAsList.clearSelection()
    },
    handleSelectionChange (selectedRows, row) {
      // need to select only one row from the table. there is a single select feature in Element UI and we were using it
      // but there was a problem. our table has a operation column too, to show the related ticket or open the dpa details
      // if we want to see the ticket only by clicking on the button, The corresponding row will also be selected, and we don't want that
      // I couldn't find a way to ignore clicking on operation column in selecting the row process;
      // So hacked the multiSelect feature of ElementUI:
      // by clearing the all selected rows after select a row, and move the selected one into the selected rows array
      this.$refs.preloadedDPAsList.clearSelection()
      this.$emit('selected-dpa', null)

      // if we uncheck the row, it means it does not exist in the selectedRows
      if (!this.$lodash.includes(selectedRows, row)) return

      this.$refs.preloadedDPAsList.toggleRowSelection(row)
      this.$emit('selected-dpa', row.id)
    },
  },
}
</script>
<style lang="scss" scoped>
.preload_answers {
  tr {
    cursor: pointer;
  }
}
</style>
