import { defineStore } from 'pinia'
import { getUserLocale } from '@/utils/getUserLocale'

const lang = getUserLocale(localStorage.getItem('lang'))

export const useLanguageStore = defineStore('languageStore', {
  state: () => ({
    lang,
  }),
  actions: {
    setLang(lang) {
      localStorage.setItem('lang', lang)
      this.lang = lang
    },
  },
})
