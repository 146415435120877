<template>
  <div
    v-loading.fullscreen.lock="isLoading"
    element-loading-text="Please Wait ...">
    <TheHeader />
    <el-main>
      <div class="grid-content bg-purple">
        <router-view />
      </div>
    </el-main>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useIndexedDBStore } from '@/stores/indexedDBStore'
import { useUserStore } from '@/stores/userStore'
import { useGlobalLoadingIndicatorStore } from '@/stores/globalLoadingIndicatorStore'
import { noActivityLogout } from '@/utils/noActivityLogout'
import { checkSSO } from '@/utils/keycloak'
import TheHeader from '@/components/the-header/TheHeader'

export default {
  components: {
    TheHeader,
  },
  computed: {
    ...mapState(useGlobalLoadingIndicatorStore, ['isLoading']),
    ...mapState(useUserStore, ['currentUser']),
  },
  async created() {
    this.performOpenDB()
    noActivityLogout()

    // This is a workaround since we reload the page after succesful login and the Keycloak instance is lost
    // This can be removed once we stop depending on window redirects and move to frontend routing to the correct path
    await checkSSO()

    const data = this.currentUser
    if (data.active_tracking) {
      this.setRunningTask({
        companyName: data.active_tracking_detail.company,
        ticketSubject: data.active_tracking_detail.subject,
        ticketSpentTime: data.active_tracking_detail.ticket_spent_time,
        ticketId: data.active_tracking_detail.ticket_id,
        trackingSpentTime: data.active_tracking_detail.tracking_spent_time,
        timeTrackingId: data.active_tracking_detail.time_tracking_id,
        lastTrackerId: data.active_tracking_detail.last_tracker_id,
        pause: data.active_tracking_detail.pause,
      })
    }
  },
  methods: {
    ...mapActions(useIndexedDBStore, ['performOpenDB',]),
    ...mapActions(useUserStore, ['setRunningTask']),
  },
}
</script>
