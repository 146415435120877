import { defineStore } from 'pinia'

export const useCompanySearchParamsStore = defineStore(
  'companySearchParamsStore',
  {
    state: () => ({ isInactiveIncluded: false }),
    actions: {
      setInactiveIncluded(data) {
        this.isInactiveIncluded = data
      },
    },
  },
)
