<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="3">
        <el-form-item
          :label="$tr('document_section.contact_person')">
          <el-select
            v-model="selectedContactPerson"
            :disabled="$isEmpty(availableEmployees)"
            filterable
            clearable
            allow-create
            class="full-width"
            @change="setContactPersonInfo">
            <el-option
              v-for="item in availableEmployees"
              :key="item.person_id"
              :label="item.full_name"
              :value="item" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="3">
        <el-form-item
          :label="$tr('document_section.contact_person_gender')">
          <el-select
            v-model="form.gender"
            class="full-width">
            <el-option
              v-for="item in genderItems"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="4">
        <el-form-item
          :label="$tr('document_section.contact_person_fname')">
          <el-input
            v-model="form.first_name"
            class="full-width" />
        </el-form-item>
      </el-col>

      <el-col :span="4">
        <el-form-item
          :label="$tr('document_section.contact_person_lname')">
          <el-input
            v-model="form.last_name"
            class="full-width" />
        </el-form-item>
      </el-col>

      <el-col :span="2">
        <el-form-item
          :label="$tr('document_section.contact_person_country_code')">
          <el-input
            v-model="form.country_code"
            class="full-width" />
        </el-form-item>
      </el-col>

      <el-col :span="4">
        <el-form-item
          :label="$tr('document_section.contact_person_tel')">
          <el-input
            v-model="form.phone"
            class="full-width" />
        </el-form-item>
      </el-col>

      <el-col :span="4">
        <el-form-item
          :label="$tr('document_section.contact_person_email')">
          <el-input
            v-model="form.email"
            class="full-width" />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    contact: { type: Object, required: true },
    availableEmployees: {
      type: Array,
      required: false,
      default () { return [] },
    },
  },
  data () {
    return {
      form: {
        id: null,
        person_id: null,
        first_name: '',
        last_name: '',
        country_code: '',
        phone: '',
        gender: '',
        email: '',
        _destroy: false,
      },
      genderItems: [
        { value: 'male', label: this.$tr('document_section.male') },
        { value: 'female', label: this.$tr('document_section.female') },
      ],
      selectedContactPerson: null,
    }
  },
  watch: {
    contact: {
      handler (v) {
        this.form = JSON.parse(JSON.stringify(v))
      },
      immediate: true,
    },
    form: {
      handler: 'emit',
      deep: true,
    },
  },
  methods: {
    emit () {
      if (!this.$lodash.isEqual(this.form, this.contact)) {
        this.$emit('update:contact', JSON.parse(JSON.stringify(this.form)))
      }
    },
    setContactPersonInfo (selectedPerson) {
      if (selectedPerson && selectedPerson.person_id) {
        this.form.person_id = selectedPerson.person_id
        this.form.gender = selectedPerson.gender
        this.form.fname = selectedPerson.first_name
        this.form.lname = selectedPerson.last_name
        this.form.tel = this.parsePhoneNumber(selectedPerson.work_phone, selectedPerson.mobile_number)
        this.form.email = selectedPerson.work_email
      } else {
        this.form.person_id = null
        this.form.gender = null
        this.form.fname = null
        this.form.lname = null
        this.form.tel = null
        this.form.email = null
      }
    },
    parsePhoneNumber (tel, mobile) {
      if (!this.$isEmpty(tel)) return tel
      if (!this.$isEmpty(mobile)) return mobile
    },
  },
}
</script>
