<template>
  <div class="resource-table">
    <el-table
      v-loading="loading"
      :data="filteredTableData"
      :default-sort="defaultSort"
      style="width: 100%; text-align: left"
      data-test="table"
      @sort-change="sortChange">
      <el-table-column
        type="index"
        :index="indexMethod" />
      <el-table-column
        v-for="(header, index) in headers"
        :key="`${header.value}-${index}`"
        :prop="header.value.toString()"
        :width="header.with"
        :sortable="sortableColumn(header.sortable)"
        :label="$tr(header.text)"
        :align="header.align">
        <template slot-scope="scope">
          <component
            :is="header.component"
            v-if="header.component"
            :data-test="header.testID"
            :value="$lodash.get(scope.row, header.value)"
            :constant-value="header.constantValue" />
          <span
            v-else
            :data-test="header.testID">
            {{ parseValue(header, scope.row) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        width="150">
        <template slot-scope="scope">
          <el-button
            data-test="button--resource-table--edit-item"
            type="text"
            size="small"
            @click="editResource(scope.row.id)">
            <font-awesome-icon
              :icon="userCanEdit ? 'edit': 'eye'"
              size="2x" />
          </el-button>
          <el-button
            v-if="isDeleteButtonVisible && userCanEdit"
            type="text"
            size="small"
            data-test="button--resource-table--delete-item"
            @click="showDeleteConfirmation(scope.row.id)">
            <font-awesome-icon
              icon="trash-alt"
              size="2x" />
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <div>
          <div
            v-if="emptyStateVisible"
            data-test="empty-state-item"
            class="py65">
            <img
              :src="emptyStateIllustration"
              class="icon-svg"
              alt="Empty State Illustration" />
            <h3 class="mt20 mb0">
              {{ $tr(emptyStateMessage) }}
            </h3>
            <p class="mt20 mb0">
              <span>{{ $tr('table.messages.adjust_settings') }}</span>
              <span v-if="createResourceFunction && !addButtonDisabled">, {{ $tr('generic_words.or') }}
                <el-link
                  type="primary"
                  @click.stop="createResourceFunction"> {{ $tr('table.add_new_item') }}</el-link>
              </span>
            </p>
          </div>
        </div>
      </template>
    </el-table>
    <el-pagination
      v-if="currentPage && pageSize"
      style="text-align: center; margin-top: 20px;"
      layout="total, sizes, prev, pager, next, jumper"
      background
      :page-size="pageSize"
      :page-sizes="pageSizeOptions"
      :current-page.sync="currentPage"
      :total="recordsSize"
      @size-change="handleSizeChange"
      @current-change="changePage" />
    <ConfirmDialog
      v-model="deleteAlertVisible"
      @close="deleteAlertVisible = false"
      @confirm-delete="deleteResource" />
  </div>
</template>

<script>
import emptyStateIllustration from '@/assets/images/empty-state.svg'
export default {
  props: {
    resource: {
      type: [String, Object],
      required: true,
    },
    resourceParams: {
      type: Object,
      default: () => ({
        page: 1,
        per_page: 25,
      }),
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    renderId: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    createResourceFunction: {
      type: Function,
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    emptyStateMessageTranslationKeys: {
      type: Object,
      required: false,
    },
    query: {
      type: String,
      default: '',
    },
    searchSubject: {
      type: String,
      default: 'title',
    },
    remoteSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    userCanEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    isDeleteButtonVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data () {
    return {
      loading: false,
      deleteAlertVisible: false,
      selectedId: null,
      records: [],
      // Enabling sorting and custom pagination happens by adding related params inside resourceParams prop
      sortColumn: this.resourceParams?.sorted?.column,
      sortDirection: this.resourceParams?.sorted?.direction,
      currentPage: this.resourceParams?.page,
      pageSize: this.resourceParams?.per_page,
      recordsSize: 0,
      searchTimer: null,
      emptyStateVisible: false,
      emptyStateIllustration,
      emptyStateMessage: '',
      defaultSort: {},
    }
  },
  computed: {
    resourceName () {
      return this.$lodash.get(this.resource, 'name', this.resource)
    },
    parentName () {
      return this.$lodash.get(this.resource, 'parentName')
    },
    parentId () {
      return this.$lodash.get(this.resource, 'parentId')
    },
    resourcePath () {
      const { parentName, parentId, resourceName } = this

      if (parentId) return `${parentName}/${parentId}/${resourceName}`
      return resourceName
    },
    resourceParamsComputed () {
      // if resourceParams prop doesn't contain 'sorted', sorting wont work
      const resourceParams = { ...this.resourceParams }
      if (this.sortable === 'custom') {
        resourceParams.sorted = {
          column: this.sortColumn,
          direction: this.sortDirection,
        }
      }

      if (this.remoteSearch && this.query.length >= 3) {
        resourceParams.search = this.query
      }

      if (this.currentPage && this.pageSize) {
        resourceParams.page = this.currentPage
        resourceParams.per_page = this.pageSize
      }

      return resourceParams
    },
    sortable () {
      if (this.emptyStateVisible) return false
      return 'sorted' in this.resourceParams ? 'custom' : true
    },
    addButtonDisabled () {
      if (typeof this.resource === 'string') return false
      return this.resource.parentName && !this.resource.parentId
    },
    filteredTableData () {
      const localSearchSubject = this.searchSubject || this.headers[0].value
      if (this.$isEmpty(this.records)) return
      if (this.remoteSearch) return this.records
      return this.records.filter(item => !this.query || this.$lodash.get(item, localSearchSubject).toLowerCase().includes(this.query.toLowerCase()))
    },
    emptyStateMessageBeforeSearch () {
      return this.emptyStateMessageTranslationKeys?.beforeSearch ?? 'table.no_data'
    },
    emptyStateMessageAfterSearch () {
      return this.emptyStateMessageTranslationKeys?.afterSearch ?? 'table.no_data'
    },
    pageSizeOptions () {
      return Array.from(new Set([+this.pageSize, 25, 50, 75, 100])).sort((i, j) => i - j)
    },
  },
  watch: {
    query (value) {
      if (this.remoteSearch && (value.length >= 3 || value.length === 0)) this.search()
    },
  },
  created () {
    if (!this.parentName || (this.parentName && this.parentId)) {
      this.fetchData()
    } else {
      this.emptyStateMessage = this.emptyStateMessageBeforeSearch
      this.emptyStateVisible = true
    }
    this.defaultSort = {
      prop: this.sortColumn || this.headers[0].value,
      order: this.sortDirection || 'ascending',
    }
  },
  methods: {
    indexMethod (index) {
      return (this.currentPage - 1) * this.pageSize + index + 1
    },
    parseValue (header, row) {
      const prefix = header.prefix ?? ''

      // When we want to have multiple column values in a single cell
      if (Array.isArray(header.value)) {
        return header.value
          .map(item => {
            const value = this.$lodash.get(row, item)
            return value ? `${prefix}${value}` : '-'
          })
          .join(' | ')
      }

      const value = this.$lodash.get(row, header.value)

      // When the fetched value for this column is an array
      if (Array.isArray(value)) {
        return value
          .map(item => {
            if (typeof item !== 'object') {
              return item
            }

            const nestedValue = item[header.nestedValue]
            return nestedValue ? `${prefix}${nestedValue}` : '-'
          })
          .join(' | ')
      }

      return value ? `${prefix}${value}` : '-'
    },
    fetchData () {
      this.loading = true
      this.$getList(this.resourcePath, this.resourceParamsComputed, {
        beforeRequest: {
          hidePreloader: true,
        },
      })
        .then(response => {
          this.records = this.$deserialize(response.data)
          this.recordsSize = response.data.meta?.total_count
          this.emptyStateMessage = this.emptyStateMessageAfterSearch
        })
        .catch(error => {
          this.$errorMessage(error.request.status, error)
        })
        .finally(() => {
          this.loading = false
          this.emptyStateVisible = !this.records.length
        })
    },
    showDeleteConfirmation (id) {
      this.selectedId = id
      this.deleteAlertVisible = true
    },
    deleteResource () {
      if (this.loading) {
        return
      }

      this.loading = true

      this.$deleteOne(this.resourcePath, { id: this.selectedId })
        .then(() => {
          this.loading = false
          this.deleteAlertVisible = false

          this.records = this.records.filter(
            item => item.id !== this.selectedId,
          )

          this.emptyStateVisible = !this.records.length

          this.selectedId = null
          this.$errorMessage(-1, this.$tr('messages.successfully_destroyed'), 'success')
        })
        .catch(err => {
          this.loading = false
          this.deleteAlertVisible = false
          this.selectedId = null

          this.$errorMessage(err.request.status, err)
        })
    },
    editResource (id) {
      this.$router.push({
        name: this.$route.name.replace('-list', '-edit'),
        params: {
          id,
          parentId: this.parentId,
          resourcePath: this.resourcePath,
        },
      })
    },
    changePage (page) {
      this.currentPage = page
      this.fetchData()
    },
    handleSizeChange (size) {
      this.pageSize = size
      this.fetchData()
    },
    sortableColumn (isColumSortable) {
      return isColumSortable === false
        ? false
        : this.sortable
    },
    sortChange ({ prop, order }) {
      if (this.sortable === 'custom') {
        // if resourceParams prop doesn't contain 'sorted', sorting wont work
        this.sortColumn = prop
        this.sortDirection = order || 'ascending'
        this.fetchData()
      }
    },
    search () {
      if (this.searchTimer) clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(function () {
        this.fetchData()
      }.bind(this), 500)
    },
  },
}
</script>
