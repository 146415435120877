<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="5">
        <el-form-item
          prop="partners[0].name"
          :label="$tr('document_section.contracting_partner')">
          <el-select
            v-model="form.name"
            placeholder="Please enter at least 3 Character"
            filterable
            remote
            clearable
            :remote-method="loadCompaniesByName"
            allow-create
            class="full-width"
            @change="setContractingPartner">
            <el-option
              v-for="item in companies"
              :key="item.id"
              :label="item.name"
              :value="item" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="19">
        <PartnerAddress :address.sync="form.address" />
      </el-col>
    </el-row>
    <el-row>
      <Contacts
        :contacts.sync="form.contacts"
        :available-employees="availableEmployees" />
    </el-row>
  </div>
</template>

<script>
import Contacts from './Contacts'
import PartnerAddress from './PartnerAddress'

export default {
  components: {
    Contacts,
    PartnerAddress,
  },
  props: {
    partner: { type: Object, required: true, defaults () { return { contacts: [] } } },
  },
  data () {
    return {
      form: {
        id: null,
        name: '',
        company_id: '',
        address: {
          id: null,
          country: '',
          city: '',
          street: '',
          street_no: '',
          postal_code: '',
        },
        contacts: [],
        _destroy: false,
      },
      searchTimer: null,
      companies: [],
      availableEmployees: [],
    }
  },
  watch: {
    partner: {
      handler (v) {
        this.form = JSON.parse(JSON.stringify(v))
        if (!this.form.contacts) {
          this.form.contacts = []
        }
      },
      immediate: true,
    },
    form: {
      handler: 'emit',
      deep: true,
    },
  },
  methods: {
    emit () {
      if (!this.$lodash.isEqual(this.form, this.partner)) {
        this.$emit('update:partner', JSON.parse(JSON.stringify(this.form)))
      }
    },
    loadCompaniesByName (query) {
      if (query.length < 3) return

      if (this.searchTimer) clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.companies = []

        let generatedRoute = this.$apiRoutes.company
        generatedRoute += '?search[query]=' + encodeURIComponent(query)
        generatedRoute += '&search[column]=name'

        this.$axios
          .get(generatedRoute)
          .then(response => {
            this.companies = this.$deserialize(response.data)
          })
          .catch(e => {
            this.$errorMessage(e.request.status, e)
          })
      }, 500)
    },
    loadCompanyById (id) {
      if (this.$isBlank(id)) return
      if (this.searchTimer) clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        this.$axios
          .get(this.$apiRoutes.company + '/' + id)
          .then(response => {
            const selectedPartner = this.$deserialize(response.data)

            this.form.id = null
            this.form.company_id = selectedPartner.id
            this.form.name = selectedPartner.name
            this.form.address.country = selectedPartner.country
            this.form.address.city = selectedPartner.city
            this.form.address.street = selectedPartner.street
            this.form.address.street_no = selectedPartner.street_number
            this.form.address.postal_code = selectedPartner.postal_code
            this.form._destroy = false

            this.fetchEmployees(id)
          })
          .catch(e => {
            this.$errorMessage(e.request.status, e)
          })
      }, 500)
    },
    fetchEmployees (_companyId) {
      // call employees endpoint and set availableEmployees variable
    },
    setContractingPartner (selectedCompany) {
      if (selectedCompany && selectedCompany.id) {
        this.loadCompanyById(selectedCompany.id)
      } else {
        this.form.id = null
        this.form.name = selectedCompany
        this.form.company_id = null
        this.form.address = {
          id: null,
          country: '',
          city: '',
          street: '',
          street_no: '',
          postal_code: '',
        }
        this.form.contacts = []
        this.form._destroy = false
      }
    },
  },
}
</script>
