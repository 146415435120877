<template>
  <span>
    <i
      v-if="value"
      class="el-icon-check" />
    <i
      v-else
      class="el-icon-close" />
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
