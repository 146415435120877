<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'
import moreIcon from '@/assets/images/svg-icons/more.svg'

export default {
  data() {
    return {
      menuItems: [
        {
          index: '/companies',
          label: 'menu.companies',
          route: '/companies',
          ref: '/companies',
          icon: '',
          roles: [
            'admin',
            'admin_intern',
            'dpo',
            'dpo_intern',
            'accounting',
            'senior_dpo',
            'sale_rep',
            'sale_rep_intern',
            'dpo_sales',
          ],
          attrs: {
            'data-test': 'companies-list',
          },
          permissions: [
            {
              featureValue: 'companies',
              permissionAssociated: 'view',
            },
          ],
        },
        {
          index: '/todos',
          label: 'menu.todos',
          route: '/todos',
          ref: '/todos',
          icon: '',
          roles: ['admin', 'admin_intern', 'dpo', 'senior_dpo'],
          permissions: [
            {
              featureValue: 'todos',
              permissionAssociated: 'view',
            },
          ],
        },
        {
          index: '',
          label: 'menu.users.users',
          route: '',
          ref: '/users',
          icon: '',
          roles: ['admin', 'admin_intern'],
          attrs: {
            'data-test': 'users-menu',
          },
          children: [
            {
              index: '/users/admin',
              label: 'menu.users.admin',
              route: '/users/admin',
              icon: '',
              roles: ['admin', 'admin_intern'],
              permissions: [
                {
                  featureValue: 'admin_users',
                  permissionAssociated: 'view',
                },
              ],
            },
            {
              index: '/users/sale-rep',
              label: 'menu.users.sale_rep',
              route: '/users/sale-rep',
              icon: '',
              roles: ['admin', 'admin_intern'],
              attrs: {
                'data-test': 'sales-representative-menu',
              },
              permissions: [
                {
                  featureValue: 'sale_rep_users',
                  permissionAssociated: 'view',
                },
              ],
            },
            {
              index: '/users/dpo',
              label: 'menu.users.dpo',
              route: '/users/dpo',
              icon: '',
              roles: ['admin', 'admin_intern'],
              attrs: {
                'data-test': 'data-protection-officer-menu',
              },
              permissions: [
                {
                  featureValue: 'dpo_users',
                  permissionAssociated: 'view',
                },
              ],
            },
            {
              index: '/users/api-client',
              label: 'menu.users.api_client',
              route: '/users/api-client',
              icon: '',
              roles: ['admin', 'admin_intern'],
              attrs: {
                'data-test': 'admins-menu',
              },
              permissions: [
                {
                  featureValue: 'api_client_users',
                  permissionAssociated: 'view',
                },
              ],
            },
            {
              index: '/users/customer',
              label: 'menu.users.customer',
              route: '/users/customer',
              icon: '',
              roles: ['admin', 'admin_intern'],
              permissions: [
                {
                  featureValue: 'customer_users',
                  permissionAssociated: 'view',
                },
              ],
            },
          ],
        },
        {
          index: '/settings',
          label: 'menu.settings',
          route: '',
          ref: '/settings',
          icon: '',
          roles: ['admin', 'template_admin', 'admin_intern'],
          attrs: {
            'data-test': 'settings-menu',
          },
          children: [
            {
              index: '/templates',
              label: 'menu.templates',
              route: '',
              icon: '',
              roles: ['admin', 'template_admin', 'admin_intern'],
              attrs: {
                'data-test': 'templates-menu',
              },
              children: [
                {
                  index: '/workflows',
                  label: 'menu.workflows',
                  route: '/workflows',
                  icon: '',
                  roles: ['admin', 'template_admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'workflows_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/template-types',
                  label: 'menu.template_type',
                  route: '/template-types',
                  icon: '',
                  roles: ['admin', 'template_admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'template_type_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/email-templates',
                  label: 'menu.email_templates',
                  route: '/email-templates',
                  icon: '',
                  roles: ['admin', 'template_admin', 'admin_intern'],
                  attrs: {
                    'data-test': 'email-templates-menu',
                  },
                  permissions: [
                    {
                      featureValue: 'email_templates_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/invite-email-templates',
                  label: 'menu.invite_email_templates',
                  route: '/invite-email-templates',
                  icon: '',
                  roles: ['admin', 'template_admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'invite_email_templates_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/questionnaire-pdf-templates',
                  label: 'menu.questionnaire_pdf_templates',
                  route: '/questionnaire-pdf-templates',
                  icon: '',
                  roles: ['admin', 'template_admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'questionnaire_pdf_templates_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/pdf-templates',
                  label: 'menu.pdf_templates',
                  route: '/pdf-templates',
                  icon: '',
                  roles: ['admin', 'template_admin', 'admin_intern'],
                  attrs: {
                    'data-test': 'pdf-templates-menu',
                  },
                  permissions: [
                    {
                      featureValue: 'pdf_templates_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/task-templates',
                  label: 'menu.task_templates',
                  route: '/task-templates',
                  icon: '',
                  roles: ['admin', 'template_admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'task_templates_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/filter-templates',
                  label: 'menu.filter_templates',
                  route: '/filter-templates',
                  icon: '',
                  roles: ['admin', 'template_admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'filter_templates_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/custom-variables',
                  label: 'menu.custom_variables',
                  route: '/custom-variables',
                  icon: '',
                  roles: ['admin', 'template_admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'custom_variables_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
              ],
            },
            {
              index: '/service_package_options',
              label: 'menu.service_package_options',
              route: '',
              icon: '',
              roles: ['admin', 'admin_intern'],
              attrs: {
                'data-test': 'service-package-options-menu',
              },
              children: [
                {
                  index: '/topics',
                  label: 'menu.topics',
                  route: '/topics',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'topics_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/service-package-categories',
                  label: 'menu.service_package_categories',
                  route: '/service-package-categories',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'service_package_categories_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/service-packages',
                  label: 'menu.service_packages',
                  route: '/service-packages',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  attrs: {
                    'data-test': 'service-packages-menu',
                  },
                  permissions: [
                    {
                      featureValue: 'service_package_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/teams-setting',
                  label: 'menu.teams',
                  route: '/teams-setting',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'teams_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/offered-services',
                  label: 'menu.offered_services',
                  route: '/offered-services',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'offered_services_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
              ],
            },
            {
              index: '/permissions',
              label: 'menu.permissions',
              route: '',
              icon: '',
              roles: ['admin', 'admin_intern'],
              attrs: {
                'data-test': 'permissions-menu',
              },
              children: [
                {
                  index: '/pages',
                  label: 'menu.page',
                  route: '/pages',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'permission_pages_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/features',
                  label: 'menu.feature',
                  route: '/features',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'permission_features_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/internal-roles',
                  label: 'menu.internal_roles',
                  route: '/internal-roles',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'internal_user_roles',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/external-roles',
                  label: 'menu.external_roles',
                  route: '/external-roles',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  attrs: {
                    'data-test': 'external-roles-menu',
                  },
                  permissions: [
                    {
                      featureValue: 'external_user_roles',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/account-roles',
                  label: 'menu.account_roles',
                  route: '/account-roles',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  attrs: {
                    'data-test': 'account-roles-menu',
                  },
                  permissions: [
                    {
                      featureValue: 'account_roles_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/external-groups',
                  label: 'menu.external_group',
                  route: '/external-groups',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  attrs: {
                    'data-test': 'external-groups-menu',
                  },
                  permissions: [
                    {
                      featureValue: 'external_groups_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
              ],
            },
            {
              index: '/industry_options',
              label: 'menu.industry_options',
              route: '',
              icon: '',
              roles: ['admin', 'admin_intern'],
              children: [
                {
                  index: '/dataguard-industries',
                  label: 'menu.dataguard_industry',
                  route: '/dataguard-industries',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'dataguard_industries_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/mapping-industry-users',
                  label: 'menu.mapping_industry_users',
                  route: '/mapping-industry-users',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'mapping_industries_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/jurisdictions',
                  label: 'menu.jurisdiction',
                  route: '/jurisdictions',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'jurisdictions_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
              ],
            },
            {
              index: '/contract_options',
              label: 'menu.contract_options',
              route: '',
              icon: '',
              roles: ['admin', 'admin_intern'],
              children: [
                {
                  index: '/lost-reasons',
                  label: 'menu.lost_reasons',
                  route: '/lost-reasons',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'lost_reasons_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/change-reasons',
                  label: 'menu.change_reasons',
                  route: '/change-reasons',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                },
                {
                  index: '/off-boarding-types',
                  label: 'menu.offboarding_types',
                  route: '/off-boarding-types',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                },
                {
                  index: '/tags-settings',
                  label: 'menu.tags',
                  route: '/tags-settings',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'tags_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/desired-certifications',
                  label: 'menu.desired_certifications',
                  route: '/desired-certifications',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'desired_certification',
                      permissionAssociated: 'view',
                    },
                  ],
                },
              ],
            },
            {
              index: '/audit_options',
              label: 'menu.audit_options',
              route: '',
              icon: '',
              roles: ['admin', 'admin_intern'],
              children: [
                {
                  index: '/grouping-audit-areas',
                  label: 'menu.grouping_audit_areas',
                  route: '/grouping-audit-areas',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'grouped_audit_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/audit-areas',
                  label: 'menu.auditareas',
                  route: '/audit-areas',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'audit_areas_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
              ],
            },
            {
              index: '/document_sections',
              label: 'menu.document_sections',
              route: '',
              icon: '',
              roles: ['admin', 'admin_intern'],
              children: [
                {
                  index: '/dpa-section',
                  label: 'menu.dpa_section',
                  route: '/dpa-section',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'dpa_sections_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/questionnaires',
                  label: 'menu.questionnaires',
                  route: '/questionnaires',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'questionnaires_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
              ],
            },
            {
              index: '/company_options',
              label: 'menu.company_options',
              route: '',
              icon: '',
              roles: ['admin', 'admin_intern'],
              children: [
                {
                  index: '/legal-forms',
                  label: 'menu.legal_forms',
                  route: '/legal-forms',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'legal_forms_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/employee-organizations',
                  label: 'menu.employee_organizations',
                  route: '/employee-organizations',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'employee_organizations_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/escalation-options',
                  label: 'menu.escalation_options',
                  route: '/escalation-options',
                  icon: '',
                  roles: ['admin'],
                  permissions: [
                    {
                      featureValue: 'escalation_options_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/churn-probabilities',
                  label: 'menu.churn_probabilities',
                  route: '/churn-probabilities',
                  icon: '',
                  roles: ['admin'],
                  permissions: [
                    {
                      featureValue: 'churn_probabilities_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/escalation-statuses',
                  label: 'menu.escalation_statuses',
                  route: '/escalation-statuses',
                  icon: '',
                  roles: ['admin'],
                  permissions: [
                    {
                      featureValue: 'escalation_statuses_setting',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/industrial-sectors',
                  label: 'menu.industrial_sectors',
                  route: '/industrial-sectors',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'industrial_sectors_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/managing-directors',
                  label: 'menu.managing_director',
                  route: '/managing-directors',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'managing_directors_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/basic-information',
                  label: 'menu.basic_informations',
                  route: '/basic-information',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'basic_information_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/stages',
                  label: 'menu.stages',
                  route: '/stages',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'stages_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
                {
                  index: '/ticket-types',
                  label: 'menu.ticket_types',
                  route: '/ticket-types',
                  icon: '',
                  roles: ['admin', 'admin_intern'],
                  permissions: [
                    {
                      featureValue: 'ticket_types_settings',
                      permissionAssociated: 'view',
                    },
                  ],
                },
              ],
            },
            {
              index: '/dg-translations',
              label: 'menu.dg_translations',
              route: '/dg-translations',
              icon: '',
              roles: ['admin', 'admin_intern'],
              permissions: [
                {
                  featureValue: 'extra_translations',
                  permissionAssociated: 'view',
                },
              ],
            },
            {
              index: '/feature-flags',
              label: 'menu.feature_flags',
              route: '/feature-flags',
              icon: '',
              roles: ['admin', 'admin_intern'],
              permissions: [
                {
                  featureValue: 'feature_flags',
                  permissionAssociated: 'view',
                },
              ],
            },
          ],
        },
        {
          index: '/statistics',
          label: 'menu.reports',
          route: '/statistics',
          ref: '/statistics',
          icon: '',
          roles: ['admin', 'admin_intern', 'senior_dpo'],
          permissions: [
            {
              featureValue: 'reports_time_tracking',
              permissionAssociated: 'view',
            },
          ],
        },
        {
          index: '/extra',
          label: 'menu.extra',
          route: '',
          ref: '/extra',
          icon: '',
          roles: ['admin', 'admin_intern', 'senior_dpo'],
          children: [
            {
              index: '/duplicated-people',
              label: 'menu.duplicated_people',
              route: '/duplicated-people',
              icon: '',
              roles: ['admin', 'admin_intern', 'senior_dpo'],
              permissions: [
                {
                  featureValue: 'extra_duplicated_people',
                  permissionAssociated: 'view',
                },
              ],
            },
            {
              index: '/dg-languages',
              label: 'menu.dg_languages',
              route: '/dg-languages',
              icon: '',
              roles: ['admin', 'admin_intern'],
              permissions: [
                {
                  featureValue: 'extra_language',
                  permissionAssociated: 'view',
                },
              ],
            },
            {
              index: '/tracking-apis',
              label: 'menu.tracking_api',
              route: '/tracking-apis',
              icon: '',
              roles: ['admin'],
              permissions: [
                {
                  featureValue: 'extra_tracking_apis',
                  permissionAssociated: 'view',
                },
              ],
            },
            {
              index: '/communications',
              label: 'menu.communication',
              route: '/communications',
              icon: '',
              roles: ['admin'],
              permissions: [
                {
                  featureValue: 'extra_communication',
                  permissionAssociated: 'view',
                },
              ],
            },
            {
              index: '/journeys',
              label: 'menu.journeys',
              route: '/journeys',
              icon: '',
              roles: ['senior_dpo', 'admin'],
            },
            {
              index: '/live_demo_company',
              label: 'menu.live_demo_company',
              route: '/companies/new',
              icon: '',
              roles: ['sale_rep', 'admin'],
              permissions: [
                {
                  featureValue: 'live_demo_company',
                  permissionAssociated: 'view',
                },
              ],
            },
            {
              index: '/salesforce',
              label: 'menu.salesforce',
              route: '/salesforce',
              icon: '',
              roles: ['admin'],
            },
          ],
        },
        {
          index: 'more',
          ref: 'more',
          class: ['more-menu'],
          roles: [
            'admin',
            'admin_intern',
            'dpo',
            'dpo_intern',
            'accounting',
            'senior_dpo',
            'sale_rep',
            'sale_rep_intern',
            'dpo_sales',
          ],
          attrs: {
            'data-test': 'more-menu',
          },
          children: [], // will be filled dynamically
        },
      ],
      maxMenuWidth: null,
      moreButtonWidthPlusMenuPaddingLeft: null,
      moreIcon,
    }
  },
  computed: {
    ...mapState(useUserStore, ['currentUser']),
  },
  mounted() {
    window.addEventListener('load', () => {
      this.calculateWidths()
      this.moreButtonWidthPlusMenuPaddingLeft = this.getMoreButtonWidthPlusMenuPaddingLeft()
      this.resizeObserver = new ResizeObserver(this.adjustMenuItemsPlacement)
      this.resizeObserver.observe(this.$refs.menu.$el)
    })
  },
  beforeUnmount() {
    this.resizeObserver.disconnect()
  },
  methods: {
    adjustMenuItemsPlacement() {
      const availableMenuWidth = this.$refs.menu.$el.offsetWidth - this.moreButtonWidthPlusMenuPaddingLeft
      const moreMenuItem = this.findMoreMenuItem()
      const primaryMenuItems = this.filterPrimaryMenuItems()

      this.moveAllItemsToPrimaryMenu(moreMenuItem, primaryMenuItems)
      this.moveItemsToMoreMenuIfNeeded(
        availableMenuWidth,
        moreMenuItem,
        primaryMenuItems,
      )

      this.updateMenuItems(primaryMenuItems, moreMenuItem)
    },

    calculateWidths() {
      this.menuItems = this.menuItems.map((item) => {
        const width = this.$refs[item.ref]?.$el.offsetWidth ?? 0
        this.maxMenuWidth += width

        return {
          ...item,
          width,
        }
      })
    },

    getMoreButtonWidthPlusMenuPaddingLeft() {
      const moreMenuItemWidth = 40
      const menuPaddingLeft = parseFloat(
        window.getComputedStyle(this.$refs.menu.$el).paddingLeft,
        10,
      )

      return moreMenuItemWidth + menuPaddingLeft
    },

    findMoreMenuItem() {
      return this.menuItems.find((item) => item.index === 'more')
    },

    filterPrimaryMenuItems() {
      return this.menuItems.filter((item) => item.index !== 'more')
    },

    moveAllItemsToPrimaryMenu(moreMenuItem, primaryMenuItems) {
      while (moreMenuItem.children.length > 0) {
        primaryMenuItems.push(moreMenuItem.children.shift())
      }
    },

    moveItemsToMoreMenuIfNeeded(
      availableMenuWidth,
      moreMenuItem,
      primaryMenuItems,
    ) {
      let primaryMenuItemsWidth = this.maxMenuWidth

      while (
        primaryMenuItemsWidth > availableMenuWidth &&
        primaryMenuItems.length > 1
      ) {
        const lastItem = primaryMenuItems.pop()
        primaryMenuItemsWidth -= lastItem.width
        moreMenuItem.children.unshift(lastItem)
      }
    },

    updateMenuItems(primaryMenuItems, moreMenuItem) {
      this.menuItems = [...primaryMenuItems, moreMenuItem]
    },

    applyPermissions(menu) {
      if (menu.allowedEnvironments) {
        return this.isLinkVisibleInCurrentEnvironment(menu)
      }

      if (this.$featureFlagIsActive('permissions')) {
        return this.userHasPermissionForMenuItem(menu)
      } else {
        return (
          menu.roles.indexOf(this.currentUser.role) !== -1 ||
          menu.roles.some(
            (item) => this.currentUser.extra_roles.indexOf(item) !== -1,
          )
        )
      }
    },
    linkIsVisible({ permissions }) {
      return permissions.some(({ featureValue, permissionAssociated }) => {
        return this.$userCan(`${permissionAssociated}:${featureValue}`)
      })
    },
    isLinkVisibleInCurrentEnvironment({ allowedEnvironments }) {
      return allowedEnvironments.includes(import.meta.env.MODE)
    },
    userHasPermissionForMenuItem({ permissions, children }) {
      if (children?.every(({ permissions }) => permissions)) {
        return children.some((menu) => this.userHasPermissionForMenuItem(menu))
      }

      if (!permissions) return true

      if (this.linkIsVisible({ permissions })) return true

      return children?.some((menu) => this.userHasPermissionForMenuItem(menu))
    },
    renderMoreMenuItemTitle(createElement) {
      return createElement('img', { attrs: { src: this.moreIcon } })
    },
    recursiveMenuPrep(createElement, menus) {
      const elements = []
      if (menus && menus.length) {
        menus.forEach((menu) => {
          let item = null
          if (
            menu.children &&
            menu.children.length &&
            this.applyPermissions(menu) &&
            menu.children.some((child) => this.applyPermissions(child))
          ) {
            item = createElement(
              'el-submenu',
              {
                ref: menu.ref,
                class: menu.class,
                props: {
                  'popper-class': 'header-customized-menu',
                  'show-timeout': 50,
                  'hide-timeout': 50,
                  index: menu.index,
                },
                attrs: menu.attrs,
              },
              [
                createElement('template', { slot: 'title' }, [
                  menu.index === 'more'
                    ? this.renderMoreMenuItemTitle(createElement)
                    : createElement('i', { class: [menu.icon] }),
                  this.$tr(menu.label),
                ]),
                this.recursiveMenuPrep(createElement, menu.children),
              ],
            )
            elements.push(item)
          } else {
            if (
              !this.applyPermissions(menu) ||
              menu.children?.every((child) => !this.applyPermissions(child))
            ) {
              return
            }
            item = createElement(
              'el-menu-item',
              {
                ref: menu.ref,
                class: menu.class,
                props: {
                  index: menu.index,
                },
              },
              [
                createElement('i', { class: [menu.icon] }),
                createElement(
                  'router-link',
                  {
                    props: {
                      to: menu.route,
                    },
                    attrs: menu.attrs,
                  },
                  this.$tr(menu.label),
                ),
              ],
            )
            elements.push(item)
          }
        }, this)
      }
      return elements
    },
  },
  render(createElement) {
    return createElement(
      'el-menu',
      {
        class: ['header-menu'],
        props: {
          mode: 'horizontal',
          'default-active': this.$route.meta.index ?? this.$route.path,
        },
        ref: 'menu',
      },
      this.recursiveMenuPrep(createElement, this.menuItems),
    )
  },
}
</script>
