import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(duration);
dayjs.extend(relativeTime);


function dateTimeFormat(value, format = 'DD.MM.YYYY') {
  if (!value) return '--'
  return dayjs(value).format(format)
}

function timeDurationFormat(
  seconds,
  { timeFormat } = {},
) {
  if (typeof seconds === 'undefined' || isNaN(Number(seconds))) {
    return '--'
  }

  const absoluteSeconds = Math.abs(seconds)
  const milliSeconds = absoluteSeconds * 1000
  const duration = dayjs.duration(milliSeconds)

  if (timeFormat === 'HH:mm') {
    const minutes = duration.as('minutes')
    const hours = Math.floor(minutes/ 60)

    return hours + ':' + duration.format('mm');
  }

  else {

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

  }

}

export { dayjs, dateTimeFormat, timeDurationFormat }
