<template>
  <el-form
    ref="taskForm"
    :model="taskForm">
    <el-card class="new-task-card">
      <div slot="header">
        <div>
          <h4 class="my0">
            New Task
          </h4>
        </div>
        <div class="actions">
          <el-dropdown
            :style="
              $isBlank(taskForm.send_email_attributes.company_id)
                ? 'opacity: .4; pointer-events: none'
                : ''"
            trigger="click">
            <span
              class="color--primary"
              data-test="show-task-templates">
              {{ $tr("task.task_template")
              }}<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu
              slot="dropdown"
              class="p10">
              <el-select
                v-model="selectedTemplateId"
                data-test="task_template"
                filterable
                remote
                reserve-keyword
                :placeholder="$tr('task.message.enter_keyword')"
                :remote-method="fetchTaskTemplates"
                style="width: 100%"
                :disabled="$isBlank(taskForm.send_email_attributes.company_id)">
                <el-option
                  v-for="(item, index) in taskTemplates"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                  data-test="task_template_options" />
              </el-select>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <el-row
        :gutter="20"
        class="pt15">
        <el-col :span="componentLocation === 'header' ? 24 : 12">
          <el-form-item
            v-if="componentLocation === 'header'"
            :label="$tr('task.customer')"
            prop="send_email_attributes.company_id"
            :rules="{ required: true, message: $tr('task.message.select_company'), trigger: 'blur' }">
            <el-select
              v-model="taskForm.send_email_attributes.company_id"
              data-test="customer_name"
              filterable
              remote
              reserve-keyword
              :placeholder="$tr('task.message.enter_keyword')"
              :remote-method="fetchCompaniesByName"
              style="width: 100%">
              <el-option
                v-for="item in companies"
                :key="item.id"
                data-test="customer_options"
                :label="item.name"
                :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="componentLocation === 'company-detail'"
            :label="$tr('task.customer')"
            prop="send_email_attributes.company_id">
            <el-select
              v-model="taskForm.send_email_attributes.company_id"
              data-test="customer_name"
              :disabled="true"
              style="width: 100%">
              <el-option
                v-for="item in companies"
                :key="item.id"
                :label="item.name"
                :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$tr('task.type')"
            prop="ticket_type_id"
            :rules="{ required: true, message: $tr('task.message.pick_ticket_type'), trigger: 'blur' }">
            <el-select
              v-model="taskForm.ticket_type_id"
              data-test="ticket_type_id"
              style="width: 100%"
              :placeholder="$tr('messages.placeholder.select')"
              filterable>
              <el-option-group>
                <el-option
                  v-for="ticketType in ticketTypes"
                  :key="ticketType.id"
                  data-test="ticket_type_options"
                  :label="ticketType.verbose_title[lang]"
                  :value="parseInt(ticketType.id)"
                  class="option-with-image">
                  <img :src="$downloadLink(ticketType.id, 'ticket_type')" />
                  <span> {{ ticketType.verbose_title[lang] }} </span>
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$tr('task.subject')"
            prop="send_email_attributes.subject"
            :rules="{ required: true, message: $tr('task.message.input_subject'), trigger: 'blur' }">
            <el-input
              v-model="taskForm.send_email_attributes.subject"
              data-test="subject" />
          </el-form-item>
        </el-col>
        <el-col :span="componentLocation === 'header' ? 24 : 12">
          <el-form-item
            :class="componentLocation === 'header' ? 'mt10' : ''"
            :label="$tr('task.assigned_to')"
            prop="dpo_id"
            :rules="{ required: true, message: $tr('task.message.select_dpo'), trigger: 'blur' }">
            <el-select
              v-model="taskForm.dpo_id"
              data-test="dpo_id"
              style="width: 100%"
              :placeholder="$tr('task.message.enter_keyword')"
              filterable
              reserve-keyword
              remote
              :remote-method="fetDPOsByName">
              <el-option
                v-for="item in dpos"
                :key="item.id"
                data-test="dpo_id_options"
                :label="item.full_name"
                :value="parseInt(item.id)" />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$tr('task.deadline')"
            prop="deadline">
            <el-date-picker
              v-model="taskForm.deadline"
              data-test="deadline"
              style="width: 100%"
              format="dd.MM.yyyy HH:mm:ss"
              type="datetime"
              prefix-icon="el-icon-date" />
          </el-form-item>
          <el-form-item
            :label="$tr('task.description')"
            prop="send_email_attributes.body"
            :rules="{ required: true, message: $tr('task.message.input_description'), trigger: 'blur' }">
            <el-input
              v-model="taskForm.send_email_attributes.body"
              data-test="description"
              type="textarea"
              class="disable-resize"
              :autosize="{ minRows: 2 }"
              :placeholder="$tr('task.description')" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :span="componentLocation === 'header' ? 24 : 12"
          :style="componentLocation === 'header' ? 'margin-top: 10px;' : 'min-height: 120px'">
          <el-row :gutter="60">
            <el-col :span="12">
              <el-tooltip
                :content="$tr('task.tooltip.audit_call')"
                placement="top">
                <el-form-item
                  class="flex items-center justify-between pr10">
                  <div slot="label">
                    {{ $tr('task.audit_call') }}
                  </div>
                  <el-switch
                    v-model="taskForm.audit_call"
                    name="audit_call"
                    data-test="audit_call" />
                </el-form-item>
              </el-tooltip>
              <!-- select Audit Area for AuditCall tickets -->
              <el-form-item
                v-if="taskForm.audit_call"
                class="mt0"
                prop="audit_area_id"
                :rules="{ required: true, message: $tr('task.message.pick_audit_area_id'), trigger: 'blur' }">
                <el-select
                  v-model="taskForm.audit_area_id"
                  data-test="audit_area"
                  class="w-full"
                  :placeholder="$tr('messages.placeholder.select')"
                  filterable
                  :disabled="!taskForm.audit_call">
                  <el-option-group>
                    <el-option
                      v-for="auditArea in auditAreas"
                      :key="auditArea.id"
                      data-test="audit_area_options"
                      :label="auditArea.title"
                      :value="parseInt(auditArea.id)" />
                  </el-option-group>
                </el-select>
                <div
                  v-if="!$isBlank(taskForm.audit_area_id)"
                  class="mt5">
                  <div style="font-size:12px; line-height: 30px">
                    {{ $tr('task.relevant_to') }}
                  </div>
                  <el-form-item
                    class="line-height-0">
                    <el-radio
                      v-model="taskForm.responsible"
                      data-test="responsible_dataGuard"
                      label="DataGuard" />
                    <el-radio
                      v-model="taskForm.responsible"
                      data-test="responsible_customer"
                      label="Customer" />
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-tooltip
                :content="$tr('task.tooltip.create_note')"
                placement="top">
                <el-form-item
                  class="flex items-center justify-between pr10">
                  <div slot="label">
                    {{ $tr('task.create_note') }}
                  </div>
                  <el-switch
                    v-model="taskForm.note.enabled"
                    data-test="create_note"
                    name="create_note" />
                </el-form-item>
              </el-tooltip>
              <el-form-item
                v-if="taskForm.note.enabled"
                class="flex items-center justify-between mt0 pr10">
                <div slot="label">
                  {{ $tr('task.audit_relevant') }}
                </div>
                <el-switch
                  v-model="taskForm.note.audit_relevant"
                  name="audit_relevant"
                  data-test="audit_relevant" />
              </el-form-item>
              <el-form-item
                v-if="taskForm.note.enabled"
                class="flex items-center justify-between mt0 pr10">
                <div slot="label">
                  {{ $tr('task.annual_report_relevant') }}
                </div>
                <el-switch
                  v-model="taskForm.note.annual_report_relevant"
                  data-test="annual_report_relevant"
                  name="annual_report_relevant" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="componentLocation === 'header' ? 24 : 12">
          <el-form-item :label="$tr('task.attachments')">
            <div>
              <FileUploader
                :reset="uploadFlag"
                :drag-file="true"
                :upload-button="$tr('task.message.attachments')"
                data-test="upload-input--new-task-form"
                @added-file="addAttachment" />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="text-mute pt60 pb20">
        * {{ $tr('time_tracking.messages.mandatory_information') }}
      </div>
      <div
        style="border-top: 1px solid #E6E6E6;"
        class="text-right pt20">
        <el-button
          class="ml10"
          @click="clear()">
          {{ $tr('task.clear') }}
        </el-button>
        <el-button
          data-test="button--new-task--create"
          type="primary"
          @click="createTask(false)">
          {{ $tr('task.create') }}
        </el-button>
        <el-button
          v-if="$userCan('edit:time_trackings')"
          type="success"
          @click="createTask(true)">
          {{ $tr('task.create_start') }}
        </el-button>
      </div>
    </el-card>
  </el-form>
</template>

<script>
import FileUploader from '@/components/FileUploader'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'
import { useLanguageStore } from '@/stores/languageStore'

export default {
  components: {
    FileUploader,
  },
  props: {
    company: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      taskForm: {
        ticket_type_id: '',
        dpo_id: '',
        status: 'open_ticket',
        todo_type: 'todo_task',
        deadline: '',
        attachments_ids: [],
        send_email_attributes: {
          email_type: 'task',
          status: 'pending',
          subject: '',
          body: '',
          company_id: '',
        },
        is_active: false, // false: task just will create, false: create & start
        note: {
          enabled: false,
          audit_relevant: false,
          annual_report_relevant: false,
        },
        audit_call: false,
        audit_area_id: '',
        responsible: '',
      },
      responsibleDpo: '',
      spentTimeTracking: 0,
      selectedTemplateId: '',
      query: '',
      searchTimer: null,
      uploadFlag: false,

      // Select Boxes Data
      selectedTemplate: [],
      dpos: [],
      taskTemplates: [],
      ticketTypes: [],
      companies: [],
      auditAreas: [],

      componentLocation: '',
    }
  },
  computed: {
    ...mapState(useUserStore, ['currentUser']),
    ...mapState(useLanguageStore, ['lang']),
    creation_date () {
      return this.$dateTimeFormat(undefined, 'DD.MM.YYYY HH:mm')
    },
    taskFormRef () {
      return this.$refs.taskForm
    },
  },
  watch: {
    selectedTemplateId (newId) {
      if (!this.$isBlank(newId)) {
        this.fetchGeneratedTemplate(newId)
      }
    },
  },
  created () {
    this.resetForm()
    this.fetchTicketTypes()
    this.fetchTaskTemplates()
    this.fetchAuditAreas()
    if (this.$isEmpty(this.company)) {
      this.componentLocation = 'header'
    } else {
      this.componentLocation = 'company-detail'
      this.taskForm.send_email_attributes.company_id = this.company.id
      this.companies.push(this.company)
    }

    this.dpos.push({
      id: this.currentUser.profile_id,
      full_name: this.currentUser.full_name,
    })
    this.taskForm.dpo_id = this.currentUser.profile_id
  },
  methods: {
    ...mapActions(useUserStore, ['resetTodoList', 'resetActivitiesList', 'setRunningTask']),
    fetchGeneratedTemplate (templateId) {
      this.resetForm('task-template-selected')
      const taskTemplateRoute = this.$apiRoutes.task_template + '/' + templateId

      this.$axios.get(taskTemplateRoute)
        .then(response => {
          this.selectedTemplate = this.$deserialize(response.data)

          // Check if Ticket Type is already selected or not
          if (!this.$isBlank(this.selectedTemplate.ticket_type_id)) {
            this.taskForm.ticket_type_id = this.selectedTemplate.ticket_type_id
            this.ticketTypes = [this.selectedTemplate.ticket_type]
          }
          if (this.$isBlank(this.selectedTemplate.subject) && this.$isBlank(this.selectedTemplate.desc)) {
            return false
          }

          // Check if Ticket Type is already selected or not
          if (!this.$isBlank(this.selectedTemplate.audit_area_id)) {
            this.taskForm.audit_area_id = this.selectedTemplate.audit_area_id
            this.auditAreas = [this.selectedTemplate.audit_area]
            this.taskForm.responsible = this.selectedTemplate.responsible
          }

          this.taskForm.note.enabled = this.selectedTemplate.note
          this.taskForm.note.audit_relevant = this.selectedTemplate.audit_relevant
          this.taskForm.audit_call = this.selectedTemplate.audit_call
          this.taskForm.note.annual_report_relevant = this.selectedTemplate.annual_report_relevant
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })

      let generatedRoute = this.$apiRoutes.parse + '/' + templateId
      generatedRoute += '?language=german'
      generatedRoute += '&company_id=' + this.taskForm.send_email_attributes.company_id
      generatedRoute += '&template_type=task'
      this.$axios.get(generatedRoute)
        .then(response => {
          const data = response.data.data
          this.taskForm.send_email_attributes.subject = data.subject
          this.taskForm.send_email_attributes.body = data.desc

          if (!this.$isBlank(data.assignee.id)) {
            this.dpos = [data.assignee]
            this.taskForm.dpo_id = parseInt(data.assignee.id)
          } else {
            this.taskForm.dpo_id = null
          }
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    fetchTicketTypes () {
      this.$axios
        .get(this.$apiRoutes.ticket_type + '?per_page=all')
        .then(response => {
          this.ticketTypes = this.$deserialize(response.data)
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    fetDPOsByName (query) {
      if (this.searchTimer) clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.dpos = []

        let generatedRoute = this.$apiRoutes.dpo
        generatedRoute += '?search=' + encodeURIComponent(query)
        generatedRoute += '&account_active=true'
        generatedRoute += '&sorted[column]=first_name'
        generatedRoute += '&sorted[direction]=asc'
        generatedRoute += '&page=1&per_page=10'

        this.$axios.get(generatedRoute)
          .then(response => {
            this.dpos = this.$deserialize(response.data)
          })
          .catch(e => {
            this.$errorMessage(e.request.status, e)
          })
      }, 500)
    },
    fetchTaskTemplates (query) {
      if (this.searchTimer) clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.taskTemplates = []

        let generatedRoute = this.$apiRoutes.task_template
        generatedRoute += '?search=' + query
        generatedRoute += '&sorted[column]=title'
        generatedRoute += '&sorted[direction]=asc'

        this.$axios.get(generatedRoute)
          .then(response => {
            this.taskTemplates = this.$deserialize(response.data)
          })
          .catch(e => {
            this.$errorMessage(e.request.status, e)
          })
      }, 500)
    },
    fetchCompaniesByName (query) {
      if (query.length < 3) return
      if (this.searchTimer) clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.companies = []

        let generatedRoute = this.$apiRoutes.company
        generatedRoute += '?search[query]=' + encodeURIComponent(query)
        generatedRoute += '&search[column]=name'
        generatedRoute += '&page=1&per_page=25'

        this.$axios
          .get(generatedRoute)
          .then(response => {
            this.companies = this.$deserialize(response.data)
          })
          .catch(e => {
            this.$errorMessage(e.request.status, e)
          })
      }, 500)
    },
    clear () {
      if (this.componentLocation === 'header') {
        this.resetForm()
      } else {
        this.resetForm('task-template-selected')
        this.selectedTemplateId = ''
      }
    },
    resetForm (type = '') {
      this.taskForm = {
        ticket_type_id: '',
        dpo_id: '',
        status: 'open_ticket',
        todo_type: 'todo_task',
        deadline: '',
        attachments_ids: [],
        send_email_attributes: {
          email_type: 'task',
          status: 'pending',
          subject: '',
          company_id: this.taskForm.send_email_attributes.company_id,
          body: '',
        },
        is_active: false,
        note: {
          enabled: false,
          audit_relevant: false,
          annual_report_relevant: false,
        },
        audit_call: false,
        responsible: '',
      }
      this.dpos = []
      this.uploadFlag = !this.uploadFlag
      this.responsibleDpo = ''

      if (type !== 'task-template-selected') {
        this.taskForm.send_email_attributes.company_id = ''
        this.selectedTemplateId = ''
      }
    },
    createTask (start) {
      this.taskFormRef.validate((valid) => {
        if (!valid) return false
        if (start) {
          this.taskForm.is_active = true
        }
        this.$axios.post(this.$apiRoutes.ticket, this.taskForm)
          .then(response => {
            this.clear()
            this.reloadCompanyShow()
            this.$errorMessage(-1, this.$tr('task.message.successfully_created'), 'success')
            this.$emit('close-modal')
            this.resetTodoList()
            if (start) {
              const ticket = this.$deserialize(response.data)
              this.setRunningTask({
                companyName: this.$lodash.get(ticket, 'company.name', 'Unknown Company'),
                ticketSubject: this.$lodash.get(ticket, 'send_email.subject', 'Anonymous Subject'),
                ticketSpentTime: this.$lodash.get(ticket, 'spent_time_tracking', 0),
                ticketId: ticket.id,
                trackingSpentTime: ticket.active_time_tracking.spent_time_tracking,
                timeTrackingId: parseInt(ticket.active_time_tracking.id),
                lastTrackerId: ticket.last_tracker_id,
                pause: ticket.active_time_tracking.pause,
              })
            }
          })
          .catch(e => {
            this.$errorMessage(e.request.status, e)
          })
      })
    },
    addAttachment (attachment) {
      this.taskForm.attachments_ids.push(attachment.id.toString())
    },
    reloadCompanyShow () {
      if (this.componentLocation !== 'header') this.resetActivitiesList()
    },
    fetchAuditAreas () {
      this.$axios
        .get(this.$apiRoutes.audit_area + '?sorted[column]=title&sorted[direction]=asc&per_page=all')
        .then(response => {
          this.auditAreas = this.$deserialize(response.data)
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-input--no-border {
  .el-input__inner {
    text-align: right;
    border: none;
  }
}
.el-form-item {
  .el-form-item__label {
    text-align: left;
  }
}
.spent_time_tag .el-tag{
  padding: 2px 20px;
  font-size: 18px;
  font-weight: bold;
  height: 40px;
}
.line-height-0 {
  .el-form-item__content {
    line-height: 0;
  }
  .el-radio {
    display: block;
    margin-bottom: 5px;
    color: currentColor;
  }
  .el-radio__label {
    font-size: 12px;
  }
}
</style>
