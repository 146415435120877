import { createPinia } from 'pinia'
import { useUserStore } from '@/stores/userStore'
// Usage: $userCan('<actionName>:<featureName>') which return true or false
// actionName can be one of 'view' or 'edit'

const pinia = createPinia()

const permissions = (() => {
  const userStore = useUserStore(pinia)

  const permissions = userStore.currentUser?.permissions ?? []
  const keyedPermissions = {}

  permissions.forEach(({ accessibility, value }) => {
    keyedPermissions[value] = accessibility
  })

  return keyedPermissions
})()

const permissionsFeatureFlagIsNotEnabled = () => {
  const userStore = useUserStore(pinia)

  return !userStore.currentUser?.active_feature_flags.includes('permissions')
}

const userCan = function (requestPhrase) {
  if (permissionsFeatureFlagIsNotEnabled()) return true

  let [requestedAction, feature] = requestPhrase.split(':')

  if (!feature) {
    // If only the feature name was inserted inside requestPhrase: then he has view permission
    feature = requestedAction
    requestedAction = 'view'
  }

  const permittedAction = permissions[feature]
  const userCanView =
    permittedAction === 'read' || permittedAction === 'read_write'
  const userCanViewAndEdit = permittedAction === 'read_write'

  if (requestedAction === 'edit') {
    return userCanViewAndEdit
  }

  if (requestedAction === 'view') {
    return userCanView
  }

  return false
}

export default userCan
