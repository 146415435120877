import axios from 'axios'
import qs from 'qs'
import { noActivityLogout } from './noActivityLogout'
import { refreshToken } from './keycloak'
import { useUserStore } from '@/stores/userStore'
import { useGlobalLoadingIndicatorStore } from '@/stores/globalLoadingIndicatorStore'
import { pinia } from '@/utils/globalSetup'
import { getUserLocale } from '@/utils/getUserLocale'


const token = document
  .getElementsByName('csrf-token')[0]
  .getAttribute('content')

axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { encode: true, arrayFormat: 'brackets' })
}

axios.interceptors.request.use(
  async function (config) {
    const globalLoadingIndicatorStore = useGlobalLoadingIndicatorStore(pinia)
    const userStore = useUserStore()

    const { beforeRequest } = config

    // only call noActivityLogout if user logged in
    if (userStore.currentUser) {
      noActivityLogout()
    }

    if (!beforeRequest || !beforeRequest.hidePreloader) {
      globalLoadingIndicatorStore.startLoading()
    }

    if (localStorage.getItem('refresh-token')) {
      await refreshToken()

      const token = localStorage.getItem('access-token')
      config.headers.Authorization = `Bearer ${token}`
    }

    const lang = getUserLocale(localStorage.getItem('lang'))
    config.headers['Accept-Language'] = lang

    return config
  },
  function (error) {
    const globalLoadingIndicatorStore = useGlobalLoadingIndicatorStore(pinia)
    globalLoadingIndicatorStore.stopLoading()
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  function (response) {
    const globalLoadingIndicatorStore = useGlobalLoadingIndicatorStore(pinia)
    globalLoadingIndicatorStore.stopLoading()
    return response
  },
  function (error) {
    const globalLoadingIndicatorStore = useGlobalLoadingIndicatorStore(pinia)
    globalLoadingIndicatorStore.stopLoading()

    return Promise.reject(error)
  },
)

export default axios
