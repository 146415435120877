<script setup>
import { useLanguageStore } from '@/stores/languageStore'
import { computed, ref } from 'vue'
import useAppQuery from '@/utils/useAppQuery'
import { tr } from '@/utils/utilityMethods'
import {
  debounce,
  isPlainObject,
  isEqual,
  uniqBy,
  uniqWith,
} from '@/utils/lodashSelectedMethods'

const props = defineProps({
  resource: { type: [String, Boolean], required: false, default: false },
  labelKey: { type: String, required: true },
  valueKey: { type: String, required: true },
  fetchOnFocus: { type: Boolean, required: false, default: false },
  remote: { type: Boolean, required: false, default: false },
  filterParamKey: { type: String, required: false, default: '' },
  // eslint-disable-next-line vue/require-default-prop
  customParams: { type: Object, required: false },
  // eslint-disable-next-line vue/require-default-prop
  placeHolder: { type: String, required: false },
  value: {
    type: [String, Object, Boolean, Array, Number],
    required: false,
    default: '',
  },
  initResourceData: { type: Array, required: false, default: () => [] },
  searchParamKey: { type: String, default: 'search' },
  dataTestPrefixForOptions: {
    type: String,
    required: false,
    default: 'option',
  },
})
const emit = defineEmits(['fetched-options', 'input'])

const startFetchingData = ref(false)
const useFilterParamInsteadOfSearchParam = ref(
  props.remote && props.filterParamKey && props.value,
)
const searchTerm = ref('')
const queryParams = computed(() => {
  const initialParams = {
    ...(useFilterParamInsteadOfSearchParam.value
      ? { [props.filterParamKey]: [props.value] }
      : { [props.searchParamKey]: searchTerm.value }),
  }
  const additionalParams = {
      sorted: {
        column: props.labelKey,
        direction: 'asc',
      },
    ...props.customParams,
  }

  return {
    ...initialParams,
    ...additionalParams,
  }
})

if (
  (!props.remote && props.resource && !props.fetchOnFocus) ||
  useFilterParamInsteadOfSearchParam.value
) {
  startFetchingData.value = true
}

const fetchDataOnfocus = () => {
  if (!props.fetchOnFocus) return

  startFetchingData.value = true
}

const search = debounce((query) => {
  if (query.length <= 2 || !props.remote) return

  useFilterParamInsteadOfSearchParam.value = false
  searchTerm.value = query
  startFetchingData.value = true
}, 500)

const { data, isFetching, isError } = useAppQuery({
  url: props.resource,
  params: queryParams,
  enabled: startFetchingData,
  retry: false,
  onSuccess: (data) => {
    emit('fetched-options', data)
  },
})

const resourceData = computed(() => {
  return mergeResources(data.value ?? [], props.initResourceData)
})

const mergeResources = (arrA, arrB) => {
  const merged = [...arrA, ...arrB]
  if (isPlainObject(merged[0])) {
    return uniqBy(merged, props.valueKey)
  }
  return uniqWith(merged, isEqual)
}

const placeHolderValue = computed(() => {
  return props.placeHolder ?? tr('messages.placeholder.enter_keyword')
})

const model = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('input', value)
  },
})

const languageStore = useLanguageStore()

const getLabel = (label) => {
  const lang = languageStore.lang
  let labelValue = label

  if (isPlainObject(label)) {
    labelValue = label[lang]
  }

  if (typeof labelValue === 'string' && labelValue.includes('.')) {
    labelValue = tr(labelValue)
  }

  return labelValue
}

const getValue = (value) => {
  const parsedValue = parseInt(value)

  if (isNaN(parsedValue) || parsedValue.toString() !== value) {
    return value
  }

  return parsedValue
}
</script>

<template>
  <el-select
    v-model="model"
    :placeholder="placeHolderValue"
    :remote-method="search"
    v-bind="{ ...$attrs, remote }"
    reserve-keyword
    :filterable="remote"
    :remote="remote"
    @focus="fetchDataOnfocus"
    v-on="$listeners">
    <div
      slot="empty"
      class="el-select-dropdown__empty">
      <span v-if="isFetching">Loading...</span>
      <span
        v-else-if="isError"
        class="color-red">
        An error occurred.
      </span>
      <span v-else>No data</span>
    </div>
    <el-option
      v-for="item in resourceData"
      :key="item[valueKey]"
      :label="getLabel(item[labelKey])"
      :value="getValue(item[valueKey])"
      :disabled="item.disabled"
      :data-test="`${dataTestPrefixForOptions}-${item[valueKey]}`">
      <slot
        name="customizedOption"
        :label="getLabel(item[labelKey])"
        :value="getValue(item[valueKey])" />
    </el-option>
  </el-select>
</template>
