import Resource from './resource'
import apiRoutes from './apiRoutes'

class EmployeeResource extends Resource {
  constructor () {
    super(apiRoutes.employee)
  }

  list (params = {}) {
    return this.call({
      method: 'get',
      url: '/',
      params,
    })
  }

  show (id) {
    return this.call({
      method: 'GET',
      url: `/${id}`,
    })
  }

  create (data) {
    return this.call({
      method: 'POST',
      url: '/',
      data,
    })
  }

  update ({ id, data }) {
    return this.call({
      method: 'PUT',
      url: `/${id}`,
      data,
    })
  }

  destroy (id) {
    return this.call({
      method: 'DELETE',
      url: `/${id}`,
    })
  }
}

export default new EmployeeResource()
