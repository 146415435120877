<template>
  <el-select
    v-model="selectedFromProps"
    placeholder="Please enter at least 3 Character"
    filterable
    clearable
    remote
    reserve-keyword
    :loading="loading"
    :remote-method="remoteMethodDpos"
    class="full-width"
    @change="handleChange">
    <el-option
      v-for="item in dpos"
      :key="item.id"
      :label="item.full_name"
      :data-test="`${item.first_name}-item-option`"
      :value="item.id" />
  </el-select>
</template>

<script>
let searchTimer

export default {
  props: {
    selected: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data () {
    return {
      dpos: this.$isEmpty(this.selected) ? [] : [this.selected],
      loading: false,
      selectedFromProps: this.selected,
    }
  },
  created () {
    if (!this.$isEmpty(this.selected)) {
      this.$emit('handle-dpo-change', +this.selected.id)
    }
  },
  methods: {
    handleChange (value) {
      this.$emit('handle-dpo-change', value)
    },
    remoteMethodDpos (query) {
      if (query.length > 2) {
        this.loadDpos(query)
      }
    },
    loadDpos (query) {
      if (searchTimer) {
        clearTimeout(searchTimer)
      }

      searchTimer = setTimeout(() => {
        this.loading = true

        let generatedRoute = this.$apiRoutes.dpo
        generatedRoute += '?search=' + encodeURIComponent(query)
        generatedRoute += '&account_active=true'
        generatedRoute += '&sorted[column]=first_name'
        generatedRoute += '&sorted[direction]=asc'
        generatedRoute += '&page=1&per_page=10'

        this.$axios
          .get(generatedRoute, {
            beforeRequest: {
              hidePreloader: true,
            },
          })
          .then(response => {
            this.loading = false
            this.dpos = this.$deserialize(response.data)
          })
          .catch(e => {
            this.loading = false
            this.$errorMessage(e.request.status, e)
          })
      }, 500)
    },
  },
}
</script>

<style>

</style>
