export const countryList = [
  { name: 'Afghanistan' },
  { name: 'Albania' },
  { name: 'Algeria' },
  { name: 'American Samoa' },
  { name: 'Andorra' },
  { name: 'Angola' },
  { name: 'Anguilla' },
  { name: 'Antigua & Barbuda' },
  { name: 'Argentina' },
  { name: 'Armenia' },
  { name: 'Aruba' },
  { name: 'Australia' },
  { name: 'Austria' },
  { name: 'Azerbaijan' },
  { name: 'Bahamas' },
  { name: 'Bahrain' },
  { name: 'Bangladesh' },
  { name: 'Barbados' },
  { name: 'Belarus' },
  { name: 'Belgium' },
  { name: 'Belize' },
  { name: 'Benin' },
  { name: 'Bermuda' },
  { name: 'Bhutan' },
  { name: 'Bolivia' },
  { name: 'Bonaire' },
  { name: 'Bosnia & Herzegovina' },
  { name: 'Botswana' },
  { name: 'Brazil' },
  { name: 'British Indian Ocean Ter' },
  { name: 'Brunei' },
  { name: 'Bulgaria' },
  { name: 'Burkina Faso' },
  { name: 'Burundi' },
  { name: 'Cambodia' },
  { name: 'Cameroon' },
  { name: 'Canada' },
  { name: 'Canary Islands' },
  { name: 'Cape Verde' },
  { name: 'Cayman Islands' },
  { name: 'Central African Republic' },
  { name: 'Chad' },
  { name: 'Channel Islands' },
  { name: 'Chile' },
  { name: 'China' },
  { name: 'Christmas Island' },
  { name: 'Cocos Island' },
  { name: 'Colombia' },
  { name: 'Comoros' },
  { name: 'Congo' },
  { name: 'Cook Islands' },
  { name: 'Costa Rica' },
  { name: 'Cote DIvoire' },
  { name: 'Croatia' },
  { name: 'Cuba' },
  { name: 'Curacao' },
  { name: 'Cyprus' },
  { name: 'Czech Republic' },
  { name: 'Denmark' },
  { name: 'Djibouti' },
  { name: 'Dominica' },
  { name: 'Dominican Republic' },
  { name: 'East Timor' },
  { name: 'Ecuador' },
  { name: 'Egypt' },
  { name: 'El Salvador' },
  { name: 'Equatorial Guinea' },
  { name: 'Eritrea' },
  { name: 'Estonia' },
  { name: 'Ethiopia' },
  { name: 'Falkland Islands' },
  { name: 'Faroe Islands' },
  { name: 'Fiji' },
  { name: 'Finland' },
  { name: 'France' },
  { name: 'French Guiana' },
  { name: 'French Polynesia' },
  { name: 'French Southern Ter' },
  { name: 'Gabon' },
  { name: 'Gambia' },
  { name: 'Georgia' },
  { name: 'Germany' },
  { name: 'Ghana' },
  { name: 'Gibraltar' },
  { name: 'Great Britain' },
  { name: 'Greece' },
  { name: 'Greenland' },
  { name: 'Grenada' },
  { name: 'Guadeloupe' },
  { name: 'Guam' },
  { name: 'Guatemala' },
  { name: 'Guinea' },
  { name: 'Guyana' },
  { name: 'Haiti' },
  { name: 'Hawaii' },
  { name: 'Honduras' },
  { name: 'Hong Kong' },
  { name: 'Hungary' },
  { name: 'Iceland' },
  { name: 'Indonesia' },
  { name: 'India' },
  { name: 'Iran' },
  { name: 'Iraq' },
  { name: 'Ireland' },
  { name: 'Isle of Man' },
  { name: 'Israel' },
  { name: 'Italy' },
  { name: 'Jamaica' },
  { name: 'Japan' },
  { name: 'Jordan' },
  { name: 'Kazakhstan' },
  { name: 'Kenya' },
  { name: 'Kiribati' },
  { name: 'Korea North' },
  { name: 'Korea South' },
  { name: 'Kuwait' },
  { name: 'Kyrgyzstan' },
  { name: 'Laos' },
  { name: 'Latvia' },
  { name: 'Lebanon' },
  { name: 'Lesotho' },
  { name: 'Liberia' },
  { name: 'Libya' },
  { name: 'Liechtenstein' },
  { name: 'Lithuania' },
  { name: 'Luxembourg' },
  { name: 'Macau' },
  { name: 'Macedonia' },
  { name: 'Madagascar' },
  { name: 'Malaysia' },
  { name: 'Malawi' },
  { name: 'Maldives' },
  { name: 'Mali' },
  { name: 'Malta' },
  { name: 'Marshall Islands' },
  { name: 'Martinique' },
  { name: 'Mauritania' },
  { name: 'Mauritius' },
  { name: 'Mayotte' },
  { name: 'Mexico' },
  { name: 'Midway Islands' },
  { name: 'Moldova' },
  { name: 'Monaco' },
  { name: 'Mongolia' },
  { name: 'Montserrat' },
  { name: 'Morocco' },
  { name: 'Mozambique' },
  { name: 'Myanmar' },
  { name: 'Nambia' },
  { name: 'Nauru' },
  { name: 'Nepal' },
  { name: 'Netherland Antilles' },
  { name: 'Netherlands (Holland, Europe' },
  { name: 'Nevis' },
  { name: 'New Caledonia' },
  { name: 'New Zealand' },
  { name: 'Nicaragua' },
  { name: 'Niger' },
  { name: 'Nigeria' },
  { name: 'Niue' },
  { name: 'Norfolk Island' },
  { name: 'Norway' },
  { name: 'Oman' },
  { name: 'Pakistan' },
  { name: 'Palau Island' },
  { name: 'Palestine' },
  { name: 'Panama' },
  { name: 'Papua New Guinea' },
  { name: 'Paraguay' },
  { name: 'Peru' },
  { name: 'Philippines' },
  { name: 'Pitcairn Island' },
  { name: 'Poland' },
  { name: 'Portugal' },
  { name: 'Puerto Rico' },
  { name: 'Qatar' },
  { name: 'Republic of Montenegro' },
  { name: 'Republic of Serbia' },
  { name: 'Reunion' },
  { name: 'Romania' },
  { name: 'Russia' },
  { name: 'Rwanda' },
  { name: 'St Barthelemy' },
  { name: 'St Eustatius' },
  { name: 'St Helena' },
  { name: 'St Kitts-Nevis' },
  { name: 'St Lucia' },
  { name: 'St Maarten' },
  { name: 'St Pierre & Miquelon' },
  { name: 'St Vincent & Grenadines' },
  { name: 'Saipan' },
  { name: 'Samoa' },
  { name: 'Samoa American' },
  { name: 'San Marino' },
  { name: 'Sao Tome & Principe' },
  { name: 'Saudi Arabia' },
  { name: 'Senegal' },
  { name: 'Seychelles' },
  { name: 'Sierra Leone' },
  { name: 'Singapore' },
  { name: 'Slovakia' },
  { name: 'Slovenia' },
  { name: 'Solomon Islands' },
  { name: 'Somalia' },
  { name: 'South Africa' },
  { name: 'Spain' },
  { name: 'Sri Lanka' },
  { name: 'Sudan' },
  { name: 'Suriname' },
  { name: 'Swaziland' },
  { name: 'Sweden' },
  { name: 'Switzerland' },
  { name: 'Syria' },
  { name: 'Tahiti' },
  { name: 'Taiwan' },
  { name: 'Tajikistan' },
  { name: 'Tanzania' },
  { name: 'Thailand' },
  { name: 'Togo' },
  { name: 'Tokelau' },
  { name: 'Tonga' },
  { name: 'Trinidad & Tobago' },
  { name: 'Tunisia' },
  { name: 'Turkey' },
  { name: 'Turkmenistan' },
  { name: 'Turks & Caicos Is' },
  { name: 'Tuvalu' },
  { name: 'Uganda' },
  { name: 'United Kingdom' },
  { name: 'Ukraine' },
  { name: 'United Arab Emirates' },
  { name: 'United States of America' },
  { name: 'Uruguay' },
  { name: 'Uzbekistan' },
  { name: 'Vanuatu' },
  { name: 'Vatican City State' },
  { name: 'Venezuela' },
  { name: 'Vietnam' },
  { name: 'Virgin Islands (Brit' },
  { name: 'Virgin Islands (USA' },
  { name: 'Wake Island' },
  { name: 'Wallis & Futana Is' },
  { name: 'Yemen' },
  { name: 'Zaire' },
  { name: 'Zambia' },
  { name: 'Zimbabwe' },
]
