import Vue from 'vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

const installElement = () => {
  Vue.use(ElementUI, { locale })

  /**
   * we could use this approach blow here to customize ElementUI components:
   * creating a function component that compose customized ElementUI Component.
   *
   * functional is required
   */

  /* customize DatePicker */
  Vue.component(ElementUI.DatePicker.name, {
    functional: true, // this is required
    component: ElementUI.DatePicker,
    render (h, context) {
      const elem = h(ElementUI.DatePicker, {
        ...context.data,
        props: {
          pickerOptions: {
            firstDayOfWeek: 1,
            ...context.props.pickerOptions,
          },
        },
      })

      return elem
    },

  })
}

export { installElement }
