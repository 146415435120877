<template>
  <el-card class="box-card">
    <div
      slot="header"
      class="flex justify-space-between items-center">
      <div class="flex items-center">
        <h3 data-test="page-title">
          {{ $tr(title) }}
        </h3>
        <el-row
          type="flex"
          justify="center"
          class="ml15"
          :gutter="15">
          <slot
            :span="12"
            name="headerFieldSlot" />
          <el-col
            :span="$slots.headerFieldSlot ? 12 : 24">
            <el-input
              v-model="query"
              :placeholder="$tr('messages.search')"
              data-test="table-search" />
          </el-col>
        </el-row>
      </div>
      <div class="flex items-center">
        <slot
          name="headerButtonSlot" />
        <el-button
          v-if="userCanEdit && isAddButtonVisible"
          type="primary"
          class="button-flat pull-right ml15"
          :disabled="addButtonDisabled"
          data-test="add-page-link"
          @click="createResource">
          {{ $tr('button.add') }}
        </el-button>
      </div>
    </div>
    <ResourceTable
      :key="tableKey"
      :create-resource-function="createResource"
      :resource="resource"
      :resource-params="resourceParams"
      :headers="headers"
      :query="query"
      :search-subject="searchSubject"
      :remote-search="remoteSearch"
      :empty-state-message-translation-keys="emptyStateMessageTranslationKeys"
      :is-delete-button-visible="isDeleteButtonVisible"
      :user-can-edit="userCanEdit" />
    <slot />
  </el-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: 'table.list',
    },
    resource: {
      type: [String, Object],
      required: true,
    },
    resourceParams: {
      type: Object,
      default: () => ({
        page: 1,
        per_page: 25,
      }),
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    // eslint-disable-next-line vue/require-default-prop
    emptyStateMessageTranslationKeys: {
      type: Object,
      required: false,
    },
    searchSubject: {
      type: String,
      default: '',
      required: false,
    },
    remoteSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    userCanEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    isAddButtonVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isDeleteButtonVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data () {
    return {
      tableKey: 0,
      query: '',
    }
  },
  computed: {
    addButtonDisabled () {
      if (typeof this.resource === 'string') return false
      return this.resource.parentName && !this.resource.parentId
    },
    resourceName () {
      return this.$lodash.get(this.resource, 'name', this.resource)
    },
    parentName () {
      return this.$lodash.get(this.resource, 'parentName')
    },
    parentId () {
      return this.$lodash.get(this.resource, 'parentId')
    },
    resourcePath () {
      const { parentName, parentId, resourceName } = this

      if (parentId) return `${parentName}/${parentId}/${resourceName}`
      return resourceName
    },
  },
  watch: {
    resource () {
      this.tableKey++
    },
  },
  methods: {
    createResource () {
      this.$router.push({
        name: this.$route.name.replace('-list', '-create'),
        params: {
          parentId: this.parentId,
          resourcePath: this.resourcePath,
        },
      })
    },
  },
}
</script>
