import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'
import { useQuery } from '@tanstack/vue-query'
import { errorMessage } from '@/utils/utilityMethods'
import { unref } from 'vue'

export default function useAppQuery({
  url,
  params = {},
  enabled = true,
  queryKey,
  ...useQueryOptions
}) {
  const { data, isLoading, isFetching, isError, refetch, ...useQueryOutput } =
    useQuery({
      queryKey: queryKey ?? [url, params],
      queryFn: ({ signal }) =>
        axios
          .get(unref(url), {
            params: unref(params),
            signal,
            beforeRequest: {
              hidePreloader: true,
            },
          })
          .then((res) => deserialize(res.data)),
      enabled,
      cacheTime: Infinity,
      onError: (e) => {
        errorMessage(e.request.status, e)
      },
      ...useQueryOptions,
    })

  return {
    data,
    refetch,
    isLoading,
    isFetching,
    isError,
    ...useQueryOutput,
  }
}
