<template>
  <div>
    <el-form
      v-if="!$isBlank(questionnaire)"
      ref="form">
      <el-card
        v-for="(questionnaire_section) in questionnaire.questionnaire_sections"
        :key="questionnaire_section.id"
        class="box-card"
        style="margin-bottom: 30px;"
        shadow="always">
        <div
          slot="header"
          class="clearfix">
          <span v-if="dpaModal.de_version">{{ questionnaire_section.title_de }}</span>
          <span v-else>{{ questionnaire_section.title_en }}</span>
        </div>
        <el-row
          v-for="question in questionnaire_section.questions"
          :key="question.id"
          :gutter="22">
          <QuestionFormElement
            :question="question"
            :de-version="dpaModal.de_version"
            :answer-sheet-id="answerSheet.id"
            @change-answer="changeAnswer" />
        </el-row>
      </el-card>

      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-check"
          data-test="save-button"
          @click="saveAnswerSheet()">
          Save
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import QuestionFormElement from './QuestionFormElement'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  components: {
    QuestionFormElement,
  },
  props: {
    documentSection: {
      type: Object,
      default () {
        return {}
      },
    },
    documentAnswerId: {
      type: [Number, String],
      default: '',
    },
    sectionType: {
      type: String,
      default: 'DpaSection',
    },
  },
  data () {
    return {
      questionnaire: null,
      answerSheet: {},
    }
  },
  computed: {
    ...mapState(useUserStore, ['dpaModalState']),
    dpaModal: {
      get () {
        return this.dpaModalState
      },
      set (value) {
        this.setDpaModalState(value)
      },
    },
  },
  created () {
    this.fetchQuestionnaire()
  },
  methods: {
    ...mapActions(useUserStore, ['setDpaModalState']),
    fetchQuestionnaire () {
      let generatedRoute = this.$apiRoutes.questionnaire
      generatedRoute += '/' + this.documentSection.questionnaire_id
      generatedRoute += '?location_type=' + this.sectionType
      generatedRoute += '&location_id=' + this.documentSection.id
      generatedRoute += '&root_location_type=Ticket'
      generatedRoute += '&root_location_id=' + this.dpaModal.ticket.id

      this.$axios.get(generatedRoute)
        .then(response => {
          this.questionnaire = this.$deserialize(response.data)
          this.setAnswerSheet()
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    setAnswerSheet () {
      if (!this.$isBlank(this.questionnaire.answer_sheet)) {
        this.answerSheet = this.questionnaire.answer_sheet
        this.answerSheet.answers_attributes = []
        return
      }
      this.answerSheet = {
        id: null,
        dpa_answer_id: this.documentAnswerId,
        location_type: this.sectionType,
        location_id: this.documentSection.id,
        root_location_type: 'Ticket',
        root_location_id: this.dpaModal.ticket.id,
        questionnaire_id: this.questionnaire.id,
        answers_attributes: [],
      }
    },
    changeAnswer (answer) {
      const answerIndex = this.answerSheet.answers_attributes.findIndex((item) => {
        return item.question_id === answer.question_id
      })
      if (answerIndex === -1) {
        this.answerSheet.answers_attributes.push(answer)
      } else {
        this.answerSheet.answers_attributes[answerIndex] = answer
      }
    },
    saveAnswerSheet () {
      if (this.$isBlank(this.answerSheet.id)) {
        this.createAnswerSheet()
      } else {
        this.upadteAnswerSheet()
      }
    },
    createAnswerSheet () {
      this.$axios.post(this.$apiRoutes.answer_sheet, this.answerSheet)
        .then(() => {
          this.$errorMessage(-1, 'Answer sheet was successfuly created.', 'success')
          this.fetchQuestionnaire()
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    upadteAnswerSheet () {
      this.$axios.put(this.$apiRoutes.answer_sheet + '/' + this.answerSheet.id, this.answerSheet)
        .then(() => {
          this.$errorMessage(-1, 'Answer sheet was successfuly updated.', 'success')
          this.fetchQuestionnaire()
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
  },
}
</script>
<style scoped>
.dpa_tabs {
  height: 400px;
  overflow-y: scroll;
}
</style>
