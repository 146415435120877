<template>
  <div>
    <div class="flex">
      <img
        :src="clockIcon"
        class="icon-svg mr10" />
      <span>{{ $tr('time_tracking.session') }}: {{ sessionOrder }}</span>
    </div>
    <div class="pl25">
      <span>Start: </span>
      <strong>{{ $dateTimeFormat(timePeriod.start_date, 'DD.MM.YYYY - HH:mm:ss') }}</strong>
      <span> | End: </span>
      <strong>{{ $dateTimeFormat(timePeriod.end_date, 'DD.MM.YYYY - HH:mm:ss') }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timePeriod: {
      type: Object,
      default: function () {
        return {
          id: null,
          start_date: '',
          end_date: '',
        }
      },
    },
    sessionOrder: {
      type: Number,
      required: true,
    },
    clockIcon: {
      type: String,
      required: true,
    },
  },
}
</script>
