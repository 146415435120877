<template>
  <el-dialog
    v-if="dpaModal.view"
    :visible.sync="dpaModal.view"
    width="90%"
    :title="$tr('send_email.check.check_dpa')">
    <DocumentAnswerForm
      v-if="enabledComponent === 'answer'"
      :document-routes="documentRoutes"
      @show-section="showSection" />
    <DocumentSectionList
      v-else-if="enabledComponent === 'section'"
      :document-answer="answer"
      :document-routes="documentRoutes"
      :section-type="sectionType" />
  </el-dialog>
</template>

<script>
import DocumentAnswerForm from './DocumentAnswerForm'
import DocumentSectionList from './DocumentSectionList'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  components: {
    DocumentAnswerForm,
    DocumentSectionList,
  },
  data () {
    return {
      enabledComponent: '',
      answer: null,
      documentRoutes: {
        answer: this.$apiRoutes.dpa_answer,
        section: this.$apiRoutes.dpa_section,
        generate: this.$apiRoutes.generated_dpa,
      },
      sectionType: 'DpaSection',
    }
  },
  computed: {
    ...mapState(useUserStore, ['dpaModalState']),
    dpaModal: {
      get () {
        return this.dpaModalState
      },
      set (value) {
        this.setDpaModalState(value)
      },
    },
  },
  watch: {
    'dpaModal.ticket': {
      handler () {
        this.enabledComponent = 'answer'
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(useUserStore, ['setDpaModalState']),
    showSection (answer) {
      this.enabledComponent = 'section'
      this.answer = answer
    },
  },
}
</script>
<style scoped>
.dpa_tabs {
  height: 400px;
  overflow-y: scroll;
}
</style>
