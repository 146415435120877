import microIcon from '@/assets/images/svg-icons/company_segment_micro.svg'
import smeIcon from '@/assets/images/svg-icons/company_segment_sme.svg'
import midMarketIcon from '@/assets/images/svg-icons/company_segment_mid_market.svg'
import enterpriseIcon from '@/assets/images/svg-icons/company_segment_enterprise.svg'
import largeEnterpriseIcon from '@/assets/images/svg-icons/company_segment_large_enterprise.svg'
import corporateIcon from '@/assets/images/svg-icons/company_segment_corporate.svg'

const segments = {
  micro: microIcon,
  sme: smeIcon,
  mid_market: midMarketIcon,
  corporate: corporateIcon,
  enterprise: enterpriseIcon,
  large_enterprise: largeEnterpriseIcon,
}

export const companySegments = Object.entries(segments).map(
  ([value, icon]) => ({
    title: `company.segments.${value}`,
    value,
    icon,
  }),
)
