<template>
  <div class="time-tracking-details">
    <el-row :gutter="10">
      <el-col
        :span="24">
        <el-tag
          v-if="billableModel == 'billable_self'"
          data-test="billable-self-tag"
          effect="dark"
          type="primary"
          class="font-normal ml0">
          {{ $tr('time_tracking.billable_self') }}
        </el-tag>
        <el-tag
          v-else-if="billableModel == 'non_billable'"
          data-test="non-billable-tag"
          effect="dark"
          type="primary"
          class="font-normal ml0">
          {{ $tr('time_tracking.non_billable') }}
        </el-tag>
        <el-tag
          v-else
          effect="dark"
          type="primary"
          class="font-normal ml0">
          Billable Parent
        </el-tag>
      </el-col>
      <el-col
        data-test="service-package"
        :span="24"
        class="flex">
        <span class="pr5">{{ $tr('company.contract.service_package') }}:</span>
        <strong>{{ $lodash.get(timeTracking, 'contract.service_package.value') }}</strong>
      </el-col>
      <el-col
        data-test="contact-person"
        :span="24"
        class="flex">
        <img
          :src="personBlackIcon"
          class="icon-svg mr10" />
        <span class="pr5">{{ $tr('time_tracking.contact_person') }}:</span>
        <strong>{{ timeTracking.contact_person_name }}</strong>
      </el-col>
      <el-col
        data-test="tracked-by"
        :span="24"
        class="flex">
        <img
          :src="personRedIcon"
          class="icon-svg mr10" />
        <span class="pr5">{{ $tr('time_tracking.tracked_by') }}:</span>
        <strong>{{ $lodash.get(timeTracking, 'tracking_assignee.full_name', '-') }}</strong>
      </el-col>
      <el-col
        :span="24"
        class="flex"
        data-test="phone-call">
        <img
          :src="phoneIcon"
          class="icon-svg mr10" />
        <span
          class="pr5">{{ $tr('time_tracking.phone_call') }}:</span>
        <strong>{{ timeTracking.phone_call }}</strong>
      </el-col>
      <el-col
        :span="24"
        class="flex items-start">
        <img
          :src="noteIcon"
          class="flex-shrink-0 icon-svg mr10" />
        <p
          data-test="task-description"
          class="flex-grow background-light-gray m0 p5"
          v-html="timeTracking.description" />
      </el-col>
      <el-col
        v-for="(timePeriod, index) in timePeriods"
        :key="index + timePeriod.start_date + timePeriod.end_date"
        :span="24">
        <TodoTimeTrackingsItemDetailsPeriod
          :time-period="timePeriod"
          :session-order="timePeriods.length - index"
          :clock-icon="clockIcon" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TodoTimeTrackingsItemDetailsPeriod from './TodoTimeTrackingsItemDetailsPeriod'
export default {
  components: {
    TodoTimeTrackingsItemDetailsPeriod,
  },
  props: {
    timeTracking: {
      type: Object,
      default: function () {
        return {
          description: null,
          billable: '',
          dpo_id: 0,
          tracking_assignee_id: 0,
          contact_person_id: null,
          phone_call: 'no',
          time_periods: [],
          tracking_assignee: {},
          dpo: {},
          deducted_from_id: '',
        }
      },
    },
    company: {
      type: Object,
      default: function () {
        return {}
      },
    },
    billableModel: {
      type: String,
      required: true,
    },
    personBlackIcon: {
      type: String,
      required: true,
    },
    personRedIcon: {
      type: String,
      required: true,
    },
    phoneIcon: {
      type: String,
      required: true,
    },
    noteIcon: {
      type: String,
      required: true,
    },
    clockIcon: {
      type: String,
      required: true,
    },
  },
  computed: {
    timePeriods () {
      return this.timeTracking.time_periods
    },
  },
}
</script>

<style lang="scss" scoped>
  .el-col-24 {
    + .el-col-24 {
      margin-top: 10px;
    }
  }
</style>
