<template>
  <div class="task-details">
    <el-row>
      <el-col :span="12">
        {{ $tr('time_tracking.type') }}
      </el-col>
      <el-col
        :span="12"
        class="font-bold text-right">
        {{ $lodash.get(ticket, `ticket_type.verbose_title.${lang}`, '-') }}
      </el-col>
    </el-row>
    <el-row v-if="ticket.ticket_type?.prompt && (ticket.ticket_type.prompt.de || ticket.ticket_type.prompt.en)">
      <el-col :span="12">AI Support</el-col>
      <el-col :span="12" class="font-bold text-right">
        <div v-if="ticketTypeChatGPTPromptOrURL('de')">
          DE:
          <a v-if="chatGPTURL('de')" :href="chatGPTURL('de')" target="_blank" class="el-button">Open in Chat GPT</a>
          <el-button v-if="!isCustomGPT('de')" @click="copyPrompt('de')">Copy Prompt</el-button>
        </div>
        <div v-if="ticketTypeChatGPTPromptOrURL('en')">
          EN:
          <a v-if="chatGPTURL('en')" :href="chatGPTURL('en')" target="_blank" class="el-button">Open in Chat GPT</a>
          <el-button v-if="!isCustomGPT('en')" @click="copyPrompt('en')">Copy Prompt</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">Offered Service</el-col>
      <el-col :span="12" class="font-bold text-right">
        <span v-if="supportService">
          {{ supportService.offered_service.title_en }}
          {{ supportService.formatted_value }}
        </span>
        <span v-else>-</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        {{ $tr('company.parent_company') }}
      </el-col>
      <el-col
        :span="12"
        class="font-bold text-right">
        {{ $lodash.get(ticket, 'company.parent_company.name', '-') }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        {{ $tr('time_tracking.subject') }}
      </el-col>
      <el-col
        :span="12"
        class="font-bold text-right">
        {{ $lodash.get(ticket, 'send_email.subject', '-') }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        {{ $tr('time_tracking.assigned_to') }}
      </el-col>
      <el-col
        :span="12"
        class="font-bold text-right">
        {{ $lodash.get(ticket, 'dpo.full_name', '-') }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        {{ $tr('time_tracking.deadline') }}
      </el-col>
      <el-col
        :span="12"
        class="font-bold text-right">
        {{ $dateTimeFormat(ticket.deadline, 'DD.MM.YYYY HH:mm:ss') }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        {{ $tr('task.audit_call') }}
      </el-col>
      <el-col
        :span="12"
        class="font-bold text-right">
        <div v-if="ticket.audit_call">
          {{ $lodash.get(ticket, 'audit_area.title', '-') }}
        </div>
        <div v-else>
          {{ ticket.audit_call }}
        </div>
        <div v-if="ticket.audit_call">
          <span class="font-normal">{{ $tr('task.relevant_to') }} </span><span>{{ $lodash.get(ticket, 'responsible', '-') }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        {{ $tr('task.note') }}
      </el-col>
      <el-col
        v-if="ticketNote.enabled"
        :span="12"
        class="font-bold text-right">
        <div>
          <el-button
            type="text"
            class="p0"
            @click="editNoteVisible = true">
            {{ $tr('task.open_note') }}
          </el-button>
        </div>
        <div v-if="ticketNote.annual_report_relevant || ticketNote.audit_relevant">
          <span class="font-normal">{{ $tr('task.relevant_to') }} </span>
          <span v-if="ticketNote.audit_relevant">{{ $tr('task.audit') }}</span>
          <span v-if="ticketNote.annual_report_relevant && ticketNote.audit_relevant"> & </span>
          <span v-if="ticketNote.annual_report_relevant">{{ $tr('task.report') }}</span>
        </div>
      </el-col>
      <el-dialog
        :visible.sync="editNoteVisible"
        :title="$tr('note.edit_note')"
        class="new-design"
        data-test="note-edit-dialog"
        append-to-body>
        <NoteEditForm
          v-if="editNoteVisible"
          :item="ticketNote"
          :company-id="ticket.company_id"
          :edit-note-visable="editNoteVisible"
          @note-updated="$emit('note-updated')"
          @close-edit-note="editNoteVisible = false" />
      </el-dialog>
    </el-row>
    <el-row class="pb0">
      <div class="pb10">
        {{ $tr('time_tracking.task_description') }}
      </div>
      <p
        class="font-bold mb10 mt0"
        style="white-space: pre-line;"
        v-html="ticket.send_email.body" />
    </el-row>
    <el-row class="pb0">
      <div class="pb10">
        {{ $tr('task.attachments') }}
      </div>
      <div>
        <el-tag
          v-for="file in ticket.attachments"
          :key="file.id"
          class="mb10 mr5 ml0">
          <a
            :href="$downloadLink(file.id)"
            target="_blank">
            {{ file.name }}
          </a>
        </el-tag>
      </div>
    </el-row>
    <el-row>
      <el-col :span="20">
        <div>
          <span>{{ $tr('time_tracking.created_by') }}: </span>
          <span>{{ ticket.creator }}</span>
        </div>
        <div>
          <span>{{ $tr('time_tracking.creation_date') }}</span>
          <span>{{ $dateTimeFormat(ticket.created_at, 'DD.MM.YYYY | HH:mm:ss') }}</span>
        </div>
        <div>
          <span>{{ $tr('task.updated_at') }}: </span>
          <span>{{ updatedAt }}</span>
        </div>
      </el-col>
      <el-col
        :span="4"
        class="text-right">
        <el-tooltip
          effect="dark"
          :content="$tr('time_tracking.button.share')"
          placement="top">
          <el-button
            type="text"
            @click="copyLink(ticket.id)">
            <img
              :src="shareIcon"
              class="icon-svg"
              alt="share icon" />
          </el-button>
        </el-tooltip>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { dayjs } from '@/utils/timeDateFormat'
import shareIcon from '@/assets/images/svg-icons/share-outline.svg'
import NoteEditForm from '@/components/NoteEditForm'
import { copyText } from '@/utils/utilityMethods'
import { mapState } from 'pinia'
import { useLanguageStore } from '@/stores/languageStore'

export default {
  components: {
    NoteEditForm,
  },
  props: {
    ticket: {
      type: Object,
      default: function () {
        return {}
      },
    },
    ticketNote: {
      type: Object,
      default: function () {
        return {}
      },
    },
    supportServices: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data () {
    return {
      shareIcon,
      editNoteVisible: false,
    }
  },
  computed: {
    ...mapState(useLanguageStore, ['lang']),
    supportService () {
      if (!this.supportServices && this.ticket?.ticket_type?.offered_service_id) {
        return
      }
      return this.supportServices.find(service => service.offered_service_id === this.ticket.ticket_type.offered_service_id)
    },
    updatedAt() {
      return dayjs(this.ticket.updated_at).from(dayjs())
    },
  },
  methods: {
    copyText,
    async copyLink (ticket) {
      const baseRoute = this.$router.options.base
      const ticketUrl = `${location.protocol}//${location.host}${baseRoute}todos?ticket_id=${ticket}`
      await copyText({
        string: ticketUrl,
        successMessage: this.$tr('todo.messages.copy_to_clipboard'),
      })
    },
    chatGPTURL(locale) {
      const promptOrURL = this.ticketTypeChatGPTPromptOrURL(locale)

      if (this.isCustomGPT(locale)) return promptOrURL

      const query = encodeURIComponent(promptOrURL)

      // We don't want to risk running into issues with overly long URLs
      if (query.length > 10000) return

      return `https://chat.openai.com?q=${query}`
    },
    isCustomGPT(locale) {
      // https://chatgpt.com/g/g-B3hgivKK9-write-for-me
      const promptOrURL = this.ticketTypeChatGPTPromptOrURL(locale)?.trim()

      return promptOrURL?.match(/^https:\/\/chatgpt.com\/g\/g-[^/\s]+$/)
    },
    async copyPrompt(locale) {
      if (this.isCustomGPT(locale)) return

      const prompt = this.ticketTypeChatGPTPromptOrURL(locale)
      if (!prompt) return

      await copyText({ string: prompt, successMessage: 'Prompt copied to clipboard' })
    },
    ticketTypeChatGPTPromptOrURL(locale) {
      if (!this.ticket.ticket_type?.prompt) return

      return this.ticket.ticket_type.prompt[locale]
    },
  },
}
</script>

 <style lang="scss" scoped>
 .task-details {
   > .el-row {
     padding: 12px 0;
     + .el-row {
       border-top: 1px solid #CCCCCC;
     }
   }
 }
 </style>
