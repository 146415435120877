import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faTimes,
  faExclamationCircle,
  faSitemap,
  faEdit,
  faEye,
  faTrashAlt,
  faChevronLeft,
  faPlusCircle,
  faEnvelope,
  faFlag,
  faPhone,
  faHistory,
  faPhoneAlt,
  faBan,
  faProjectDiagram,
  faFileAlt,
  faUndo,
  faHourglassHalf,
  faPause,
  faFileSignature,
  faBars,
  faCaretDown,
  faLock,
  faCircle,
  faTrash,
  faCopy,
  faPaperclip,
  faArrowRight,
  faArrowLeft,
  faReply,
  faReplyAll,
  faCalendarTimes,
  faFilePdf,
  faUpload,
  faStopwatch,
  faPlay,
  faRedoAlt,
  faUserCircle,
  faUser,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'

const installFontAwesomePlugin = {
  install(Vue) {
    Vue.component('FontAwesomeIcon', FontAwesomeIcon)
    Vue.component('FontAwesomeLayers', FontAwesomeLayers)
    library.add(
      faCheck,
      faTimes,
      faExclamationCircle,
      faSitemap,
      faEdit,
      faEye,
      faTrashAlt,
      faChevronLeft,
      faPlusCircle,
      faEnvelope,
      faFlag,
      faPhone,
      faHistory,
      faPhoneAlt,
      faBan,
      faProjectDiagram,
      faFileAlt,
      faUndo,
      faHourglassHalf,
      faPause,
      faFileSignature,
      faBars,
      faCaretDown,
      faLock,
      faCircle,
      faTrash,
      faCopy,
      faPaperclip,
      faArrowRight,
      faArrowLeft,
      faReply,
      faReplyAll,
      faCalendarTimes,
      faFilePdf,
      faUpload,
      faStopwatch,
      faPlay,
      faRedoAlt,
      faUserCircle,
      faUser,
      faTimesCircle,
    )
  },
}

export default installFontAwesomePlugin
