import { Notification } from 'element-ui'
import { logout } from './keycloak'

// times in minute
const options = {
  noActivityInterval: 180,
  warningTimeInterval: 20,
  warningDuration: 1,
}

// timers id for clearInterval
let logoutTimer // timer id for keep inactivity for noActivityInterval minutes
let warningTimer // timer id for show warning every warningTimeInterval minutes
let countdownTimer // timer id for countdown interval

// functions
const getTranslateOf = (key, fallback, replace) => {
  const translate = window.DATAGUARD.locale()[key]
  const replacedTranslate = translate && replace ? translate.split(/%{\w+}/).join('%{}').replace('%{}', replace) : translate
  return replacedTranslate || fallback
}

const noActivityText = (countdown) => {
  const str = getTranslateOf('no_activity.no_activity_logout_text', `You will be logged out after ${countdown}`, countdown)
  return str
}

const noActivityTitle = () => {
  const str = getTranslateOf('no_activity.no_activity_title', 'No activity')
  return str
}

const getCountdown = (endTime) => {
  const diff = endTime.getTime() - new Date().getTime()

  const seconds = parseInt((diff / 1000) % 60)
  const minutes = parseInt(diff / (1000 * 60) % 60)
  const hours = parseInt(diff / (1000 * 60 * 60) % 24)

  const countdown = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`

  return countdown
}

const noActivityLogout = () => {
  logoutTimer && clearInterval(logoutTimer)
  warningTimer && clearInterval(warningTimer)
  countdownTimer && clearInterval(warningTimer)

  const endTime = new Date(new Date().getTime() + options.noActivityInterval * 60000)

  warningTimer = setInterval(() => {
    countdownTimer = setInterval(() => {
      const notificationsDOM = document.querySelectorAll('.no-activity-notification')

      notificationsDOM.forEach(item => {
        item.querySelector('.el-notification__content').textContent = noActivityText(getCountdown(endTime))
      })
    }, 1000)

    Notification({
      type: 'warning',
      title: noActivityTitle(),
      message: noActivityText(getCountdown(endTime)),
      duration: options.warningDuration * 60000,
      position: 'top-left',
      customClass: 'no-activity-notification',
    })
  }, options.warningTimeInterval * 60000)

  logoutTimer = setTimeout(async () => {
    if (this.loginWithKeycloak) {
      await this.$axios.delete('/users/sign_out')
      await logout()
    } else {
      window.location.reload()
    }
  }, options.noActivityInterval * 60000)
}

export { noActivityLogout }
