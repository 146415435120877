export const getUserLocale = (sourceLanguage) => {
  const GERMAN = 'de'
  const ENGLISH = 'en'

  if ([ENGLISH, GERMAN].includes(sourceLanguage)) {
    return sourceLanguage
  }

  const browserLanguage = (navigator.language ?? navigator.userLanguage)?.slice(0, 2)
  if (browserLanguage === ENGLISH) {

    return ENGLISH
  }

  return GERMAN
}