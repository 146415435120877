<template>
  <div class="flex justify-between items-center">
    <div>
      <h4
        data-test="heading--todo-dialog--item-subject">
        {{ $tr('time_tracking.task') }}: {{ ticket.send_email.subject }}
      </h4>
      <strong
        style="font-size: 14px; color: #0078D4"
        data-test="ticket-status">
        {{ statusLabel(ticket.status) }}
      </strong>
    </div>
    <div class="actions flex flex-grow-1 justify-end text-right">
      <el-badge
        :value="ticket.comments_length || null"
        :class="['item', ticket.comments_length ? 'mr25' : 'mr10']"
        type="primary">
        <el-button @click="scrollToBottomOfDialog">
          {{$tr('comment.go_to_comments')}}
        </el-button>
      </el-badge>
      <el-select
        v-model="ticket.status"
        data-test="status-select"
        class="status-select mr10"
        :disabled="ticket.active_tracking && ticket.last_tracker_id !== currentUser.id"
        placeholder="Select Status">
        <el-option
          v-for="status in statuses"
          :key="status.value"
          :label="status.label"
          :value="status.value"
          :disabled="status.disabled"
          :data-test="`status-option-${status.value}`" />
      </el-select>
      <el-button @click="updateTaskStatus">
        {{ $tr('time_tracking.button.update_status') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  props: {
    ticket: {
      type: Object,
      required: true,
      default: function () {
        return {}
      },
    },
  },
  data () {
    return {
      statuses: [
        {
          label: this.$tr('todo.label.open'),
          value: 'open_ticket',
          disabled: this.ticket.status === 'open_ticket',
        },
        {
          label: this.$tr('todo.label.closed_failed'),
          value: 'closed_failed',
          disabled: this.ticket.status === 'closed_failed',
        },
        {
          label: this.$tr('todo.label.closed_success'),
          value: 'closed_success',
          disabled: this.ticket.status === 'closed_success',
        },
        {
          label: this.$tr('todo.label.in_progress'),
          value: 'in_progress',
          disabled: this.ticket.status === 'in_progress',
        },
        {
          label: this.$tr('todo.label.waiting_for_customer'),
          value: 'waiting_for_customer',
          disabled: this.ticket.status === 'waiting_for_customer',
        },
      ],
      taskCurrentStatus: 'in_progress',
    }
  },
  computed: {
    ...mapState(useUserStore, ['currentUser']),
  },
  methods: {
    updateTaskStatus () {
      const data = {
        id: this.ticket.id,
        status: this.ticket.status,
      }
      const generatedRoute = this.$apiRoutes.ticket + '/' + this.ticket.id
      this.$axios
        .put(generatedRoute, data)
        .then(() => {
          this.$emit('update-ticket')
          this.showSuccessfullyUpdateMessage()
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    showSuccessfullyUpdateMessage () {
      this.$errorMessage(
        -1,
        'The ticket #' + this.ticket.id + ' updated',
        'success',
      )
    },
    statusLabel (value) {
      if (value !== '-') {
        return this.statuses.find(status => status.value === value).label
      }
    },
    scrollToBottomOfDialog () {
      this.$emit('scroll-to-bottom-of-dialog')
    }
  },
}
</script>

<style lang="scss" scoped>
  .status-select {
    ::v-deep {
      .el-input__inner {
        height: 35px;
        line-height: 35px;
      }
      .el-input__icon {
        line-height: 35px;
      }
    }
  }
</style>
