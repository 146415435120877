<template>
  <div>
    <div class="flex justify-space-between items-center">
      <h4 class="my0">
        PPM {{ $tr("document_section.files_section_label") }}
      </h4>
    </div>
    <el-divider class="mt7 mb15" />
    <el-row class="ml10">
      <el-col :span="24">
        <Category
          :required="requiredFiles.includes('privacy_contract')"
          :absent="absentFiles.includes('privacy_contract')"
          :category="'privacy_contract'"
          :category-label="$tr('document_section.dpa_section_title')"
          :multiple="false"
          :files.sync="items" />
      </el-col>
    </el-row>
    <br />
    <el-row class="ml10">
      <el-col :span="24">
        <Category
          :required="requiredFiles.includes('toms')"
          :absent="absentFiles.includes('toms')"
          :category="'toms'"
          :category-label="$tr('document_section.tom_section_title')"
          :multiple="false"
          :files.sync="items" />
      </el-col>
    </el-row>
    <br />
    <el-row class="ml10">
      <el-col :span="24">
        <Category
          :required="requiredFiles.includes('main_contract')"
          :absent="absentFiles.includes('main_contract')"
          :category="'main_contract'"
          :category-label="$tr('document_section.main_contract_section_title')"
          :multiple="false"
          :files.sync="items" />
      </el-col>
    </el-row>
    <br />
    <el-row class="ml10">
      <el-col :span="24">
        <Category
          :required="requiredFiles.includes('additional_attachments')"
          :absent="absentFiles.includes('additional_attachments')"
          :category="'additional_attachments'"
          :category-label="
            $tr('document_section.additional_attachment_section_title')
          "
          :multiple="true"
          :files.sync="items" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Category from './Category'

export default {
  components: {
    Category,
  },
  props: {
    files: {
      type: Array,
      required: true,
      default () {
        return []
      },
    },
    requiredFiles: {
      type: Array,
      required: false,
      default () {
        return []
      },
    },
    absentFiles: {
      type: Array,
      required: false,
      default () {
        return []
      },
    },
  },
  data () {
    return {
      items: [],
    }
  },
  watch: {
    files: {
      handler (v) {
        if (!this.$isEmpty(v)) {
          this.items = JSON.parse(JSON.stringify(v))
        }
      },
      immediate: true,
    },
    items: {
      handler () {
        this.emit()
      },
      deep: true,
    },
  },
  methods: {
    emit () {
      if (!this.$lodash.isEqual(this.items, this.files)) {
        this.$emit('update:files', JSON.parse(JSON.stringify(this.items)))
      }
    },
  },
}
</script>
