import axios from 'axios'
import merge from 'lodash/merge'

/**
 * use this function to get list of a resource, pass `params` to payload for sorting, filtering or ...
 * @param {string} resource name of resource should api to call it.
 * @param {object} payload payload of data should be processed. usage example: { params: { order_by: "company_name" } }
 * @param {objet} requestConfig axios request object configuration
 */
function getList(resource, params = {}, requestConfig = {}) {
  return axios(
    merge(
      {
        method: 'GET',
        url: resource,
        params,
      },
      requestConfig,
    ),
  )
}

/**
 * use this function to get one item from a resource, pass `id` to payload
 * @param {string} resource name of resource should api to call it.
 * @param {object} payload payload of data should be processed. usage example: { id: 2 }
 * @param {objet} requestConfig axios request object configuration
 */
function getOne(resource, params = {}, requestConfig = {}) {
  return axios(
    merge(
      {
        method: 'GET',
        url: `${resource}/${params.id}`,
      },
      requestConfig,
    ),
  )
}

/**
 * use this function to create one item from resource, pass `data` to payload
 * @param {string} resource name of resource should api to call it.
 * @param {object} payload payload of data should be processed. usage example: { data: { name: 'test'} }
 * @param {objet} requestConfig axios request object configuration
 */
function createOne(resource, params = {}, requestConfig = {}) {
  return axios(
    merge(
      {
        method: 'POST',
        url: resource,
        data: params.data,
      },
      requestConfig,
    ),
  )
}

/**
 * use this function to update one item from resource, pass `data` and `id` to payload
 * @param {string} resource name of resource should api to call it.
 * @param {object} payload payload of data should be processed. usage example: { id: 2, data: { id: 2 , name: 'test', ...} }
 * @param {objet} requestConfig axios request object configuration
 */
function updateOne(resource, params = {}, requestConfig = {}) {
  return axios(
    merge(
      {
        method: 'PUT',
        url: `${resource}/${params.id}`,
        data: params.data,
      },
      requestConfig,
    ),
  )
}

/**
 * use this function to delete one item from resource, , pass `id` to payload
 * @param {string} resource name of resource should api to call it.
 * @param {object} payload payload of data should be processed. usage example: { id: 2 }
 * @param {objet} requestConfig axios request object configuration
 */
function deleteOne(resource, params = {}, requestConfig = {}) {
  return axios(
    merge(
      {
        method: 'DELETE',
        url: `${resource}/${params.id}`,
      },
      requestConfig,
    ),
  )
}

export { getList, getOne, createOne, updateOne, deleteOne }
