<template>
  <div>
    <div class="flex justify-space-between items-center">
      <h4 class="my0">
        {{ $tr('document_section.company') }}
      </h4>
      <PpmDetails
        v-if="ppm"
        :ppm="ppm" />
    </div>
    <el-divider class="mt7" />
    <el-row :gutter="20">
      <el-col :span="5">
        <el-form-item
          :label="$tr('document_section.company')"
          prop="company_id">
          <el-select
            v-model="form.company_id"
            placeholder="Please enter at least 3 Character"
            filterable
            remote
            :remote-method="loadCompaniesByName"
            class="full-width">
            <el-option
              v-for="item in companies"
              :key="item.id"
              :label="item.name"
              :value="parseInt(item.id)" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="5">
        <el-form-item
          :label="$tr('document_section.customer_role')"
          prop="customer_role">
          <el-select
            v-model="form.customer_role"
            class="full-width">
            <el-option
              v-for="item in customerRoleItems"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="5">
        <el-form-item :label="$tr('document_section.date')">
          <el-date-picker
            v-model="form.date"
            type="datetime"
            class="full-width" />
        </el-form-item>
      </el-col>

      <el-col :span="5">
        <el-form-item
          :label="$tr('document_section.version')"
          prop="version">
          <el-input
            v-model="form.version"
            data-test="version-input"
            class="full-width" />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PpmDetails from './PpmDetails'

export default {
  components: {
    PpmDetails,
  },
  props: {
    customer: { type: Object, required: true },
    ppm: { type: Object, required: false, default () { return {} } },
    company: { type: Object, required: true, default () { return { name: '' } } },
  },
  data () {
    return {
      form: {},
      searchTimer: null,
      companies: [this.company],
      customerRoleItems: [
        { value: 'client', label: this.$tr('document_section.client') },
        { value: 'contractor', label: this.$tr('document_section.contractor') },
      ],
    }
  },
  watch: {
    customer: {
      handler (v) {
        this.form = JSON.parse(JSON.stringify(v))
      },
      deep: true,
      immediate: true,
    },
    form: {
      handler: 'emit',
      deep: true,
    },
  },
  methods: {
    emit () {
      if (this.$isBlank(this.form)) return

      if (!this.$lodash.isEqual(this.form, this.customer)) {
        this.$emit('update:customer', JSON.parse(JSON.stringify(this.form)))
      }
    },
    loadCompaniesByName (query) {
      if (query.length > 2) return false
      if (this.searchTimer) clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.companies = []

        let generatedRoute = this.$apiRoutes.company
        generatedRoute += '?search[query]=' + encodeURIComponent(query)
        generatedRoute += '&search[column]=name'

        this.$axios
          .get(generatedRoute)
          .then(response => {
            this.companies = this.$deserialize(response.data)
          })
          .catch(e => {
            this.$errorMessage(e.request.status, e)
          })
      }, 500)
    },
  },
}
</script>
