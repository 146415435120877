<template>
  <el-link
    :disabled="!file.url"
    :href="file.url"
    target="_blank"
    type="primary">
    V{{ file.version }}:
    &nbsp;
    {{ $dateTimeFormat(file.created_at, 'DD.MM.YYYY, HH:mm') }}
    &nbsp;&nbsp;
    {{ file.file_name }}
  </el-link>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
      default () { return { url: '', file_name: '' } },
    },
  },
}
</script>
