<template>
  <div>
    <div
      :class="[
        'flex',
        'justify-space-between',
        'items-left',
        { absent: absent },
      ]">
      <strong class="my0">
        {{ categoryLabel }}
        <span
          v-if="required"
          style="color: #f56c6c;">
          *
        </span>
      </strong>
      <el-upload
        v-if="addable"
        ref="upload"
        :multiple="false"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="addFile"
        :action="''">
        <el-button
          type="text"
          class="add-button">
          <i class="el-icon-circle-plus-outline" />
        </el-button>
      </el-upload>
    </div>
    <el-divider class="mt7 mb7" />
    <el-row
      v-for="(item, index) in items"
      :key="index"
      :gutter="20"
      :class="
        `uploaded_item mt5 mr10 ml0 ${visible(item) ? '' : 'hide_element'}`
      ">
      <template v-if="visible(item)">
        <el-col :span="23">
          <File :file="items[index]" />
        </el-col>
        <el-col :span="1">
          <el-button
            :type="item._destroy ? 'info' : 'danger'"
            :icon="item._destroy ? 'el-icon-refresh-left' : 'el-icon-delete'"
            size="small"
            circle
            @click="triggerRemove(index)" />
        </el-col>
      </template>
    </el-row>
  </div>
</template>

<script>
import File from './File'

export default {
  components: {
    File,
  },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    absent: {
      type: Boolean,
      required: false,
      default: false,
    },
    files: {
      type: Array,
      required: true,
      default () {
        return []
      },
    },
    category: {
      type: String,
      required: true,
    },
    categoryLabel: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    multipleLimitation: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data () {
    return {
      items: [],
    }
  },
  computed: {
    categoryItems () {
      if (!this.items) {
        return []
      }

      return this.items.filter(item => item.file_type === this.category)
    },
    addable () {
      if (this.categoryItems.length === 0) return true
      if (this.multiple && this.categoryItems.length <= this.multipleLimitation) { return true }

      return false
    },
  },
  watch: {
    files: {
      handler (v) {
        this.items = JSON.parse(JSON.stringify(v))
      },
      immediate: true,
    },
    items: {
      handler () {
        this.emit()
      },
      deep: true,
    },
  },
  methods: {
    emit () {
      if (!this.$lodash.isEqual(this.items, this.files)) {
        this.$emit('update:files', JSON.parse(JSON.stringify(this.items)))
      }
    },
    triggerRemove (index) {
      if (this.$isBlank(this.items[index].id)) {
        this.items.splice(index, 1)
      } else {
        this.items[index]._destroy = !this.items[index]._destroy
        this.emit()
      }
    },
    visible (item) {
      if (item.file_type !== this.category) return false
      if (item.id) return true
      if (item.file) return true

      return false
    },
    async addFile (file) {
      const result = await this.fileObjToDataURL(file)

      const item = {
        id: null,
        file_name: result.title,
        file: result.file,
        file_type: this.category,
        size: '',
        url: '',
        version: '',
        _destroy: false,
      }

      this.items.unshift(item)
      this.emit()
    },
    fileObjToDataURL (fileObject) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        // reject promise on error handler
        reader.onerror = () => reject(reader.error)
        // resolve with dataURL on success handler
        reader.onload = () => {
          const file = reader.result
          const title = fileObject.name
          resolve({ title, file })
        }
        // trigger read method
        reader.readAsDataURL(fileObject.raw)
      })
    },
  },
}
</script>
<style scoped lang="scss">
.uploaded_item {
  background-color: #ededed;
}
.hide_element {
  display: none;
}
.add-button {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 22px;
  i {
    font-weight: bolder !important;
  }
}
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  color: currentColor;
  z-index: 2;
  i {
    font-weight: bolder !important;
  }
}

.absent {
  padding: 5px;
  border-radius: 10px;
  border: 1px dashed #f56c6c;
}
</style>
