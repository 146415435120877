<template>
  <img v-if="value && constantValue" :src="$downloadLink(value, constantValue)" />
  <div v-else>-</div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    constantValue: {
      type: String,
      default: null,
    },
  },
}
</script>
