import { defineStore } from 'pinia'

export const useGlobalLoadingIndicatorStore = defineStore(
  'globalLoadingIndicatorStore',
  {
    state: () => ({ onGoingLoadingsCount: 0 }),
    getters: {
      isLoading(state) {
        return state.onGoingLoadingsCount > 0
      },
    },
    actions: {
      startLoading() {
        this.onGoingLoadingsCount += 1
      },
      stopLoading() {
        if (this.onGoingLoadingsCount) {
          this.onGoingLoadingsCount -= 1
        }
      },
    },
  },
)
