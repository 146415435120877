import * as lodashSelectedMethods from '@/utils/lodashSelectedMethods'
import apiRoutes from '@/api/apiRoutes'
import axios from './axiosSetup'
import userCan from '@/utils/acl'
import { deserialize } from 'jsonapi-deserializer'
import { logout } from './keycloak'
import {
  dateTimeFormat,
  timeDurationFormat,
} from './timeDateFormat'
import {
  getList,
  getOne,
  createOne,
  updateOne,
  deleteOne,
} from '@/api/dataProvider'
import {
  isBlank,
  truncate,
  pluck,
  downloadLink,
  isEmpty,
  booleanIcon,
  errorMessage,
  featureFlagIsActive,
  tr,
  hasRole,
} from './utilityMethods'

const installPrototypeUtilities = {
  install(Vue) {
    Vue.prototype.$axios = axios
    Vue.prototype.$apiRoutes = apiRoutes
    Vue.prototype.$lodash = lodashSelectedMethods
    Vue.prototype.$dateTimeFormat = dateTimeFormat
    Vue.prototype.$timeDurationFormat = timeDurationFormat
    Vue.prototype.$userCan = userCan

    Vue.prototype.$getList = getList
    Vue.prototype.$getOne = getOne
    Vue.prototype.$createOne = createOne
    Vue.prototype.$updateOne = updateOne
    Vue.prototype.$deleteOne = deleteOne

    Vue.prototype.$isBlank = isBlank
    Vue.prototype.$truncate = truncate
    Vue.prototype.$pluck = pluck
    Vue.prototype.$downloadLink = downloadLink
    Vue.prototype.$isEmpty = isEmpty
    Vue.prototype.$deserialize = deserialize
    Vue.prototype.$booleanIcon = booleanIcon
    Vue.prototype.$errorMessage = errorMessage

    Vue.prototype.$auth = { logout }
    Vue.prototype.$featureFlagIsActive = featureFlagIsActive
    Vue.prototype.$tr = tr
    Vue.prototype.$hasRole = hasRole
  },
}

export default installPrototypeUtilities
