<template>
  <div
    data-test="floating-buttons"
    class="flex justify-between items-center">
    <div
      class="ticket-info cursor-pointer"
      @click="modal = { view: true, id: runningTask.ticketId }">
      <h4>
        <TimeTrackingTimer :item-type="'time_tracking'" />
      </h4>
      <div data-test="company-name">
        {{ runningTask.companyName }}
      </div>
      <div
        class="text-mute"
        data-test="todo-subject">
        {{ runningTask.ticketSubject }}
      </div>
    </div>
    <div class="flex items-center">
      <el-button
        :data-test="runningTask.pause ? 'button--start-time-tracking' : 'button--pause-time-tracking'"
        :class="runningTask.pause ? 'floating-start-button' : 'floating-pause-button'"
        :type="runningTask.pause ? 'success' : 'info'"
        circle
        @click="runningTask.pause ? continueTracking() : pauseTracking()" />
      <el-button
        data-test="button--stop-time-tracking"
        class="floating-stop-button"
        type="danger"
        circle
        @click="stopTracking" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  computed: {
    ...mapState(useUserStore, [
      'runningTask',
      'lastUpdatedTicket',
      'ticketModalState',
    ]),
    modal: {
      get () {
        return this.ticketModalState
      },
      set (value) {
        this.setTicketModalState(value)
      },
    },
  },
  methods: {
    ...mapActions(useUserStore, ['setTicketModalState', 'runTrackingAction']),
    continueTracking () {
      const data = {
        ticket_id: this.runningTask.ticketId,
        action: 'continue',
      }
      this.runTrackingAction(data)

      this.openModal()
    },
    pauseTracking () {
      const data = {
        ticket_id: this.runningTask.ticketId,
        action: 'pause',
      }
      this.runTrackingAction(data)
    },
    stopTracking () {
      const data = {
        ticket_id: this.runningTask.ticketId,
        action: 'stop',
      }
      this.runTrackingAction(data)

      this.openModal()
    },
    openModal () {
      this.modal = { view: true, id: this.runningTask.ticketId }
    },
  },
}
</script>
