<template>
  <div>
    <el-row>
      <el-col
        :span="24"
        style="height: 600px; overflow: auto">
        <el-tabs
          :stretch="true"
          type="border-card">
          <el-tab-pane
            v-for="item in documentSections"
            :key="item.id"
            :lazy="true">
            <div slot="label">
              <span v-if="dpaModal.de_version">{{ item.title_de }}</span>
              <span v-else>{{ item.title_en }}</span>
            </div>
            <div v-if="!$isBlank(item.questionnaire)">
              <DocumentSectionItem
                :document-section="item"
                :document-answer-id="documentAnswer.id"
                :section-type="sectionType" />
            </div>
            <div v-else>
              {{ $tr('document_section.messages.empty_questionnaire') }}
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col
        :span="24"
        class="mt30">
        <el-row :gutter="24">
          <el-col :span="12">
            <span class="block mb10">
              {{ $tr('document_section.it_reviewer') }}
            </span>
            <DpoSelector
              data-test="it-reviewer-selector"
              :selected="documentAnswer.it_reviewer"
              @handle-dpo-change="(value)=>handleDpoChange('itReviewer', value)" />
          </el-col>
          <el-col :span="12">
            <span class="block mb10 is-required-right">
              {{ $tr('document_section.legal_reviewer') }}
            </span>
            <DpoSelector
              data-test="legal-reviewer-selector"
              :selected="documentAnswer.legal_reviewer"
              @handle-dpo-change="(value)=>handleDpoChange('legalReviewer', value)" />
            <span
              v-if="legalReviewer.error"
              class="block element-color-red">
              {{ legalReviewer.message }}
            </span>
          </el-col>
        </el-row>
      </el-col>
      <el-col
        :span="24"
        class="text-right mt30">
        <span style="color: orange; font-size: 12px">
          {{ $tr('document_section.click_save_before_finish') }}
        </span>
            &nbsp;
        <el-button
          type="primary"
          class="button-flat"
          data-test="finish-and-generate-button"
          @click="checkValidatedData">
          {{ $tr('document_section.finish_and_generate_pdf') }}
        </el-button>
      </el-col>
    </el-row>

    <el-dialog
      :title="$tr('messages.show_company')"
      :visible.sync="showCompanyQuestion"
      width="40%"
      center
      append-to-body>
      <div
        slot="footer"
        class="dialog-footer button align-right">
        <el-button
          data-test="show-and-edit-company-button"
          @click="generatedDocumentPdf">
          {{ $tr('button.show_company_and_edit_document') }}
        </el-button>

        <el-button @click="showCompanyQuestion = false">
          {{ $tr('button.cancel') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DocumentSectionItem from './DocumentSectionItem'
import DpoSelector from './DpoSelector'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  components: {
    DocumentSectionItem,
    DpoSelector,
  },
  props: {
    documentAnswer: {
      type: Object,
      default () {
        return {}
      },
    },
    documentRoutes: {
      type: Object,
      required: true,
      default () {
        return {
          answer: this.$apiRoutes.dpa_answer,
        }
      },
    },
    sectionType: {
      type: String,
      default: 'DpaSection',
    },
  },
  data () {
    return {
      documentSections: [],
      showCompanyQuestion: false,
      itReviewer: {
        error: false,
        message: this.$tr('document_section.messages.required_it_reviewer'),
        value: '',
      },
      legalReviewer: {
        error: false,
        message: this.$tr('document_section.messages.required_legal_reviewer'),
        value: '',
      },
    }
  },
  computed: {
    ...mapState(useUserStore, ['dpaModalState']),
    dpaModal: {
      get () {
        return this.dpaModalState
      },
      set (value) {
        this.setDpaModalState(value)
      },
    },
  },
  watch: {
    'legalReviewer.value' (value) {
      if (value) {
        this.legalReviewer.error = false
      } else {
        this.legalReviewer.error = true
      }
    },
  },
  created () {
    this.fetch()
  },
  methods: {
    ...mapActions(useUserStore, [
      'clearTicketModalState',
      'clearDpaModalState',
      'selectActiveActivityTab',
      'setDpaModalState',
    ]),
    fetch () {
      let generatedRoute = this.documentRoutes.section
      generatedRoute += '?by_document_type=' + this.dpaModal.document_type
      this.$axios
        .get(generatedRoute)
        .then(response => {
          this.documentSections = this.$deserialize(response.data)
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    checkValidatedData () {
      if (this.$isBlank(this.documentAnswer.company_id)) {
        this.$errorMessage(
          -1,
          this.$tr('document_section.messages.company_exist'),
        )
        this.showCompanyQuestion = false
        return
      }

      if (this.$isBlank(this.legalReviewer.value)) {
        this.legalReviewer.error = true
        this.$errorMessage(-1, this.$tr(this.legalReviewer.message))
        this.showCompanyQuestion = false
        return
      }

      this.showCompanyQuestion = true
    },
    generatedDocumentPdf () {
      const data = {
        company_id: this.documentAnswer.company_id,
        document_section_answer_id: this.documentAnswer.id,
        language: this.dpaModal.de_version ? 'german' : 'english',
        template_type: this.dpaModal.document_type,
        only_parse_content: true,
        it_reviewer_id: this.itReviewer.value,
        legal_reviewer_id: this.legalReviewer.value,
      }

      this.$axios
        .post(this.documentRoutes.generate, data)
        .then(response => {
          this.showCompanyDetail(response.data.data)
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    showCompanyDetail (parsedContent = null) {
      const documentSection = {
        activeTab: this.dpaModal.document_type,
        parsedContent,
        documentSectionAnswerId: this.documentAnswer.id,
      }

      this.showCompanyQuestion = false // close popup

      if (this.$route.name !== 'company-show') {
        this.$router.push({
          name: 'company-show',
          params: { id: this.documentAnswer.company_id },
        })
      }
      this.clearTicketModalState()
      this.clearDpaModalState()
      this.selectActiveActivityTab(documentSection)
    },
    handleDpoChange (key, value) {
      this[key].value = value
    },
  },
}
</script>
<style scoped>
.dpa_tabs {
  height: 400px;
  overflow-y: scroll;
}
</style>
