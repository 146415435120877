<template>
  <el-card
    v-loading="loading"
    style="background-color: #F4F4F5;"
    class="el-card__body--pb0">
    <div
      slot="header"
      class="pt20 pb10">
      <div class="w-full">
        <div class="flex items-center justify-between">
          <div style="font-size: 16px">
            <span class="pr10">ID</span><strong>{{ ticket.id }}</strong>
          </div>
          <div v-if="$userCan('edit:todos')">
            <div
              v-if="editTask"
              class="flex">
              <el-tooltip
                effect="dark"
                :content="$tr('time_tracking.button.cancel_editing_time_tracking')"
                placement="top">
                <el-button
                  plain
                  @click="closeEditTask">
                  {{ $tr('time_tracking.button.cancel') }}
                </el-button>
              </el-tooltip>
              <el-tooltip
                effect="dark"
                :content="$tr('time_tracking.button.save_time_tracking')"
                placement="top">
                <el-button
                  data-test="button--edit-task--submit"
                  type="primary"
                  @click="updateTask">
                  {{ $tr('time_tracking.button.save') }}
                </el-button>
              </el-tooltip>
            </div>
            <div v-else>
              <el-tooltip
                effect="dark"
                :content="$tr('time_tracking.button.edit_time_tracking')"
                placement="top">
                <el-button
                  data-test="task-edit-button"
                  type="primary"
                  :disabled="ticket.active_tracking && ticket.last_tracker_id !== currentUser.id"
                  @click="openEditTask">
                  {{ $tr('time_tracking.button.edit') }}
                </el-button>
              </el-tooltip>
            </div>
          </div>
        </div>
        <el-row
          type="flex"
          class="items-center mt20">
          <el-col :span="10">{{ $tr('time_tracking.customer') }}</el-col>
          <el-col
          :span="14"
          class="text-right">
            <SelectWrapper
              v-if="editTask"
              v-model="taskUpdate.company_id"
              :resource="$apiRoutes.company"
              label-key="name"
              value-key="id"
              remote
              class="full-width"
              search-param-key="search[query]"
              :init-resource-data="companiesForSelect"
              :custom-params="{
                fields: { company: ['name'] },
              }"
              :place-holder="$tr('header.search_company_placeholder')" />
            <el-link
              v-if="!editTask && ticket.company !== undefined"
              type="primary"
              class="font-bold"
              @click="showCompany(ticket.company.id)">
              {{ ticket.company.name }}
            </el-link>
          </el-col>
        </el-row>
        <div class="flex items-center justify-between mt20">
          <div>{{ $tr('time_tracking.customer_segment') }}</div>
          <CompanySegment
            v-if="ticket.company?.segment"
            :segment="ticket.company?.segment"
            class="bold" />
        </div>
      </div>
    </div>
    <TodoPropertiesFrom
      v-if="editTask"
      v-loading="isLoadingTaskForm"
      :task-update="taskUpdate"
      :ticket="ticket"
      :ticket-types="ticketTypes" />
    <TodoPropertiesDetails
      v-else
      :ticket="ticket"
      :ticket-note="ticket.note"
      :support-services="supportServices"
      @note-updated="$emit('update-ticket')" />
    <el-form>
      <DpaSectionsDropdown
        v-if="ticket.ticket_type !== undefined"
        :ticket="ticket" />
    </el-form>
    <CommentList
      :key="ticket.id"
      class="my40"
      commentable-type="Ticket"
      :commentable-id="+ticket.id" />
  </el-card>
</template>

<script>
import DpaSectionsDropdown from '@/components/DpaSectionsDropdown'
import TodoPropertiesFrom from './TodoPropertiesFrom'
import TodoPropertiesDetails from './TodoPropertiesDetails'
import CompanySegment from '@/pages/companies/components/CompanySegment'
import CommentList from '@/components/emails/comments/CommentList'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  components: {
    DpaSectionsDropdown,
    TodoPropertiesFrom,
    TodoPropertiesDetails,
    CompanySegment,
    CommentList,
  },
  props: {
    ticket: {
      type: Object,
      default: function () {
        return {}
      },
    },
    supportServices: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data () {
    return {
      loading: false,
      editTask: false,
      taskUpdate: {
        company_id: '',
        ticket_type_id: '',
        deadline: '',
        audit_call: false,
        audit_area_id: null,
        responsible: '',
        dpo_id: '',
        dpo: {},
        send_email_attributes: {
          body: '',
          subject: '',
        },
        attachments: [],
        added_files: [],
        removed_files: [],
        note: {
          enabled: false,
          audit_relevant: false,
          annual_report_relevant: false,
        },
      },
      ticketTypes: [],
      isLoadingTaskForm: false,
    }
  },
  computed: {
    ...mapState(useUserStore, ['ticketModalState', 'currentUser']),
    modal: {
      get () {
        return this.ticketModalState
      },
      set (value) {
        this.setTicketModalState(value)
      },
    },
    companiesForSelect () {
      // NOTE: SelectWrapper expects an array, but a null-ish entry causes the dropdown not to show at all, which makes
      //       it impossible to select any entry. Passing an empty array makes it behave correctly.
      return this.ticket.company ? [this.ticket.company] : []
    }
  },
  methods: {
    ...mapActions(useUserStore,['setTicketModalState']),
    prepareEditTask () {
      this.taskUpdate.send_email_attributes.body = this.ticket.send_email.body
      this.taskUpdate.send_email_attributes.subject = this.ticket.send_email.subject
      this.taskUpdate.deadline = this.ticket.deadline
      this.taskUpdate.audit_call = this.ticket.audit_call
      this.taskUpdate.audit_area = this.ticket.audit_area
      this.taskUpdate.audit_area_id = this.ticket.audit_area_id
      this.taskUpdate.responsible = this.ticket.responsible
      this.taskUpdate.attachments = this.ticket.attachments
      this.taskUpdate.company_id = this.ticket.company_id
      this.taskUpdate.ticket_type_id = this.ticket.ticket_type_id
      this.taskUpdate.dpo_id = this.ticket.dpo_id
      this.taskUpdate.dpo = this.ticket.dpo
      if (this.ticket.note != null) {
        this.taskUpdate.note.enabled = this.ticket.note.enabled
        this.taskUpdate.note.audit_relevant = this.ticket.note.audit_relevant
        this.taskUpdate.note.annual_report_relevant = this.ticket.note.annual_report_relevant
      }
    },
    openEditTask () {
      if (!this.$userCan('edit:todos')) return
      this.fetchTicketTypes()
        .then(() => {
          this.editTask = true
          this.$emit('edit-task-toggle', this.editTask)
          this.prepareEditTask()
        })
    },
    closeEditTask () {
      this.editTask = false
      this.$emit('edit-task-toggle', this.editTask)
    },
    updateTask () {
      if (!this.$userCan('edit:todos')) return
      this.loading = true

      const updateTask = {
        id: this.ticket.id,
        status: this.ticket.status,
        flagged: this.ticket.flagged,
        dpo_id: this.taskUpdate.dpo_id,
        company_id: this.taskUpdate.company_id,
        ticket_type_id: this.taskUpdate.ticket_type_id,
        removed_files: this.taskUpdate.removed_files,
        added_files: this.taskUpdate.added_files,
        deadline: this.taskUpdate.deadline,
        audit_call: this.taskUpdate.audit_call,
        audit_area_id: this.taskUpdate.audit_area_id,
        responsible: this.taskUpdate.responsible,
        send_email_attributes: {
          id: this.ticket.send_email.id,
          subject: this.taskUpdate.send_email_attributes.subject,
          email_type: this.ticket.send_email.email_type,
          body: this.taskUpdate.send_email_attributes.body,
        },
        note: {
          enabled: this.taskUpdate.note.enabled,
          audit_relevant: this.taskUpdate.note.audit_relevant,
          annual_report_relevant: this.taskUpdate.note.annual_report_relevant,
        },
      }
      this.$axios.put(this.$apiRoutes.ticket + '/' + this.ticket.id, updateTask, {
        beforeRequest: {
          hidePreloader: true,
        },
      })
        .then(() => {
          this.loading = false
          this.$emit('update-ticket')
          this.editTask = false
          this.$emit('edit-task-toggle', this.editTask)
          this.showSuccessfullyUpdateMessage()
        })
        .catch(e => {
          this.loading = false
          this.$errorMessage(e.request.status, e)
        })
    },
    showCompany (id) {
      let routeName = 'company_edit'
      if (this.currentUser.role === 'dpo') {
        routeName = 'company-show'
      }
      const routeData = this.$router.resolve({ name: routeName, params: { id } })
      window.open(routeData.href, '_blank')
    },
    showSuccessfullyUpdateMessage () {
      this.$errorMessage(
        -1,
        'The ticket #' + this.ticket.id + ' updated',
        'success',
      )
    },
    fetchTicketTypes () {
      this.isLoadingTaskForm = true
      return this.$axios
        .get(this.$apiRoutes.ticket_type + '?per_page=all', {
          beforeRequest: {
            hidePreloader: true,
          },
        })
        .then(response => {
          this.ticketTypes = this.$deserialize(response.data)
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
        .finally(() => {
          this.isLoadingTaskForm = false
        })
    },
  },
}
</script>
