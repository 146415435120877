import Resource from './resource'
import apiRoutes from './apiRoutes'

class TrackingActionsResource extends Resource {
  constructor () {
    super(apiRoutes.tracking_actions)
  }

  create (data) {
    return this.call({
      method: 'POST',
      url: '/',
      data,
    })
  }
}

export default new TrackingActionsResource()
