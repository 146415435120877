<template>
  <el-container>
    <el-header class="site-header flex justify-space-between">
      <div class="title pr25 flex flex-grow-1">
        <router-link to="/">
          <img :src="logo" />
        </router-link>
        <div class="flex-grow-1">
          <TheHeaderMenu v-if="currentUser.role !== 'customer'" />
        </div>
      </div>
      <div class="flex items-center">
        <div
          v-if="$userCan('edit:todos')"
          class="pr20">
          <el-button
            v-if="currentUser.role === 'dpo'"
            data-test="new_task"
            type="primary"
            size="mini"
            @click="taskFormVisible = true">
            {{ $tr('header.button.new_task') }}
          </el-button>
          <span v-else>&nbsp;</span>
        </div>
        <SelectWrapper
          v-if="$userCan('view:companies') && currentUser.role !== 'customer'"
          v-model="searchQuery"
          :resource="$apiRoutes.company"
          label-key="name"
          value-key="id"
          remote
          class="search__width"
          search-param-key="search[query]"
          :custom-params="{
            fields: { company: ['name'] },
            include_inactive: isInactiveIncluded,
          }"
          :place-holder="$tr('header.search_placeholder')"
          @change="showCompany" />
        <div
          v-if="isDpo && $userCan('view:todos')"
          class="notification pr20">
          <el-button
            size="small"
            type="text"
            style="width: 40px"
            @click="openTodosPage(notification.open_tickets_count)">
            <el-badge
              :value="notification.open_tickets_count"
              class="item"
              :type="todosBadgeType">
              <img
                :src="todosTickIcon"
                class="icon-svg"
                alt="todos icon" />
            </el-badge>
          </el-button>
        </div>
        <el-menu
          mode="horizontal"
          :default-active="activeIndex"
          class="pull-right">
          <el-submenu
            :show-timeout="100"
            :hide-timeout="100"
            popper-class="header-customized-menu header-settings-menu"
            index="header-settings-menu"
            class="user">
            <template slot="title">
              <div class="profile">
                <div class="profile__details">
                  <div data-test="profile-name">
                    {{ $truncate(currentUser.full_name, 28) }}
                  </div>
                  <div
                    v-if="currentUser.role === 'customer'"
                    class="user__role">
                    {{ $truncate(selectedCompany.name, 25) }}
                  </div>
                  <div
                    v-else
                    class="user__role"
                    data-test="user-role">
                    {{ currentUser.role }}
                    <span
                      v-for="role in currentUser.extra_roles"
                      :key="role">
                      - {{ role }}
                    </span>
                  </div>
                </div>
                <div>
                  <div class="profile__abbr-name">
                    {{ abbrName.firstName }}
                    {{ abbrName.lastName }}
                  </div>
                </div>
              </div>
            </template>

            <el-menu-item-group
              id="site-header__langs"
              class="site-header__langs">
              <el-menu-item
                v-for="languageFlag in languageFlags"
                :key="languageFlag.name">
                <el-button
                  class="site-header__lang-item"
                  size="mini"
                  type="text"
                  :disabled="selectedLanguage === languageFlag.name"
                  @click="selectedLanguage = languageFlag.name">
                  <img :src="languageFlag.flagPath" />
                </el-button>
              </el-menu-item>
            </el-menu-item-group>

            <el-menu-item
              v-if="currentUser.role === 'dpo'"
              index="out-of-office"
              @click="outOfOffice">
              <span>{{ $tr('header.out_of_office') }}</span>
            </el-menu-item>

            <el-menu-item
              v-if="isQuestionnaireLinkVisible"
              index="questionnaire-link">
              <a
                :href="questionnaireLink"
                target="_blank">
                {{ questionnaireLinkTitle }}
              </a>
            </el-menu-item>

            <el-submenu
              :show-timeout="100"
              :hide-timeout="100"
              index="knowledge-base"
              class="el-menu--left">
              <template slot="title">
                {{ $tr('header.knowledge_base') }}
              </template>
              <el-menu-item
                v-for="{
                  link,
                  translationKey,
                  title,
                } in knowledgeBaseMenuItems"
                :key="title ?? translationKey"
                :index="title ?? translationKey">
                <a
                  :href="link"
                  target="_blank">
                  {{ title ?? $tr(translationKey) }}
                </a>
              </el-menu-item>
            </el-submenu>

            <el-menu-item-group
              v-if="currentUser.role === 'customer'"
              class="site-header__customer-companies"
              :title="$tr('header.your_companies')">
              <el-menu-item
                v-for="(company, index) in currentUser.companies"
                :key="company.id"
                :class="{
                  'is-active': selectedCompany.name === company.name,
                }"
                :index="`company-${index}`"
                @click="switchCompany(company)">
                <span>{{ company.name }}</span>
              </el-menu-item>
            </el-menu-item-group>

            <el-menu-item
              id="header-logout"
              index="logout-button"
              @click="logout">
              <span>
                <i class="el-icon-switch-button" />
                {{ $tr('header.logout') }}
              </span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-header>
    <div class="new-design">
      <TodoDialog />
      <el-dialog
        :visible.sync="taskFormVisible"
        width="520px"
        custom-class="taskFormDialog">
        <NewTaskForm
          :task-form-visible="taskFormVisible"
          @close-modal="taskFormVisible = false" />
      </el-dialog>
    </div>
    <el-dialog
      :visible.sync="showErrorAccess"
      width="40%"
      data-test="dialog--basic-info--error">
      <strong v-html="messageErrorAccess" />
      <div
        slot="footer"
        class="dialog-footer align-right">
        <el-button
          data-test="next_to_audit"
          @click="goToAudit">
          {{ $tr('button.next') }}
        </el-button>
      </div>
    </el-dialog>
    <CheckDocumentDialog />
  </el-container>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import TheHeaderMenu from './TheHeaderMenu'
import CheckDocumentDialog from './check-document-dialog/CheckDocumentDialog'
import profile from '@/assets/images/profile.jpg'
import logo from '@/assets/images/dg-logo-white.svg'
import kivancSemen from '@/assets/images/customer/kivanc_semen.png'
import NewTaskForm from '@/components/NewTaskForm'
import TodoDialog from '@/components/todo-dialog/TodoDialog'

// language flags
import de from '@/assets/images/language-flags/de.svg'
import en from '@/assets/images/language-flags/en.svg'

import todosTickIcon from '@/assets/images/svg-icons/todos-tick.svg'
import { useUserStore } from '@/stores/userStore'
import { useLanguageStore } from '@/stores/languageStore'
import { useCompanySearchParamsStore } from '@/stores/companySearchParamsStore'

export default {
  components: {
    TheHeaderMenu,
    TodoDialog,
    NewTaskForm,
    CheckDocumentDialog,
  },
  data() {
    return {
      searchQuery: '',
      taskFormVisible: false,
      profile,
      kivanc: kivancSemen,
      logo,
      notification: {
        open_tickets_count: 0,
      },
      todosBadgeType: 'danger',
      languageFlags: [
        {
          name: 'de',
          flagPath: de,
        },
        {
          name: 'en',
          flagPath: en,
        },
      ],
      selectedCompany: {
        id: null,
        name: '',
      },
      selectedLanguage: '',
      todosTickIcon,
      showErrorAccess: false,
      messageErrorAccess: '',
      knowledgeBaseMenuItems: [
        {
          link: 'https://datacogmbh.sharepoint.com/sites/Professional-Services_Department',
          translationKey: 'header.privacy_handbook',
        },
        {
          link: 'https://datacogmbh.sharepoint.com/sites/CS_Bible',
          translationKey: 'header.cs_handbook',
        },
        {
          link: 'https://datacogmbh.sharepoint.com/sites/ProductSSOT',
          translationKey: 'header.processes_releases',
        },
        {
          link: 'https://app.zavvy.io/dashboard',
          title: 'Zavvy',
        },
      ],
    }
  },
  channels: {
    TicketChannel: {
      received(data) {
        this.notification = data
      },
    },
  },
  computed: {
    ...mapState(useCompanySearchParamsStore, ['isInactiveIncluded']),
    ...mapState(useLanguageStore, ['lang']),
    ...mapState(useUserStore, [
      'currentUser',
      'getLoginWithKeycloak',
      'getDataGuardConfigs',
      'runningTask',
      'selectedCustomerCompany',
    ]),
    loginWithKeycloak() {
      return this.getLoginWithKeycloak
    },
    activeIndex() {
      return this.$route.name
    },
    questionnaireLink() {
      const url = this.getDataGuardConfigs('my_url')
      if (this.$isBlank(url)) {
        return 'https://my.dataguard.de'
      }
      return url.description
    },
    questionnaireLinkTitle() {
      if (this.currentUser.role === 'dpo') {
        return this.$tr('header.questionnier')
      }
      return this.$tr('header.go_to_audit')
    },
    isQuestionnaireLinkVisible() {
      return ['dpo', 'customer'].includes(this.currentUser.role)
    },
    abbrName() {
      const splitFullName = this.currentUser.full_name.split(' ')
      const firstName =
        splitFullName && splitFullName[0] ? splitFullName[0] : ''
      const lastName = splitFullName && splitFullName[1] ? splitFullName[1] : ''

      return {
        firstName: firstName.charAt(0).toUpperCase(),
        lastName: lastName.charAt(0).toUpperCase(),
      }
    },
    isDpo() {
      if (this.$featureFlagIsActive('permissions')) return true
      return this.currentUser.role === 'dpo'
    },
  },
  watch: {
    selectedLanguage(lang) {
      if (!lang) return
      this.changeLanguage(lang)
    },
    'notification.open_tickets_count'(newValue, oldValue) {
      if (newValue > oldValue) {
        this.todosBadgeType = 'warning'
        setTimeout(() => {
          this.todosBadgeType = 'danger'
        }, 200)
      }
    },
  },
  created() {
    this.selectedLanguage = this.lang
    if (this.currentUser.role === 'customer') {
      this.switchCompany(this.selectedCustomerCompany)
    }
  },
  mounted() {
    if (this.isDpo && this.$userCan('view:todos')) {
      this.$cable.subscribe({
        channel: 'TicketChannel',
        room: 'received_user_' + this.currentUser.id,
      })
    }
  },
  methods: {
    ...mapActions(useLanguageStore, ['setLang']),
    ...mapActions(useUserStore, ['switchCustomerCompany']),
    async logout() {
      await this.$axios.delete('/users/sign_out')

      if (this.loginWithKeycloak) {
        await this.$auth.logout()
      } else {
        window.location.href = '/auth'
      }
    },
    setting() {
      this.$router.push({ name: 'user-setting' })
    },
    outOfOffice() {
      this.$router.push({ name: 'out-of-office' })
    },
    fetchCompany(companyId) {
      this.$axios
        .get(this.$apiRoutes.company + '/' + companyId)
        .then((response) => {
          this.company = this.$deserialize(response.data)
          this.switchCustomerCompany(this.company)
          if (this.$route.name !== 'index') this.$router.push({ name: 'index' })
        })
        .catch((e) => {
          this.$errorMessage(e.request.status, e)
        })
    },
    switchCompany(company) {
      if (this.$isBlank(company)) {
        this.selectedCompany = this.currentUser.companies[0]
      } else {
        this.selectedCompany = this.currentUser.companies.find(
          (userCompany) => parseInt(userCompany.id) === parseInt(company.id),
        )
      }
      this.showBasicInfo(this.selectedCompany.access_to_basic_info)
    },
    showBasicInfo(accessToBasicInfo) {
      if (accessToBasicInfo) {
        this.fetchCompany(this.selectedCompany.id)
      } else {
        this.switchCustomerCompany(this.selectedCompany)
        if (this.$route.name !== 'index') this.$router.push({ name: 'index' })
        this.messageErrorAccess = this.$tr(
          'header.message_access_to_basic_info',
          [this.selectedCompany.name],
        )
        this.showErrorAccess = true
      }
    },
    goToAudit() {
      const myURL = window.location.origin + '?force_redirect=true'
      window.location.href = myURL
    },
    openTodosPage(openTicketsCount) {
      let routeData = ''
      if (openTicketsCount > 0) {
        // let myURL = window.location.origin
        routeData = this.$router.resolve({
          name: 'ticket-list',
          query: {
            filter_dpo: this.currentUser.profile_id,
            filter_ticket_status: JSON.stringify(['open_ticket']),
          },
        })
      } else {
        routeData = this.$router.resolve({
          name: 'ticket-list',
          query: {
            filter_dpo: this.currentUser.profile_id,
            filter_ticket_status: JSON.stringify([
              'in_progress',
              'waiting_for_customer',
            ]),
          },
        })
      }

      window.open(routeData.href, '_blank')
    },
    showCompany(id) {
      const routeData = this.$router.resolve({
        name: 'company-show',
        params: { id },
      })
      window.open(routeData.href, '_blank')
    },
    changeLanguage(lang) {
      if (!lang) return
      this.setLang(lang)
      this.$axios.get(`/api/v1/pings?locale=${lang}`, {
        beforeRequest: {
          hidePreloader: true,
        },
      })
    },
  },
}
</script>
<style scoped>
.search__width {
  max-width: 40rem;
  min-width: 20rem;
}
</style>
