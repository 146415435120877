/* eslint-disable camelcase */
import { defineStore } from 'pinia'
import CompanyResource from '@/api/company'
import EmployeeResource from '@/api/employee'
import TicketResource from '@/api/ticket'
import TrackingActionsResource from '@/api/trackingActions'
import { sortBy } from 'lodash'
import { deserialize } from 'jsonapi-deserializer'
import { errorMessage, tr } from '@/utils/utilityMethods'
import * as Sentry from '@sentry/vue';

const env = document.getElementsByName('env')[0]?.getAttribute('content')

const defaultSenderEmail = document
  .getElementsByName('default_sender_email')[0]
  ?.getAttribute('content')

const loginWithKeycloak = document
  .getElementsByName('login_with_keycloak')[0]
  ?.getAttribute('content')

const rawDataGuardConfigs = document
  .getElementsByName('dataguard_configs')[0]
  ?.getAttribute('content')
const dataGuardConfigs = rawDataGuardConfigs && JSON.parse(rawDataGuardConfigs)

const rawCurrentUser = document
  .getElementsByName('current_user')[0]
  ?.getAttribute('content')
const currentUser = rawCurrentUser && JSON.parse(rawCurrentUser)

if (currentUser) {
  Sentry.setUser({ id: currentUser.id });
}

export const useUserStore = defineStore('userStore', {
  state: () => ({
    env,
    defaultSenderEmail,
    loginWithKeycloak,
    currentUser,
    dataGuardConfigs,
    ticketModalState: {
      view: false,
      id: null,
      afterOpen: null,
    },
    dpaModalState: {
      view: false,
      document_type: 'dpa',
      ticket: {
        id: null,
        company: {
          id: null,
        },
        dpa: { id: null },
        thirteen_art: { id: null },
        fourteen_art: { id: null },
        mix_art: { id: null },
      },
      de_version: false,
      attachments: [],
    },
    todoListResetFlag: false,
    activitiesResetFlag: false,
    ppmsResetFlag: false,
    selectedCustomerCompany: null, // selected first customer company for null
    selectedActiveActivityTab: '',
    parsedDPA: {
      parsedContent: null,
      documentSectionAnswerId: null,
    },
    documentContent: '',
    documentTitle: '',
    documentKey: '',
    isDocumentActionTabSelected: false,
    // inline translation
    // to save the last updated ticket. we use this to update timer and
    // ticket/tracking buttons
    lastUpdatedTicket: {
      ticket: null,
      flag: false,
    },
    // time tracking states
    runningTask: {
      active: false,
      companyName: 'Unknown Company',
      ticketSubject: 'Anonymous Subject',
      ticketSpentTime: false,
      ticketId: false,
      trackingSpentTime: false,
      timeTrackingId: false,
      lastTrackerId: false,
      pause: false,
    },
    runningTaskInterval: false,
    // Tickets
    ticketsFetchParams: {},
    tickets: [],
    ticketsDetails: {
      ticketsSize: 0,
      paginateOptions: { total_count: 0 },
      statistics: {},
    },
    // Companies
    company: null, // after click on show company button, its details fetch and store here
    academyUsers: [],
    employees: [], // list of employees for loaded company will store here
    mainContacts: [], // main contact of th employees for the loaded company will store here
    contract: null, // Company has many contracts, one of them will be selected to show its details in company details page
    emailContent: '',
    companyHasAuditVersion3: false,
    customerTaskUrl: ''
  }),
  getters: {
    getCurrentUserRoles: (state) => {
      return state.currentUser?.extra_roles.concat(state.currentUser.role)
    },
    getDataGuardConfigs: (state) => (category) => {
      if (state.dataGuardConfigs) {
        return state.dataGuardConfigs[category]
      }
      return false
    },
    successAppStatus: (state) => {
      return state.env.includes('success')
    },
    getTicketIndexByID: (state) => (id) => {
      if (state.tickets) {
        return state.tickets.findIndex(
          (item) => parseInt(item.id) === parseInt(id),
        )
      }
      return -1
    },
    getLoginWithKeycloak: (state) => {
      return state.loginWithKeycloak === 'true'
    },
  },
  actions: {
    setTicketModalState({ view, id, afterOpen }) {
      this.ticketModalState = {
        view,
        id,
        afterOpen,
      }
    },
    clearTicketModalState() {
      this.ticketModalState = {
        view: false,
        id: null,
        afterOpen: null,
      }
    },
    setDpaModalState({ view, ticket, de_version, document_type, attachments }) {
      this.dpaModalState = {
        view,
        document_type,
        ticket,
        de_version,
        attachments,
      }
    },
    clearDpaModalState() {
      this.dpaModalState = {
        view: false,
        document_type: 'dpa',
        ticket: {
          id: null,
          company: {
            id: null,
          },
          dpa: { id: null },
          thirteen_art: { id: null },
          fourteen_art: { id: null },
          mix_art: { id: null },
        },
        de_version: false,
        attachments: [],
      }
    },
    setRunningTask({
      companyName,
      ticketSpentTime,
      ticketId,
      trackingSpentTime,
      timeTrackingId,
      lastTrackerId,
      ticketSubject,
      pause,
    }) {
      if (lastTrackerId !== this.currentUser.id) return
      this.clearRunningTask()
      this.runningTask = {
        active: true,
        companyName,
        ticketSubject,
        ticketSpentTime,
        ticketId,
        trackingSpentTime,
        timeTrackingId,
        lastTrackerId,
        pause,
      }
      // We don't need to run timer for paused tickets
      if (pause) return
      const interval = setInterval(() => {
        this.runningTask.ticketSpentTime++
        this.runningTask.trackingSpentTime++
      }, 1000)
      this.runningTaskInterval = interval
    },
    clearRunningTask() {
      this.runningTask = {
        active: false,
        companyName: 'Unknown Company',
        ticketSubject: 'Anonymous Company',
        ticketSpentTime: false,
        ticketId: false,
        trackingSpentTime: false,
        timeTrackingId: false,
        lastTrackerId: false,
        pause: false,
      }
      clearInterval(this.runningTaskInterval)
      this.runningTaskInterval = null
    },
    resetTodoList() {
      this.todoListResetFlag = !this.todoListResetFlag
    },
    resetActivitiesList() {
      this.activitiesResetFlag = !this.activitiesResetFlag
    },
    resetPpmList() {
      this.ppmsResetFlag = !this.ppmsResetFlag
    },
    switchCustomerCompany(company) {
      this.selectedCustomerCompany = company
    },
    setDocumentContent(data) {
      this.documentContent = data
    },
    setDocumentTitle(data) {
      this.documentTitle = data
    },
    setDocumentKey(data) {
      this.documentKey = data
    },
    selectDocumentTab() {
      this.isDocumentActionTabSelected = !this.isDocumentActionTabSelected
    },
    selectActiveActivityTab(data) {
      this.selectedActiveActivityTab = data.activeTab
      this.parsedDPA.parsedContent = data.parsedContent
      this.parsedDPA.documentSectionAnswerId = data.documentSectionAnswerId
    },
    runTrackingAction(data) {
      const object = {
        ticket_id: data.ticket_id,
        time_action: data.action,
      }
      return TrackingActionsResource.create(object)
        .then((response) => {
          const timeTracking = deserialize(response.data)
          if (data.action === 'stop') {
            this.clearRunningTask()
          } else {
            this.setRunningTask({
              companyName: timeTracking.ticket.send_email.company.name,
              ticketSubject: timeTracking.ticket.send_email.subject,
              ticketSpentTime: timeTracking.ticket.spent_time_tracking,
              ticketId: timeTracking.ticket_id,
              trackingSpentTime: timeTracking.spent_time_tracking,
              timeTrackingId: parseInt(timeTracking.id),
              lastTrackerId: timeTracking.last_tracker_id,
              pause: timeTracking.pause,
            })
          }

          let message = tr('time_tracking.messages.continue_tracking')
          if (data.action === 'stop')
            message = tr('time_tracking.messages.stop_time_tracking')
          if (data.action === 'start')
            message = tr('time_tracking.messages.start_tracking')
          if (data.action === 'pause')
            message = tr('time_tracking.messages.pause_time_tracking')
          errorMessage(-1, message, 'success')
        })
        .catch((e) => {
          errorMessage(e.request.status, e)
        })
    },
    setFetchTicketsParams(data) {
      this.ticketsFetchParams = data
    },
    async fetchTickets() {
      try {
        const response = await TicketResource.list(this.ticketsFetchParams)
        this.tickets = deserialize(response.data)
        this.ticketsDetails = {
          ticketsSize: response.data.meta.total_count,
          paginateOptions: response.data.meta,
          statistics: response.data.meta.statistics,
        }
      } catch (error) {
        errorMessage(error.request.status, error)
        return error
      }
    },
    setTickets(data) {
      this.tickets = data
    },
    updateTicketItem(data) {
      const ticketIndex = this.getTicketIndexByID(data.id)
      this.lastUpdatedTicket = {
        ticket: data,
        flag: !this.lastUpdatedTicket.flag,
      }

      if (ticketIndex < 0) return
      const tickets = this.tickets
      tickets[ticketIndex] = data

      this.tickets = tickets
    },
    setContract(id) {
      this.contract = this.company.contracts[id]
    },
    fetchCompany(data) {
      return CompanyResource.show(data)
        .then(({ data }) => {
          const deserializedData = deserialize(data)
          this.company = deserializedData
          // We choose the first active contract to be shown first
          const firstActiveContract = deserializedData.contracts.find(contract => contract.status.toLowerCase() === 'active')
          this.contract = firstActiveContract || deserializedData.contract
        })
        .catch((e) => {
          this.company = null
          this.contract = null
          errorMessage(e.request.status, e)
          // TODO: this.$router.push({ name: 'company-list' })
        })
    },
    updateCompany(_context, data) {
      CompanyResource.update(data.id, data.data)
    },
    setEmployees(data) {
      this.employees = data
    },
    setAcademyUsers(data) {
      this.academyUsers = data
    },
    setMainContacts() {
      const mainContacts = []

      this.employees.forEach((employee) => {
        if (employee.main_contact) {
          mainContacts.push(employee)
        }
      })

      if (mainContacts.length === 0) {
        mainContacts.push({
          work_email: '',
          work_phone: '',
          full_name: '',
          role: '',
        })
      }
      this.mainContacts = mainContacts
    },
    async fetchEmployees(data) {
      const isAcademyUsersFetched = Symbol.for('isAcademyUsersFetched')

      const callSecondaryAction = (setPeopleAction, data) => {
        const sortedActionInput = sortBy(data, ['full_name'])
        setPeopleAction(sortedActionInput)

        if (setPeopleAction === this.setEmployees) {
          this.setMainContacts()
          return
        }

        this.academyUsers[isAcademyUsersFetched] = true
      }

      let fetchParams = {}
      if (data.isFetchAfterEdit && this.academyUsers[isAcademyUsersFetched]) {
        fetchParams = { by_company: data.by_company }
      } else if (data.isFetchAfterEdit) {
        fetchParams = { by_company: data.by_company, non_academy_users: true }
      } else {
        fetchParams = data
      }

      await EmployeeResource.list(fetchParams)
        .then((response) => {
          const deserializedData = deserialize(response.data)

          if (
            data.isFetchAfterEdit &&
            this.academyUsers[isAcademyUsersFetched]
          ) {
            const employees = []
            const academyUsers = []

            deserializedData.forEach((user) => {
              if (
                user.user_roles.length === 1 &&
                user.user_roles[0].value === 'academy'
              ) {
                academyUsers.push(user)
              } else {
                employees.push(user)
              }
            })
            callSecondaryAction(this.setEmployees, employees)
            callSecondaryAction(this.setAcademyUsers, academyUsers)
          } else if (data.isFetchAfterEdit) {
            callSecondaryAction(this.setEmployees, deserializedData)
          } else if (data.academy_users) {
            callSecondaryAction(this.setAcademyUsers, deserializedData)
          } else {
            callSecondaryAction(this.setEmployees, deserializedData)
          }

          return deserializedData
        })
        .catch((e) => {
          if (data.academy_users) {
            this.setAcademyUsers([])
          } else {
            this.setEmployees([])
          }

          errorMessage(e.request.status, e)
        })
    },
    setEmailContent(data) {
      this.emailContent = data
    },
    setCompanyHasAuditVersion3(data) {
      this.companyHasAuditVersion3 = data
    },
    setCustomerTaskUrl(data){
      this.customerTaskUrl = data
    }
  },
})
