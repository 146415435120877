<template>
  <el-form
    v-bind="$attrs"
    :id="id"
    :ref="formRef"
    class="form-wrapper"
    :model="formData"
    :label-position="labelPosition">
    <slot v-bind="{ $validate }" />

    <slot
      v-if="userCanEdit"
      name="actions"
      v-bind="{ submitHandler }">
      <el-form-item class="button text-right">
        <el-button @click="resetForm">
          {{ $tr('button.reset') }}
        </el-button>
        <el-button
          class="submit"
          data-test="submit"
          type="primary"
          @click="submitHandler">
          {{ $tr('button.submit') }}
        </el-button>
      </el-form-item>
    </slot>
  </el-form>
</template>

<script>
const inc = new Date().getTime()

export default {
  props: {
    id: {
      type: String,
      default: () => `form-${inc}`,
    },
    submitFunction: {
      type: Function,
      required: false,
      default: () => {},
    },
    formRef: {
      type: String,
      required: false,
      default: 'form',
    },
    formData: {
      type: Object,
      required: true,
      default: () => {},
    },
    labelPosition: {
      type: String,
      default: () => 'left',
    },
    userCanEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data () {
    return {
      rules: {
        required: {
          required: true,
          message: this.$tr('messages.validation.required'),
          trigger: ['blur'],
        },
        array: {
          type: 'array',
          required: true,
          message: this.$tr('messages.validation.array'),
          trigger: ['blur'],
        },
        email: {
          type: 'email',
          message: this.$tr('messages.validation.email'),
          trigger: ['blur'],
        },
      },
    }
  },
  mounted () {
    this.initialFormValues = JSON.parse(JSON.stringify(this.formData))
  },
  beforeDestroy () {
    this.$emit('close-form')
  },
  methods: {
    $validate (params) {
      // for validation it si possible to use the following formats:
      // $validate('type')
      // $validate(['type1', 'type2'])
      // $validate([{type: 'type1',message: 'string1'}, {type: 'type2',message: 'string2'}])
      const selectedRules = []
      const validationChain = Array.isArray(params) ? params : [params]
      validationChain.forEach(validation => {
        if (typeof validation === 'object') {
          selectedRules.push({
            ...this.rules[validation.type],
            message: validation.message,
          })
        } else {
          selectedRules.push(this.rules[validation])
        }
      })
      return selectedRules
    },
    submitHandler () {
      // check if the form is valid before proceeding
      this.$refs[this.formRef].validate(valid => {
        if (!valid) return
        if (this.submitFunction) {
          this.submitFunction(this.formData).then(res => {
            if (res && res.success) {
                this.resetForm()
                return res
              }
          })
        }
      })
    },
    resetForm () {
      this.$emit(
        'update:form-data',
        JSON.parse(JSON.stringify(this.initialFormValues)),
      )
      if (this.$refs[this.formRef]) {
        this.$refs[this.formRef].resetFields()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.form-wrapper {
  padding: 16px;
  background: #fff;
  color: #254a5d;
  font-size: 14px;
  ::v-deep {
    .el-form-item__label {
      font-size: 14px;
      font-weight: 600;
      color: currentColor;
    }
  }
  .form-action--top {
    position: absolute;
    top: 36px;
    right: 0;
  }
}
</style>
