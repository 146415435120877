const rootRoutes = {
  base: '/api/v1/',
  registration: '/users',
}

const functionalRoutes = {
  delete_organizational_chart_file: (companyId) => {
    return `${rootRoutes.base}companies/${companyId}/delete_organizational_chart_file`
  },
  delete_designation_document: (companyId) => {
    return `${rootRoutes.base}companies/${companyId}/delete_designation_document`
  },
  company_teams: (companyId) => {
    return `${rootRoutes.base}companies/${companyId}/teams`
  },
}

const subdirectories = {
  account_role: 'external_roles',
  activity: 'activities',
  admin: 'admins',
  answer_sheet: 'answer_sheets',
  api_client: 'api_clients',
  api_controller: 'api_controllers',
  audit_area: 'audit_areas',
  audit_area_activity: 'audit_area_activities',
  azure_user: 'azure/users',
  basic_information: 'basic_informations',
  basic_information_status: 'basic_information_statuses',
  billable_hour: 'billable_hours',
  check_email: 'check_emails',
  churn_probabilities: 'churn_probabilities',
  comment: 'comments',
  communication: 'communications',
  company: 'companies',
  demo_company: 'demo_companies',
  company_communication_languages: 'company_communication_languages',
  company_name: 'company_names',
  complete_basic_information: 'complete_basic_informations',
  content: 'contents',
  contract: 'contracts',
  contract_change_reason: 'contracts/change_reasons',
  contract_schedule: 'contract_schedules',
  custom_variable: 'custom_variables',
  customer: 'customer_users',
  dataguard_industry: 'dataguard_industries',
  desired_certification: 'desired_certifications',
  destroy_ticket: 'destroy_tickets',
  dg_language: 'dg_languages',
  dg_translation: 'dg_translations',
  discard: 'discards',
  download: 'downloads',
  download_public_keys: 'download_public_keys',
  dpa_answer: 'dpa_answers',
  dpa_check: 'dpa_checks',
  dpa_section: 'dpa_sections',
  dpo: 'dpos',
  duplicate_user_role: 'duplicate_user_roles',
  email: 'send_emails',
  email_template: 'email_templates',
  employee: 'employees',
  employee_organization: 'employee_organizations',
  escalation_options: 'escalation_options',
  escalation_statuses: 'escalation_statuses',
  external_group: 'external_groups',
  feature: 'features',
  feature_flag: 'feature_flags',
  filter_template: 'filter_templates',
  generated_dpa: 'generated_dpas',
  generatePDF: 'generate_pdfs',
  grouping_audit_area: 'grouping_audit_areas',
  history: 'histories',
  industrial_sector: 'industrial_sectors',
  invitation: 'invitations',
  invite_email_template: 'invite_email_templates',
  invite_person: 'invite_person',
  journey: 'journeys',
  jurisdiction: 'jurisdictions',
  legal_form: 'legal_forms',
  lost_reason: 'lost_reasons',
  managing_director: 'managing_directors',
  mapping_industry_user: 'mapping_industry_users',
  mapping_service_package: 'mapping_service_packages',
  merge_user: 'merge_users',
  note: 'notes',
  nps_feedback: 'rating_customer_feedbacks',
  offboarding_type: 'offboarding_types',
  offered_service: 'offered_services',
  offered_service_statuses: 'offered_service_statuses',
  page: 'page_permissions',
  parse: 'parses',
  password: 'passwords',
  pdf_template: 'pdf_templates',
  person: 'people',
  placetel: 'placetels',
  privacy_contract: 'privacy_contracts',
  public_content: 'public_contents',
  public_key: 'public_keys',
  publish_document: 'publish_documents',
  quandora: 'quandora',
  questionnaire: 'questionnaires',
  questionnaire_document: 'questionnaire_documents',
  questionnaire_link: 'questionnaire_links',
  questionnaire_pdf_template: 'questionnaire_pdf_templates',
  rating: 'ratings',
  responsibility: 'responsibilities',
  sale_rep: 'sale_reps',
  send_test_email: 'send_test_emails',
  service_package: 'service_packages',
  service_package_category: 'service_package_categories',
  stage: 'stages',
  statistic_tracking: 'statistic_trackings',
  support_service: 'support_services',
  tag: 'tags',
  task_template: 'task_templates',
  team: 'teams',
  team_members: 'team_members',
  template_type: 'template_types',
  ticket: 'tickets',
  ticket_type: 'ticket_types',
  time_period: 'time_periods',
  time_tracking: 'time_trackings',
  topic: 'topics',
  tracking_actions: 'tracking_actions',
  tracking_api: 'tracking_apis',
  upload: 'attachments',
  user: 'users',
  user_role: 'user_roles',
  workflow: 'workflows',
}

const prefixedSubdirectories = {}
Object.entries(subdirectories).forEach(([key, value]) => {
  if (typeof value === 'string') {
    prefixedSubdirectories[key] = `${rootRoutes.base}${value}`
  }
})

const apiRoutes = {
  ...rootRoutes,
  ...functionalRoutes,
  ...prefixedSubdirectories,
}

export default apiRoutes
