<template>
  <div>
    <el-form
      v-if="!$isBlank(timeTrackingModel)"
      ref="timeTrackingForm"
      :model="timeTrackingModel">
      <el-row
        :key="timeTrackingModel.id"
        v-loading="loading"
        :gutter="20"
        class="mt20">
        <el-col :span="24">
          <el-card data-test="time-tracking-card">
            <div
              slot="header"
              class="flex items-center justify-between">
              <h4
                class="flex my10"
                style="font-size: 16px">
                <span class="font-normal">{{ lastTimeTrackedDate }}</span>
                <div class="ml10">
                  <TimeTrackingTimer
                    :item="timeTrackingModel"
                    item-type="time_tracking" />
                </div>
              </h4>
              <div>
                <div v-show="edit">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$tr('time_tracking.button.save_time_tracking')"
                    placement="top">
                    <el-button
                      data-test="save-time-tracking-button"
                      type="primary"
                      circle
                      size="small"
                      :disabled="!updateTimeTrackingPermission"
                      @click="update()">
                      <img
                        :src="icons.tickIcon"
                        class="icon-svg" />
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    :content="$tr('time_tracking.button.cancel_editing_time_tracking')"
                    placement="top">
                    <el-button
                      type="info"
                      circle
                      size="small"
                      @click="cancelEdit">
                      <img
                        :src="icons.crossIcon"
                        class="icon-svg" />
                    </el-button>
                  </el-tooltip>
                </div>
                <div
                  v-if="$userCan('edit:time_trackings')"
                  v-show="!edit">
                  <el-tooltip
                    effect="dark"
                    :content="$tr('time_tracking.button.edit_time_tracking')"
                    placement="top">
                    <el-button
                      data-test="edit-time-tracking"
                      type="primary"
                      :disabled="!updateTimeTrackingPermission"
                      circle
                      size="small"
                      class="pr10"
                      @click="edit = true">
                      <img
                        :src="icons.editIcon"
                        class="icon-svg" />
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    :content="$tr('time_tracking.messages.delete_time_tracking')"
                    placement="top">
                    <el-button
                      v-if="!timeTracking.is_active"
                      data-test="delete-time-tracking"
                      type="danger"
                      :disabled="!updateTimeTrackingPermission"
                      circle
                      size="small"
                      @click="deleteAtivity(timeTracking)">
                      <img
                        :src="icons.delete2Icon"
                        class="icon-svg" />
                    </el-button>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <TodoTimeTrackingsItemForm
              v-if="edit"
              :ticket="ticket"
              :billable-model="billableModel"
              :current-user="currentUser"
              :time-tracking="timeTrackingModel"
              :handle-form-change="handleFormChange"
              v-bind="icons"
              @update-ticket="$emit('update-ticket')" />
            <TodoTimeTrackingsItemDetails
              v-else
              :company="ticket.company"
              :billable-model="billableModel"
              :time-tracking="timeTrackingModel"
              v-bind="icons" />
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import TodoTimeTrackingsItemDetails from './TodoTimeTrackingsItemDetails'
import TodoTimeTrackingsItemForm from './TodoTimeTrackingsItemForm'

import personBlackIcon from '@/assets/images/svg-icons/person-black.svg'
import personRedIcon from '@/assets/images/svg-icons/person-red.svg'
import phoneIcon from '@/assets/images/svg-icons/phone.svg'
import noteIcon from '@/assets/images/svg-icons/note.svg'
import clockIcon from '@/assets/images/svg-icons/clock.svg'
import editIcon from '@/assets/images/svg-icons/edit.svg'
import delete2Icon from '@/assets/images/svg-icons/delete-2.svg'
import deleteOutlineIcon from '@/assets/images/svg-icons/delete-outline.svg'
import tickIcon from '@/assets/images/svg-icons/tick-white.svg'
import crossIcon from '@/assets/images/svg-icons/cross-white.svg'

import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  components: {
    TodoTimeTrackingsItemDetails,
    TodoTimeTrackingsItemForm,
  },
  props: {
    timeTracking: {
      type: Object,
      default: function () {
        return {
          description: 'Tacking for ' + this.ticket.send_email.subject,
          billable: 'Non-billable',
          last_tracker_id: 0,
          tracking_assignee: this.currentUser,
          tracking_assignee_id: this.currentUser.id,
          contact_person_id: this.ticket.company.main_contact_dpo_id,
          phone_call: 'no',
          time_periods: [],
          dpo: {},
          deducted_from_id: '',
          contract_id: null,
        }
      },
    },
    editable: {
      type: Boolean,
      required: true,
    },
    ticket: {
      type: Object,
      default: function () {
        return {}
      },
    },
    currentUser: {
      type: Object,
      default: function () {
        return {}
      },
    },
    lastTrackingEditable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      icons: {
        personBlackIcon,
        personRedIcon,
        phoneIcon,
        noteIcon,
        clockIcon,
        editIcon,
        delete2Icon,
        deleteOutlineIcon,
        tickIcon,
        crossIcon,
      },
      edit: false,
      timeTrackingModel: Object.assign({}, this.timeTracking),
      loading: false,
    }
  },
  computed: {
    ...mapState(useUserStore, ['ticketModalState']),
    modal: {
      get () {
        if (!this.timeTrackingModel.is_active) {
          return null
        }

        return this.ticketModalState
      },
      set (value) {
        this.setTicketModalState(value)
      },
    },
    timeTrackingForm () {
      return this.$refs.timeTrackingForm
    },
    billableModel () {
      const { timeTrackingModel, $lodash } = this

      if (!timeTrackingModel.billable) {
        return 'non_billable'
      }
      if (
        timeTrackingModel.billable &&
        parseInt(timeTrackingModel.deducted_from_id) === parseInt($lodash.get(timeTrackingModel, 'company.parent_company.id'))) {
        return 'billable_parent'
      }

      return 'billable_self'
    },
    updateTimeTrackingPermission () {
      if (this.$isBlank(this.timeTrackingModel.last_tracker)) return true
      // admin user is able to edit the tracking just when last_tracker is inactive
      if (!this.timeTrackingModel.last_tracker.account_active && this.$hasRole(['admin'])) return true
      // if it's not active, all users are able to edit
      if (!this.timeTrackingModel.is_active) return true
      // if it's active, just last_tracker is able to edit
      if (this.timeTrackingModel.last_tracker_id === this.currentUser.id) return true

      return false
    },
    lastTimeTrackedDate () {
      if (typeof (this.timeTrackingModel.time_periods[0]) === 'object') {
        return this.$dateTimeFormat(this.timeTrackingModel.time_periods[0].start_date)
      } else {
        return ''
      }
    },
  },
  created () {
    this.timeTrackingModel.company = this.ticket.company
    if (this.$isEmpty(this.ticket.company.parent_company)) this.timeTracking.deducted_from_id = this.ticket.company.id
    this._cacheTimeTracking = Object.assign({}, this.timeTracking)
    this.prefilledDescription()
    if (this.lastTrackingEditable && this.editable) {
      this.edit = true
    }
  },
  methods: {
    ...mapActions(useUserStore, ['setTicketModalState']),
    cancelEdit () {
      this.edit = false
      // Using this emit command its because of canceling editin time periods
      // after change time period time, and click on cancel, perivous content
      // doesn't back, and I couldn't solve it after two days, so I had to recall
      // the fetching time tracking again.
      this.$emit('update-ticket')
      this.$emit('time-tracking-updated')
    },
    update () {
      this.loading = true
      this.timeTrackingModel.time_periods_attributes = this.timeTrackingModel.time_periods
      this.$axios.put(
        this.$apiRoutes.time_tracking + '/' + this.timeTrackingModel.id,
        this.timeTrackingModel,
        {
          beforeRequest: {
            hidePreloader: true,
          },
        },

      )
        .then(() => {
          this.$errorMessage(-1, this.$tr('time_tracking.messages.update_time_tracking'), 'success')
          this.$emit('update-ticket')
          this.$emit('time-tracking-updated')
          this.edit = false
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleFormChange (key, value, index = null) {
      if (key === 'billable_change') {
        const { company } = this.timeTrackingModel

        this.timeTrackingModel.billable = (value !== 'non_billable')
        this.timeTrackingModel.deducted_from_id = this.$lodash.get(company, value === 'billable_parent' ? 'parent_company.id' : 'id')
      } else if (key === 'time_periods') {
        this.timeTrackingModel[key][index] = value
      } else {
        this.timeTrackingModel[key] = value
      }
    },
    prefilledDescription () {
      if (!this.editable) return
      if (!this.timeTrackingModel.is_active) return
      if (!this.$isEmpty(this.timeTrackingModel.description)) return
      if (this.ticket.send_email.email_type !== 'task') return

      this.timeTrackingModel.description = this.ticket.send_email.body
    },
    deleteAtivity (timeTracking) {
      this.$confirm(
        this.$tr('messages.sure_to_delete'),
        this.$tr('messages.warning'),
        {
          confirmButtonText: this.$tr('button.confirm'),
          cancelButtonText: this.$tr('button.cancel'),
          type: 'warning',
        }).then(() => {
        this.$axios.delete(this.$apiRoutes.time_tracking + '/' + timeTracking.id)
          .then(() => {
            this.$emit('update-ticket')
            this.$errorMessage(-1, this.$tr('time_tracking.messages.delete_time_tracking'), 'success')
          })
          .catch(e => {
            this.$errorMessage(e.request.status, e)
          })
      }).catch((_e) => {})
    },
  },
}
</script>

<style scoped>
.error_for_time_tracking_fill .el-alert__title {
  font-weight: bold;
}
</style>
