<template>
  <div>
    <template v-if="showText">
      <strong>{{ $tr('send_email.detail.sum_time') }}</strong>
    </template>
    {{ $timeDurationFormat(spentTime, { timeFormat: 'HH:mm:ss' }) }}
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      },
    },
    itemType: {
      type: String,
      required: true,
      default: 'ticket', // ticket/time_tracking
    },
    showText: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      itemModel: Object.assign({}, this.item),
      spentTime: 0,
    }
  },
  computed: {
    ...mapState(useUserStore, ['runningTask', 'lastUpdatedTicket']),
  },
  watch: {
    runningTask: {
      handler () {
        // We want to call setSpentTime function just for active tickets
        // TODO: I should change this function later
        if (
          !this.$isEmpty(this.itemModel) &&
          (
            (
              this.itemType === 'ticket' &&
              parseInt(this.itemModel.id) !== parseInt(this.runningTask.ticketId)
            ) ||
            (
              this.itemType === 'time_tracking' &&
              parseInt(this.itemModel.id) !== parseInt(this.runningTask.timeTrackingId)
            )
          )
        ) return

        this.setSpentTime()
      },
      deep: true,
    },
    item: {
      handler () {
        this.itemModel = Object.assign({}, this.item)
        this.setSpentTime()
      },
      deep: true,
    },
    // when modal is open, but tracking is not running,
    // if you change tracking time, e.g. remove time periods
    // or update times, timer doesn't change. we use this watch
    // to update timer value
    'lastUpdatedTicket.flag' () {
      if (this.itemType !== 'ticket') return
      if (this.$isEmpty(this.itemModel)) return
      if (parseInt(this.lastUpdatedTicket.ticket.id) !== parseInt(this.itemModel.id)) return

      this.itemModel = this.lastUpdatedTicket.ticket
      this.setSpentTime()
    },
  },
  created () {
    this.setSpentTime()
  },
  methods: {
    setSpentTime () {
      switch (this.itemType) {
        case 'ticket':
          this.setTicketSpentTime()
          break
        case 'time_tracking':
          this.setTimeTrackingSpentTime()
          break
      }
    },
    setTicketSpentTime () {
      if (this.$isEmpty(this.itemModel)) {
        this.spentTime = this.runningTask.ticketSpentTime
        return
      }
      if (parseInt(this.runningTask.ticketId) === parseInt(this.itemModel.id)) {
        this.spentTime = this.runningTask.ticketSpentTime
      } else {
        this.spentTime = this.itemModel.spent_time_tracking
      }
    },
    setTimeTrackingSpentTime () {
      if (this.$isEmpty(this.itemModel)) {
        this.spentTime = this.runningTask.trackingSpentTime
        return
      }
      if (parseInt(this.runningTask.timeTrackingId) === parseInt(this.itemModel.id)) {
        this.spentTime = this.runningTask.trackingSpentTime
      } else {
        this.spentTime = this.itemModel.spent_time_tracking
      }
    },
  },
}
</script>
