<template>
  <el-card
    v-loading="loading"
    data-test="time-tracking"
    class="text-center"
    style="background: #F4F4F5;">
    <div class="mb10">
      <div class="text-mute small mb2">
        {{ $tr('time_tracking.messages.total_time') }}
      </div>
      <strong style="font-size: 16px">
        <TimeTrackingTimer
          :item="ticket"
          :item-type="'ticket'" />
      </strong>
    </div>
    <div
      v-if="$userCan('edit:time_trackings')"
      data-test="buttons--time-tacking-dialog">
      <el-button
        v-if="!lastTimeTracking.pause"
        data-test="start-time-tracking"
        :disabled="!startable"
        type="success"
        @click="startTracking()">
        {{ $tr('time_tracking.button.start') }}
      </el-button>
      <el-button
        v-if="lastTimeTracking.pause"
        data-test="continue-time-tracking"
        :disabled="!continuable"
        type="success"
        @click="continueTracking()">
        {{ $tr('time_tracking.button.start') }}
      </el-button>
      <el-button
        data-test="pause-time-tracking"
        :disabled="!pausable"
        plain
        @click="pauseTracking()">
        <span v-if="$lodash.get(lastTimeTracking, 'pause', false)">
          {{ $tr('time_tracking.button.pause') }}
        </span>
        <span v-else>
          {{ $tr('time_tracking.button.pause') }}
        </span>
      </el-button>
      <el-button
        data-test="stop-time-tracking"
        :disabled="!stoppable"
        type="danger"
        @click="stopTracking()">
        {{ $tr('time_tracking.button.stop') }}
      </el-button>
    </div>
    <hr
      style="border: none; border-top: 1px solid #CCCCCC;"
      class="mt10 mb5" />
    <div class="flex justify-between">
      <div>
        <div class="text-mute">
          {{ $tr('time_tracking.messages.total_non_billable_time') }}
        </div>
        <strong>{{ $timeDurationFormat(ticket.non_billable_spent_time, { timeFormat: 'HH:mm:ss' }) }}</strong>
      </div>
      <div>
        <div class="text-mute">
          {{ $tr('time_tracking.messages.total_billable_time') }}
        </div>
        <strong>{{ $timeDurationFormat(ticket.billable_spent_time, { timeFormat: 'HH:mm:ss' }) }}</strong>
      </div>
    </div>
  </el-card>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  props: {
    ticket: {
      type: Object,
      default: function () {
        return {}
      },
    },
    lastTimeTracking: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data () {
    return {
      loading: false,
      ticketModel: Object.assign({}, this.ticket),
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'currentUser',
      'runningTask',
      'lastUpdatedTicket',
      'ticketModalState',
    ]),
    modal: {
      get () {
        return this.ticketModalState
      },
      set (value) {
        this.setTicketModalState(value)
      },
    },
    continuable () {
      // If time_tracking is active, not able to continue it
      if (this.lastTimeTracking.is_active) return false
      // Just last_tracker is able to continue a tracking
      if (this.lastTimeTracking.last_tracker_id === parseInt(this.currentUser.id)) return true

      return false
    },
    startMode () {
      return this.lastTimeTracking.pause ? 'continue' : 'start'
    },
    startable () {
      return !this.lastTimeTracking.is_active
    },
    stoppable () {
      // If time_tracking is not active, not able to stop it
      if (!this.lastTimeTracking.is_active && !this.lastTimeTracking.pause) return false

      // Just last_tracker is able to stop tracking if is active
      if (this.lastTimeTracking.last_tracker.account_active) {
        if (this.lastTimeTracking.last_tracker_id === parseInt(this.currentUser.id)) return true
        return false
      }

      // Just admin user is able to stop tracking if last_tracker is inactive
      if (this.$hasRole(['admin'])) return true

      return false
    },
    pausable () {
      if (this.lastTimeTracking.pause) return false

      // If time_tracking is not active, not able to stop it
      if (!this.lastTimeTracking.is_active) return false

      // Just last_tracker is able to stop tracking if is active
      if (this.lastTimeTracking.last_tracker.account_active) {
        if (this.lastTimeTracking.last_tracker_id === parseInt(this.currentUser.id)) return true
        return false
      }

      // Just admin user is able to stop tracking if last_tracker is inactive
      if (this.$hasRole(['admin'])) return true

      return false
    },
  },
  watch: {
    'lastUpdatedTicket.flag' () {
      if (this.$isEmpty(this.ticketModel)) return
      if (parseInt(this.lastUpdatedTicket.ticket.id) !== parseInt(this.ticketModel.id)) return

      this.ticketModel = this.lastUpdatedTicket.ticket
    },
  },
  methods: {
    ...mapActions(useUserStore, ['setTicketModalState', 'runTrackingAction']),
    continueTracking () {
      const data = {
        ticket_id: this.ticketModel.id,
        action: 'continue',
      }
      this.runTrackingAction(data).then(() => {
          this.$emit('update-ticket')
          this.$root.$emit('tracking-started')
        })
    },
    pauseTracking () {
      const data = {
        ticket_id: this.ticketModel.id,
        action: 'pause',
      }
      this.runTrackingAction(data).then(() => {
          this.$emit('update-ticket')
          this.$root.$emit('tracking-stopped')
        })
    },
    startTracking () {
      const data = {
        ticket_id: this.ticketModel.id,
        action: 'start',
      }
      this.runTrackingAction(data).then(() => {
          this.$emit('update-ticket')
          this.$root.$emit('tracking-started')
        })
    },
    stopTracking () {
      const data = {
        ticket_id: this.ticketModel.id,
        action: 'stop',
      }
      this.runTrackingAction(data).then(() => {
          this.$emit('update-ticket')
          this.$root.$emit('tracking-stopped')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .el-button {
    padding: 8px 16px;
  }
</style>
