<template>
  <div v-loading="trackingLoading">
    <TodoTimeTrackingsActions
      :ticket="ticket"
      :last-time-tracking="timeTrackings[0]"
      @update-ticket="resetTimeTrackings" />
    <div
      v-for="(timeTracking, index) in timeTrackings"
      :key="timeTracking.id">
      <TodoTimeTrackingsItem
        :key="index + timeTracking.id + timeTracking.spent_time_tracking + Date.now()"
        :editable="index === 0"
        :ticket="ticket"
        :current-user="currentUser"
        :time-tracking="timeTracking"
        :last-tracking-editable="lastTrackingEditable"
        @update-ticket="resetTimeTrackings"
        @time-tracking-updated="lastTrackingEditable = false" />
        <!--
          I'm using :key="index + timeTracking.spent_time_tracking" to re-render the components
          why this? which this: https://michaelnthiessen.com/force-re-render/
        -->
    </div>
  </div>
</template>

<script>
import TodoTimeTrackingsItem from './TodoTimeTrackingsItem'
import TodoTimeTrackingsActions from './TodoTimeTrackingsActions'
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  components: {
    TodoTimeTrackingsItem,
    TodoTimeTrackingsActions,
  },
  props: {
    ticket: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data () {
    return {
      timeTrackings: [],
      trackingLoading: false,
      lastTrackingEditable: false,
    }
  },
  computed: {
    ...mapState(useUserStore, ['currentUser']),
  },
  created () {
    if (!this.ticket.id) return
    this.fetchTimeTrackings()
  },
  mounted () {
    this.$root.$on('tracking-started', () => {
      this.lastTrackingEditable = true
    })
    this.$root.$on('tracking-stopped', () => {
      this.lastTrackingEditable = false
    })
  },
  methods: {
    resetTimeTrackings () {
      this.$emit('update-ticket')
      this.fetchTimeTrackings()
    },
    fetchTimeTrackings () {
      this.trackingLoading = true
      let generatedRoute = this.$apiRoutes.time_tracking
      generatedRoute += '?by_ticket=' + this.ticket.id

      this.$axios.get(generatedRoute, {
        beforeRequest: {
          hidePreloader: true,
        },
      })
        .then(response => {
          this.trackingLoading = false
          this.timeTrackings = this.$deserialize(response.data)
        })
        .catch(e => {
          this.trackingLoading = false
          this.$errorMessage(e.request.status, e)
        })
    },
  },
}
</script>
