<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="text-right">
          <el-dropdown
            v-if="ticket.ticket_type?.show_check_dpa"
            trigger="click"
            split-button
            type="success"
            data-test="check-dpa-dropdown"
            @click="loadDpaSection('de')"
            @command="loadDpaSection">
            {{ $tr('send_email.check.check_dpa') }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="en">
                {{ $tr('send_email.check.english') }}
              </el-dropdown-item>
              <el-dropdown-item command="de">
                {{ $tr('send_email.check.german') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
  props: {
    ticket: {
      type: Object,
      default: function () {
        return {}
      },
    },
    attachments: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      auditAreas: [],
    }
  },
  methods: {
    ...mapActions(useUserStore, ['setDpaModalState', 'clearTicketModalState']),
    async fetchTicket() {
      try {
        const response = await this.$getOne(this.$apiRoutes.ticket, {
          id: this.ticket.id,
        })
        const data = this.$deserialize(response.data)
        return data
      } catch (error) {
        this.$errorMessage(error.request.status, error)
      }
    },
    async loadDpaSection(language) {
      // The ticket passed here as prop has no the whole data when comes from the email details page
      // so we need to fetch it again to get the whole data includes: dpa, arts and company
      // the next steps of DPA section depends on dpa and arts attributes
      const ticket = await this.fetchTicket()

      const dpaModalValue = {
        view: true,
        document_type: 'dpa',
        de_version: language === 'de',
        ticket,
        attachments: this.attachments || [],
      }
      this.setDpaModalState(dpaModalValue)
      // Ticket modal has append-to-body attribute so we need to close it before opening another modal
      this.clearTicketModalState()
    },
  },
}
</script>
