<script setup>
import { computed } from 'vue'
import { tr } from '@/utils/utilityMethods'
import { companySegments } from '@/constants/companySegmentOptions'

const props = defineProps({
  segment: {
    type: String,
    required: true,
    validator(value) {
      return companySegments.map((segment) => segment.value).includes(value)
    },
  },
})

const companySegment = computed(() => {
  return companySegments.find((segment) => segment.value === props.segment)
})
</script>

<template>
  <div class="flex items-center">
    <img
      :src="companySegment.icon"
      class="mr10"
      :alt="`${tr(companySegment.title)} icon`" />
    <span class="text-nowrap">{{ tr(companySegment.title) }}</span>
  </div>
</template>
