<template>
  <div class="comment">
    <div class="flex justify-space-between items-center">
      <div>
        <strong class="creator">
          {{ item.user_full_name }}
        </strong>
        <small class="time ml10 color-gray">
          {{ dayjs(item.created_at).fromNow() }}
        </small>
      </div>
      <div
        v-if="item.user.id == currentUser.id"
        :span="1">
        <el-popover
          v-model="visibleDelete"
          placement="left"
          width="200">
          <p class="mt0">{{ $tr('comment.messages.delete') }}</p>
          <div style="text-align: right; margin: 0">
            <el-button
              size="mini"
              type="text"
              @click="visibleDelete = false">
              {{ $tr('button.cancel') }}
            </el-button>
            <el-button
              type="danger"
              size="mini"
              @click="deleteComment(item.id)">
              {{ $tr('button.delete') }}
            </el-button>
          </div>
          <el-button
            slot="reference"
            type="text"
            size="mini"
            class="pt0 delete_comment">
            Delete comment
          </el-button>
        </el-popover>
      </div>
    </div>
    <div>
      {{ item.content }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'
import { dayjs } from '@/utils/timeDateFormat'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      visibleDelete: false,
    }
  },
  computed: {
    ...mapState(useUserStore, ['currentUser']),
  },
  methods: {
    dayjs,
    deleteComment (commentId) {
      this.$axios.delete(this.$apiRoutes.comment + '/' + commentId)
        .then(() => {
          this.$errorMessage(-1, this.$tr('messages.destroyed_comment'), 'success')
          this.$emit('sendRefreshFetch')
          this.visibleDelete = false
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .delete_comment  {
    color: #E57373;
    font-weight: 100;
  }
  .comment {
    border-radius: 5px;
    background: #FAFAFA;
    padding: 15px;
    margin-bottom: 10px;
  }
</style>
