<template>
  <el-input v-model="updateForm" />
</template>

<script>
export default {
  props: {
    form: {
      type: String,
      default: '',
    },
  },
  computed: {
    updateForm: {
      get() {
        return this.form
      },
      set(value) {
        this.$emit('update:form', value)
      },
    },
  },
}
</script>
