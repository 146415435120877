<template>
  <span>
    <el-popover
      ref="draft-option"
      v-model="draftOptionsVisible"
      placement="top-start"
      width="50%"
      trigger="click"
      class="template_popover">
      <el-button
        type="text"
        @click="saveDraft">
        Save Draft
      </el-button>
      <br />
      <el-button
        type="text"
        @click="loadDraft">
        Load Draft
      </el-button>
    </el-popover>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form
          ref="noteForm"
          :model="item"
          :rules="rules"
          label-width="0"
          @keydown.enter.native="updateNoteItem">
          <el-form-item props="item.title">
            <el-input
              id="note-edit-title"
              v-model="item.title"
              name="note-title"
              required />
          </el-form-item>
          <el-form-item>
            <Tinymce
              id="note-edit-description"
              v-model="item.description"
              type="textarea"
              placeholder="description" />
          </el-form-item>
          <el-form-item>
            <el-col
              :span="12">
              <el-switch
                v-model="item.audit_relevant"
                class="mr25"
                name="note-audit_relevant"
                data-test="note-audit_relevant"
                :inactive-text="$tr('note.audit_relevant')" />
              <el-switch
                v-model="item.annual_report_relevant"
                name="note-annual_report_relevant"
                data-test="note-annual_report_relevant"
                :inactive-text="$tr('note.annual_report')" />
            </el-col>
            <el-col
              :span="12"
              class="text-right">
              <el-button
                v-popover:draft-option
                type="text">
                <font-awesome-icon
                  v-if="draftSaving"
                  icon="redo-alt"
                  transform="shrink-2"
                  pulse />
                <font-awesome-icon
                  v-else
                  icon="check"
                  transform="shrink-2" />
                Draft
                <i class="el-icon-caret-bottom el-icon-right" />
              </el-button>
            </el-col>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div
      slot="footer"
      class="dialog-footer text-right">
      <el-button
        type="primary"
        data-test="button--note-form--update"
        @click="updateNoteItem">{{ $tr('button.update') }}</el-button>
      <el-button
        plain
        data-test="note-close-dialog-button"
        @click="confirmClose">
        {{ $tr('button.close') }}
      </el-button>
    </div>
  </span>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useNotesStore } from '@/stores/notesStore'
import { useIndexedDBStore } from '@/stores/indexedDBStore'
import { useUserStore } from '@/stores/userStore'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    companyId: {
      required: true,
      type: [String, Number],
    },
  },
  data () {
    return {
      originalNote: null,
      rules: {
        title: [
          { required: true, message: this.$tr('note.messages.input_title'), trigger: 'blur' },
        ],
      },
      draftOptionsVisible: false,
      draftSaving: false,
      draftTimer: null,
    }
  },
  computed: {
    ...mapState(useUserStore, ['currentUser']),
    noteFormRef () {
      return this.$refs.noteForm
    },
    isNoteModified () {
      return JSON.stringify(this.item) !== JSON.stringify(this.originalNote)
    },
  },
  watch: {
    'item.description' (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.draftTimer) clearTimeout(this.draftTimer)

        if (newValue.length === 0) {
          this.deleteDraft()
        } else {
          this.draftTimer = setTimeout(() => {
            this.saveDraft()
          }, 2000)
        }
      }
    },
  },
  mounted () {
    this.originalNote = { ...this.item };
  },
  methods: {
    ...mapActions(useNotesStore, ['updateNote']),
    ...mapActions(useIndexedDBStore, ['indexedDbOS']),
    updateNoteItem () {
      this.noteFormRef.validate((valid) => {
        if (!valid) return false
        this.updateNote({
          id: this.item.id,
          data: this.item,
        })
          .then(() => {
            this.$errorMessage(-1, 'Note was successfuly updated.', 'success', 'edit-note-success')
            this.$emit('note-updated')
            this.$emit('close-edit-note')
          })
          .catch(e => {
            this.$errorMessage(e.request.status, e)
          })
      })
    },
    async saveDraft () {
      this.draftSaving = true
      const store = await this.indexedDbOS('notes')

      store.put({
        user_id: this.currentUser.id,
        company_id: parseInt(this.companyId),
        title: this.item.title,
        description: this.item.description,
        audit_relevant: this.item.audit_relevant,
      })

      setTimeout(() => {
        this.draftSaving = false
      }, 1000)
    },
    async loadDraft () {
      const objectStore = await this.indexedDbOS('notes')
      const request = objectStore.get(parseInt(this.companyId))

      request.onsuccess = () => {
        if (!request.result) return
        this.item.title = request.result.title
        this.item.description = request.result.description
        this.item.audit_relevant = request.result.audit_relevant
      }
    },
    async deleteDraft () {
      const objectStore = await this.indexedDbOS('notes')
      objectStore.delete(parseInt(this.companyId))
    },
    confirmClose () {
      if (!this.isNoteModified) {
        this.$emit('close-edit-note')
        return
      }
      this.$confirm(this.$tr('note.messages.confirm_close'),
      this.$tr('messages.warning'),
      {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      })
        .then(() => {
          this.$emit('close-edit-note')
        })
        .catch(_e => {})
    },
  },
}
</script>
