<template>
  <el-row>
    <CommentForm
      :commentable-type="commentableType"
      :commentable-id="commentableId"
      @sendRefreshFetch="fetch" />

    <br />

    <el-row
      v-if="comments.length == 0"
      class="panel-body text-center">
      {{ $tr('comment.messages.empty') }}
    </el-row>

    <el-row v-else>
      <el-row
        v-for="(item, index) in comments"
        :key="index">
        <CommentItem
          :item="item"
          @sendRefreshFetch="fetch" />
      </el-row>

      <el-pagination
        style="text-align: center; margin-top: 20px;"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page.sync="currentPage"
        :total="commentsSize"
        hide-on-single-page
        @current-change="changePage" />
    </el-row>
  </el-row>
</template>

<script>
import CommentForm from './CommentForm'
import CommentItem from './CommentItem'

export default {
  components: {
    CommentForm,
    CommentItem,
  },
  props: {
    commentableType: {
      type: String,
      required: true,
      default: 'SendEmail',
    },
    commentableId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      comments: [],
      commentsSize: 0,
      paginateOptions: {},
      currentPage: 1,
      pageSize: 10,
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    fetch () {
      let generatedRoute = this.$apiRoutes.comment
      generatedRoute += '?page=' + this.currentPage + '&per_page=' + this.pageSize
      generatedRoute += '&filter_commentable[class_name]=' + this.commentableType
      generatedRoute += '&filter_commentable[id]=' + this.commentableId
      generatedRoute += '&sorted[column]=id'
      generatedRoute += '&sorted[direction]=desc'

      this.$axios.get(generatedRoute)
        .then(response => {
          this.comments = this.$deserialize(response.data)
          this.commentsSize = response.data.meta.total_count
          this.paginateOptions = response.data.meta
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    changePage (page) {
      this.currentPage = page
      this.fetch()
    },
    rowIndex (tableIndex) {
      return (this.paginateOptions.current_page - 1) * this.paginateOptions.per_page + tableIndex + 1
    },
  },
}
</script>
