<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="5">
        <el-form-item
          prop="partners[0].address.country"
          :label="$tr('document_section.country')">
          <el-select
            v-model="form.country"
            placeholder="Please enter at least 1 Character"
            filterable
            class="full-width">
            <el-option
              v-for="item in countryList"
              :key="item.name"
              :label="item.name"
              :value="item.name" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="5">
        <el-form-item
          prop="partners[0].address.city"
          :label="$tr('document_section.city')">
          <el-input
            v-model="form.city"
            class="full-width" />
        </el-form-item>
      </el-col>

      <el-col :span="5">
        <el-form-item
          prop="partners[0].address.street"
          :label="$tr('document_section.street')">
          <el-input
            v-model="form.street"
            class="full-width" />
        </el-form-item>
      </el-col>

      <el-col :span="5">
        <el-form-item
          prop="partners[0].address.street_no"
          :label="$tr('document_section.street_number')">
          <el-input
            v-model="form.street_no"
            class="full-width" />
        </el-form-item>
      </el-col>

      <el-col :span="4">
        <el-form-item
          prop="partners[0].address.postal_code"
          :label="$tr('document_section.postal_code')">
          <el-input
            v-model="form.postal_code"
            class="full-width" />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { countryList } from '@/utils/countryList'

export default {
  props: {
    address: { type: Object, required: true, defaults () { } },
  },
  data () {
    return {
      form: {
        id: null,
        country: '',
        city: '',
        street: '',
        street_no: '',
        postal_code: '',
      },
      countryList,
    }
  },
  watch: {
    address: {
      handler (v) {
        this.form = JSON.parse(JSON.stringify(v))
      },
      immediate: true,
    },
    form: {
      handler: 'emit',
      deep: true,
    },
  },
  methods: {
    emit () {
      if (!this.$lodash.isEqual(this.form, this.address)) {
        this.$emit('update:address', JSON.parse(JSON.stringify(this.form)))
      }
    },
  },
}
</script>
