<template>
  <div>
    <el-form
      ref="commentForm"
      :model="comment">
      <el-form-item>
        <el-input
          id="content"
          v-model="comment.content"
          type="textarea"
          :rows="5"
          placeholder="Ask a question or post an update..." />
      </el-form-item>

      <el-form-item>
        <el-button
          class="pull-right"
          type="primary"
          @click="submitForm">
          {{ $tr('button.submit') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    commentableType: {
      type: String,
      required: true,
      default: 'SendEmail',
    },
    commentableId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      comment: {
        content: '',
        commentable_type: this.commentableType,
        commentable_id: this.commentableId,
      },
    }
  },
  computed: {
    commentForm () {
      return this.$refs.commentForm
    },
  },
  watch: {
    commentableId (val) {
      this.comment.commentable_id = val
    },
    commentableType (val) {
      this.comment.commentable_type = val
    },
  },
  methods: {
    submitForm () {
      this.$axios
        .post(this.$apiRoutes.comment, this.commentForm.model)
        .then(() => {
          this.$errorMessage(
            -1,
            this.$tr('comment.messages.successfuly_created'),
            'success')
          this.$emit('sendRefreshFetch')
          this.resetForm()
        })
        .catch(e => {
          this.$errorMessage(e.request.status, e)
        })
    },
    resetForm () {
      this.commentForm.model.content = ''
    },
  },
}
</script>
